import React from "react";

const WaysToHelp = () => {
  return (
    <div>
      <img
        style={{ width: "100%", height: "500px", objectFit: "cover" }}
        src={require("../../assets/images/hero-new.jpeg")}
        alt="hero"
      />
    </div>
  );
};

export default WaysToHelp;
