import React, { useState, DragEvent } from "react";
import styled from "styled-components";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../services/firebaseService";
import { theme } from "../../config/theme";
import Label from "../Label";

interface DragAndDropImageUploadProps {
  uploadedImageUrl?: string;
  onUploadComplete: (url: string) => void;
  onUploadError: (error: any) => void;
}

const DragAndDropImageUpload: React.FC<DragAndDropImageUploadProps> = ({
  uploadedImageUrl,
  onUploadComplete,
  onUploadError,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files.length) {
      uploadImage(files[0]);
    }
  };

  const uploadImage = (file: File) => {
    if (!file) return;

    const storageRef = ref(storage, `images/${Date.now()}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        onUploadError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onUploadComplete(downloadURL);
        });
      }
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      uploadImage(event.target.files[0]);
    }
  };

  return (
    <DropZone
      isDragging={isDragging}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => document.getElementById("fileInput")?.click()}
      style={{
        backgroundImage: uploadedImageUrl ? `url(${uploadedImageUrl})` : "none",
      }}
    >
      {!uploadedImageUrl && uploadProgress === 0 && (
        <UploadBox>
          <UploadMessage>
            {isDragging
              ? "Drop here to upload"
              : "Drag and drop a photo or click to select one"}
          </UploadMessage>
          <FileInput
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            id="fileInput"
          />
          <UploadLabel htmlFor="fileInput">Add a Photo</UploadLabel>
        </UploadBox>
      )}
      {uploadProgress > 0 && uploadProgress <= 100 && !uploadedImageUrl && (
        <UploadBox>
          <Label
            sz="small"
            styles={{ color: theme.colors.text.body, textAlign: "center" }}
          >
            Uploading...
          </Label>
          <ProgressBar style={{ width: `${uploadProgress}%` }} />
        </UploadBox>
      )}
    </DropZone>
  );
};

export default DragAndDropImageUpload;

const DropZone = styled.div<{ isDragging: boolean }>`
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  background-size: cover;
  background-position: center;
  width: 180px;
  height: 120px;
  background-color: ${({ isDragging }) =>
    isDragging ? theme.colors.secondary[200] : theme.colors.primary};
`;

const UploadBox = styled.div`
  margin-top: 22px;
`;

const UploadMessage = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${theme.colors.secondary[300]};
  opacity: 0.5;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadLabel = styled.label`
  display: inline-block;
  padding: 8px 12px;
  opacity: 0.5;
  border: 1px solid ${theme.colors.secondary[300]};
  border-radius: 4px;
  cursor: pointer;
  color: ${theme.colors.secondary[300]};
`;

const ProgressBar = styled.div`
  height: 6px;
  background-color: ${theme.colors.secondary[300]};
  margin-top: 10px;
`;
