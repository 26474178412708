import { StyleSheet, View, Dimensions, Linking } from "react-native-web";
import Label from "../../Label";
import LabeledInput from "../../LabeledInput";
import LabeledAddressInput from "../../LabeledAddressInput";
import { makeIntoPhoneNumber } from "../../../config/formatter";
import { useState } from "react";
import { theme } from "../../../config/theme";
import Button from "../../Button";
import { updateAddress, updateFoster } from "../../../services/apiService";
import { auth, logAnalyticsEvent } from "../../../services/firebaseService";
import { Foster } from "../../../types/types";
import { Address } from "../../../types/types";
import LabeledSwitch from "../../LabeledSwitch";

type Props = {
  isEditable: boolean;
  application?: Foster;
  fosterAddress?: Address;
  userId: string;
  onRefresh?: () => void;
};
const PersonalInformation = ({
  isEditable,
  application,
  fosterAddress,
  userId,
  onRefresh,
}: Props) => {
  const [isEditing, setIsEditing] = useState(
    !application?.first_name &&
      !application?.last_name &&
      !application?.phone &&
      isEditable
  ); //default to editing state if no application exists yet
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [email, setEmail] = useState(
    application?.email ||
      auth.currentUser?.email ||
      window.localStorage.getItem("email") ||
      ""
  );
  const [firstName, setFirstName] = useState(application?.first_name || "");
  const [lastName, setLastName] = useState(application?.last_name || "");
  const [phone, setPhone] = useState(application?.phone || "");
  const [address, setAddress] = useState<Address | undefined>(fosterAddress);
  const [isAdult, setIsAdult] = useState(application?.is_adult || false);

  const isGuest = application?.auth_user_id !== userId;

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }
    setError("");
    setIsLoading(true);

    if (application?.id) {
      logAnalyticsEvent({ eventName: "contact_information_updated" });
      const res = await updateFoster({
        id: application?.id,
        auth_user_id: userId,
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        is_adult: isAdult,
      });

      if (address) {
        await updateAddress({ ...address, auth_user_id: userId });
      }
      if (res.success) {
        setIsEditing(false);
        onRefresh && onRefresh();
      }
    }

    setIsLoading(false);
  };

  return (
    <View style={{ width: "100%" }}>
      {isEditable && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Label styles={{ paddingRight: 16 }} weight="medium" sz="large">
            Contact information
          </Label>
          {isEditing ? (
            <Label
              weight="medium"
              onPress={() => setIsEditing(false)}
              sz="medium"
              styles={styles.clickableText}
            >
              Cancel
            </Label>
          ) : (
            <Label
              weight="medium"
              onPress={() => setIsEditing(true)}
              sz="medium"
              styles={styles.clickableText}
            >
              Edit
            </Label>
          )}
        </View>
      )}
      {isEditing && !application?.first_name && (
        <Label
          styles={{ paddingRight: 16, paddingBottom: 16, paddingTop: 6 }}
          sz="medium"
        >
          Step 2 of 3.
        </Label>
      )}

      {isEditing ? (
        <View style={{ paddingTop: 16 }}>
          {application?.first_name ? ( // don't show email input for first sign ups
            <LabeledInput
              label="Email address"
              value={email}
              onChange={setEmail}
              placeholder=""
              width={Dimensions.get("window").width - 40}
            />
          ) : null}
        </View>
      ) : (
        <View>
          <Label
            styles={[
              styles.labelText,
              { textDecorationLine: isGuest ? "underline" : undefined },
            ]}
            sz="small"
          >
            Email
          </Label>
          <Label onPress={() => Linking.openURL("mailto:" + email)} sz="medium">
            {email}
          </Label>
        </View>
      )}

      {isEditing ? (
        <LabeledInput
          label="First name"
          value={firstName}
          onChange={setFirstName}
          placeholder=""
          width={Dimensions.get("window").width - 40}
        />
      ) : (
        <View>
          <Label styles={styles.labelText} sz="small">
            Full name
          </Label>
          <Label sz="medium">
            {firstName} {lastName}
          </Label>
        </View>
      )}

      {isEditing ? (
        <LabeledInput
          label="Last name"
          value={lastName}
          onChange={setLastName}
          placeholder=""
          width={Dimensions.get("window").width - 40}
        />
      ) : null}

      {isEditing ? (
        <LabeledInput
          label="Phone (optional)"
          value={phone}
          onChange={(v) => setPhone(makeIntoPhoneNumber(v))}
          placeholder=""
          width={Dimensions.get("window").width - 40}
        />
      ) : (
        <View>
          <Label styles={styles.labelText} sz="small">
            Phone number
          </Label>
          <Label
            onPress={() => Linking.openURL("tel:" + phone)}
            styles={{ textDecorationLine: isGuest ? "underline" : undefined }}
            sz="medium"
          >
            {phone || "None provided"}
          </Label>
        </View>
      )}

      {isEditing ? (
        <LabeledAddressInput
          label="Address or City, State"
          value={{
            label: address?.street_1?.trim()
              ? `${address?.street_1}, ${address.city}, ${address.state}`
              : address?.city
              ? `${address?.city}, ${address?.state}`
              : "",
          }}
          onChange={setAddress}
          placeholder=""
          width={Dimensions.get("window").width - 40}
        />
      ) : (
        <View>
          <Label styles={styles.labelText} sz="small">
            Address
          </Label>
          <Label sz="medium">
            {address?.street_1?.trim()
              ? `${address?.street_1}, ${address.city}, ${address.state}`
              : address?.city
              ? `${address?.city}, ${address?.state}`
              : "Available upon request"}
          </Label>
        </View>
      )}

      {isEditing ? (
        <LabeledSwitch
          onPress={(v) => setIsAdult(v.target.checked || false)}
          isSelected={isAdult}
          label="Age"
          value="I am an adult 18 years or older."
        />
      ) : null}

      {error ? (
        <Label styles={styles.errorText} sz="small">
          {error}
        </Label>
      ) : null}

      {isEditing ? (
        <Button
          isDisabled={!address || !firstName || !lastName || isLoading}
          width={Dimensions.get("window").width - 40}
          onPress={onSubmit}
        >
          {"Update"}
        </Button>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  errorText: {
    color: theme.colors.error,
  },
  labelText: {
    paddingBottom: 6,
    paddingTop: 16,
    color: "black",
  },
  clickableText: {
    color: theme.colors.secondary[400],
    alignSelf: "start",
    textDecorationLine: "underline",
    paddingTop: 4,
  },
});

export default PersonalInformation;
