import React from "react";
import { Pressable, StyleSheet } from "react-native-web";
import { theme } from "../../config/theme";
import Label from "../Label";

type Props = {
  children?: React.ReactNode | string;
  onPress: () => void;
  width: number;
  height?: number;
  isDisabled?: boolean;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
};

const Button = ({
  children,
  onPress,
  width,
  height,
  isDisabled,
  backgroundColor,
  textColor,
  borderColor,
  ...rest
}: Props) => {
  return (
    <Pressable
      disabled={isDisabled}
      style={[
        styles.button,
        {
          width,
          height,
          backgroundColor: backgroundColor
            ? backgroundColor
            : isDisabled
            ? theme.colors.tertiary
            : undefined,
          borderWidth: borderColor ? 1 : 0,
          borderColor: borderColor ? borderColor : undefined,
        },
      ]}
      onPress={onPress}
    >
      <Label
        styles={[styles.text, { color: textColor ? textColor : undefined }]}
        sz="medium"
        weight="medium"
      >
        {children}
      </Label>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
    color: "white",
  },
  button: {
    maxWidth: 600,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "black",
    height: 48,
    backgroundColor: theme.colors.secondary[400],
    padding: 8,
    marginTop: 16,
  },
});

export default Button;
