import {
  StyleSheet,
  View,
  Pressable,
  Image,
  Dimensions,
  ScrollView,
} from "react-native-web";
import Label from "../../components/Label";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { theme } from "../../config/theme";
import { isMobile } from "../../config/utils";
import { Animal, PetFinderOrganization } from "../../types/types";
import Button from "../../components/Button";
import {
  auth,
  isAuthenticated,
  logAnalyticsEvent,
} from "../../services/firebaseService";
import {
  createApplication,
  submitApplication,
} from "../../services/apiService";
import SuccessModal from "../../components/SuccessModal";
import PetCard from "../../components/PetCard";
import { fetchPetFinderAnimals } from "../../services/petfinderService";
import { FaArrowLeft } from "react-icons/fa";

const OrgDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const organization: PetFinderOrganization = state.organization;
  const [petsList, setPetsList] = useState<Animal[]>([]);

  const [petImageUrl, setPetImageUrl] = useState(
    organization?.photos?.find((p) => p.full)?.full
  );
  const [didSendApplication, setDidSendApplication] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchPetFinderAnimals({
        location: window.localStorage.getItem("LAT_LNG") || "",
        organization: organization.id,
        page: "1",
        limit: "20",
      });
      if (res?.animals) {
        setPetsList(res.animals);
      }
    };
    if (!petsList.length) {
      fetchData();
    }
  }, [organization.id, petsList]);

  const onSubmitApplication = async () => {
    logAnalyticsEvent({
      eventName: "submit_application_attempt",
      data: { screen: "org" },
    });
    if (isAuthenticated()) {
      setIsLoading(true);
      const userId = auth.currentUser?.uid || "";
      logAnalyticsEvent({
        eventName: "submit_application",
        data: { screen: "org" },
      });
      const createAppRes = await createApplication({
        auth_user_id: userId,
        organization_petfinder_id: organization.id,
      });
      if (createAppRes.success) {
        const submitRes = await submitApplication({
          organization_email: organization.email || "support@petfosterz.com",
          organization_name: organization.name,
          pet_name: "",
          auth_user_id: userId,
          organization_petfinder_id: organization.id,
        });
        if (submitRes.success) {
          setDidSendApplication(true);
        }
      }
      setIsLoading(false);
    } else {
      navigate("/create-account");
    }
  };

  return (
    <View
      style={{
        backgroundColor: theme.colors.primary,
        padding: 24,
        width: "100%",
      }}
    >
      <Pressable style={{ alignSelf: "start" }} onPress={() => navigate(-1)}>
        <FaArrowLeft size={28} />
      </Pressable>

      <View style={{ paddingTop: 24 }}>
        <Image
          style={styles({ isSelected: false }).petImage}
          source={
            petImageUrl
              ? { uri: petImageUrl }
              : require("../../assets/images/avatar.png")
          }
        />

        <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
          <View
            style={{
              width: "100%",
              paddingTop: 16,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {organization.photos.map((photo, i) => {
              return (
                <Pressable key={i} onPress={() => setPetImageUrl(photo.full)}>
                  <Image
                    style={
                      styles({ isSelected: photo.full === petImageUrl })
                        .petImageSmall
                    }
                    source={{ uri: photo.full }}
                  />
                </Pressable>
              );
            })}
          </View>
        </ScrollView>

        <View style={styles({}).sectionContainer}>
          <Label sz="xlarge">{organization.name}</Label>
          <Label styles={{ paddingTop: 16 }} sz="medium">
            {organization.distance
              ? `${organization.distance.toFixed(1)} miles away`
              : ""}
          </Label>
          <Label styles={{ paddingTop: 16 }} sz="medium">
            {organization.address.address1
              ? `${organization.address.address1}\n`
              : ""}
            {organization.address.city}, {organization.address.state}{" "}
            {organization.address.postcode}
          </Label>

          {organization.phone && (
            <Label styles={{ paddingTop: 16 }} sz="medium">
              {organization.phone}
            </Label>
          )}
        </View>

        <View
          style={[
            styles({}).sectionContainer,
            {
              backgroundColor: theme.colors.secondary[400],
              alignItems: "center",
              paddingBottom: 24,
            },
          ]}
        >
          <Label
            styles={{ textAlign: "center", color: "white", padding: 16 }}
            sz="xlarge"
          >
            Considering to help {organization?.name}?
          </Label>

          <Button
            backgroundColor="white"
            textColor={theme.colors.secondary[400]}
            isDisabled={isLoading}
            onPress={onSubmitApplication}
            width={330}
          >
            SUBMIT MY APPLICATION
          </Button>
        </View>

        <View style={styles({}).sectionContainer}>
          <Label sz="medium">
            {organization.mission_statement}{" "}
            {organization?.mission_statement?.endsWith("...") ? (
              <Label
                styles={{ color: theme.colors.text.link }}
                sz="medium"
                onPress={() => (window.location.href = organization.url)}
              >
                Read more
              </Label>
            ) : null}
          </Label>
        </View>

        <Label sz="xlarge">{"Help Our Animals"}</Label>

        <View style={styles({}).gridContainer}>
          {petsList.map((pet, i) => {
            const formattedName = pet.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            const petImageUrl =
              pet.photos.find((p) => p.medium)?.medium ||
              pet.primary_photo_cropped?.medium;
            return (
              <PetCard
                key={i}
                pet={pet}
                imageUrl={petImageUrl}
                name={formattedName}
                breed={pet.breeds.primary}
                age={pet.age}
                distance={pet.distance}
              />
            );
          })}
        </View>
      </View>
      <SuccessModal
        isVisible={didSendApplication}
        onDismiss={() => setDidSendApplication(false)}
      />
    </View>
  );
};

const styles = (props: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: 10,
    },
    icon: {
      fontSize: 24,
      lineHeight: 24,
    },
    pressable: {
      paddingLeft: 16,
      paddingTop: 12,
      paddingBottom: 12,
    },
    petImage: {
      borderRadius: 12,
      width: isMobile(Dimensions.get("window").width) ? "100%" : 400,
      height: 365,
      resizeMode: "cover",
    },
    petImageSmall: {
      borderWidth: props.isSelected ? 3 : 0,
      borderColor: theme.colors.secondary[300],
      borderRadius: 12,
      marginRight: 12,
      width: 130,
      height: 100,
      resizeMode: "cover",
    },
    readMoreLink: {
      color: theme.colors.text.link,
    },
    sectionContainer: {
      marginTop: 16,
      marginBottom: 16,
      backgroundColor: "white",
      padding: 16,
      borderRadius: 8,
    },
    gridContainer: {
      paddingTop: 12,
      width: isMobile(Dimensions.get("window").width) ? "100%" : "60%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
  });

export default OrgDetail;
