// theme.js
const theme = {
  fontSizes: {
    1: "10px",
    2: "12px",
    3: "14px",
    4: "16px",
    5: "20px",
  },
  breakpoints: {
    sm: 595,
    md: 675,
    lg: 950,
  },
  fonts: {
    regular: "Urbanist, sans-serif",
    bold: "Urbanist-Bold, sans-serif",
    light: "Urbanist-Light, sans-serif",
  },
  colors: {
    primary: "#EAEFEE",
    secondary: {
      100: "#E9F4F0",
      200: "#A6D6C8",
      300: "#4B9D99",
      400: "#2F6B61",
    },
    tertiary: "#A7A7A7",
    selector: "#9F9F9F",
    error: "#A20808",
    info: "#EFF8FF",
    text: {
      header: "#000",
      body: "#5B616E",
      subtle: "#A9A9A9",
      link: "#3793E0",
    },
    border: "#E5E6E6",
  },
};

export { theme };
