import { useEffect } from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native-web";
import { logAnalyticsEvent } from "../../../services/firebaseService";

import Label from "../../../components/Label";
import { useParams } from "react-router-dom";
import { isMobile } from "../../../config/utils";
import { theme } from "../../../config/theme";

const HowDoesFosteringWork = () => {
  const { id } = useParams();

  useEffect(() => {
    logAnalyticsEvent({
      eventName: "blog_detail_visited",
      data: { blogId: id },
    });
  }, [id]);

  return (
    <View>
      <Image
        style={styles.image}
        source={require("../../../assets/images/foster-family.jpeg")}
      />
      <Label styles={styles.header} weight="medium" sz="xxxlarge">
        How Does Pet Fostering Work?
      </Label>
      <Label weight="medium" styles={styles.header} sz="large">
        Together, We Can Make a Pawsitive Impact and Reduce Shelter
        Overcrowding.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        What is Pet Fostering?
      </Label>
      <Label styles={styles.body} sz="medium">
        Pet fostering is a temporary living arrangement where individuals and
        families open their hearts and homes to help rescue animals in need.
        These animals, often dogs or cats, stay with foster caregivers until
        they find their furever homes with all their expenses paid by the
        rescue.{"\n\n"}It's a vital step in the rescue process, providing
        animals with the love, care, and support they need to thrive and helping
        them get ready for their furever home.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        How Pet Fostering Works:
      </Label>

      <Label styles={styles.subHeader} sz="medium" weight="bold">
        1. Contacting a Rescue Organization or Shelter:
      </Label>
      <Label styles={styles.body} sz="medium">
        The first step in becoming a pet foster parent is to reach out to local
        rescue organizations or animal shelters. These organizations typically
        have foster programs in place and can guide you through the fostering
        process.{"\n\n"}We make it easy on PetFosterz.com by allowing you to
        complete just one single application and submit it for any Rescue or
        Animal directly through our platform. The application process is
        important to help connect you to the right animals and rescues that best
        fit your lifestyle and preferences.
      </Label>

      <Label styles={styles.subHeader} sz="medium" weight="bold">
        2. Preparing Your Home:
      </Label>
      <Label styles={styles.body} sz="medium">
        Before bringing a foster pet home, it's essential to prepare your space.
        This may involve gathering necessary supplies such as food, bedding,
        toys, and grooming tools, as well as creating a designated area where
        your foster pet can feel safe and comfortable. Generally the rescue or
        shelter will pay or reimburse you for any expenses associated with the
        foster pet, but it's important to confirm with them first.
      </Label>

      <Label styles={styles.subHeader} sz="medium" weight="bold">
        3. Welcoming Your Foster Pet:
      </Label>
      <Label styles={styles.body} sz="medium">
        When you bring your foster pet home, give them time to adjust to their
        new surroundings. Offer plenty of love, patience, and understanding as
        they acclimate to their temporary home. It's not uncommon for them to
        need a few days to get adjusted to their new living space. Your role is
        to provide daily care for your foster animal, including feeding,
        grooming, exercise, and socialization until they get adopted.{"\n\n"}If
        you have any questions or doubts you can reach out to the Rescue or our
        team here at PetFosterz.com to help guide and assist you.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Benefits of Pet Fostering:
      </Label>

      <Label styles={styles.subHeader} sz="medium" weight="bold">
        Saves Lives:
      </Label>
      <Label styles={styles.body} sz="medium">
        You are literally saving lives by providing animals with a second chance
        at finding loving homes. Unfortunately due to the extreme overcrowding
        in animal rescues across the country, it is not uncommon that many pets
        need to be euthanized due to lack of space and staff to accommodate the
        6M+ animals entering rescues every single year.
      </Label>

      <Label styles={styles.subHeader} sz="medium" weight="bold">
        Socialization:
      </Label>
      <Label styles={styles.body} sz="medium">
        Fostering helps animals become more socialized and adapted to normal
        home life. This also greatly increases their chances of getting adopted.
      </Label>

      <Label styles={styles.subHeader} sz="medium" weight="bold">
        Flexibility:
      </Label>
      <Label styles={styles.body} sz="medium">
        Fostering offers flexibility, allowing you to choose the duration and
        type of pets that you foster. If you are considering to adopt, fostering
        is a great way to trial the pet in your home as well.
      </Label>

      <Label styles={styles.subHeader} sz="medium" weight="bold">
        Support:
      </Label>
      <Label styles={styles.body} sz="medium">
        Fostering animals comes with support from the rescue organizations and
        our community, including supplies, guidance, and assistance. All of the
        pet's expenses are fully paid by the rescue so you only need to focus on
        giving them a loving space and environment.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Conclusion
      </Label>
      <Label styles={styles.body} sz="medium">
        Pet fostering is a powerful way to make a difference in the lives of the
        millions of animals in need. By opening your heart and home to a foster
        pet, you're not only providing them with temporary care and support but
        also helping them on their journey to finding their furever families.
        Join us at PetFosterz.com in our mission to unleash love and save lives,
        one paw at a time.
      </Label>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    maxWidth: 1200,
    width: "100%",
    height: isMobile(Dimensions.get("window").width) ? 300 : 500,
    resizeMode: "cever",
    borderRadius: 8,
  },
  header: {
    paddingBottom: 16,
    paddingTop: 28,
  },
  subHeader: {
    color: "black",
    paddingBottom: 8,
  },
  body: {
    maxWidth: 1200,
    paddingBottom: 16,
    color: theme.colors.text.body,
  },
});

export default HowDoesFosteringWork;
