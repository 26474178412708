export const isEmailAddress = (input: string) => {
    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+((?!con)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i.test(
      input,
    );
  };

  const numerize = (s: string) => String(s || '').replace(/[^\d]/g, '');
  
  export const isPhoneNumber = (input: string) => {
    const phoneRegex = /^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]\d{3}-?\d{4}$/;
    return phoneRegex.test(numerize(input));
  };
  
  export const makeIntoPhoneNumber = (s: string) => {
    if (!s) {
      return '';
    }
    s = numerize(s);
    if (s.charAt(0) === '1') {
      s = s.slice(1);
    }
    if (s.length < 3) {
      return  s;
    }
    if (s.length < 7) {
      return s.slice(0, 3) + s.slice(3);
    }
    return '(' + s.slice(0, 3) + ') ' + s.slice(3, 6) + '-' + s.slice(6, 10);
  };

export const formatNotificationDate = (dateString: string): string => {
  const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    return date.toLocaleDateString('en-US', options);
};

export const timeAgo = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 }
  ];

  for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count > 0) {
          return count === 1
              ? `${count} ${interval.label} ago`
              : `${count} ${interval.label}s ago`;
      }
  }

  return 'just now';
}

