import {
  View,
  Pressable,
  Image,
  StyleSheet,
  Dimensions,
} from "react-native-web";
import { useNavigate } from "react-router-dom";
import Label from "../Label";
import { theme } from "../../config/theme";
import { Animal } from "../../types/types";
import { useState } from "react";

type Props = {
  pet: Animal;
  imageUrl?: string;
  name: string;
  breed: string;
  age: string;
  distance: number;
};
const PetCard = ({
  pet,
  imageUrl,
  name,
  breed,
  age,
  distance,
  ...rest
}: Props) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasLocationSet, setHasLocationSet] = useState(
    !!window.localStorage.getItem("LAT_LNG")
  );

  const cityState = pet.contact.address
    ? `${pet.contact.address.city}, ${pet.contact.address.state}`
    : "";
  const navigateToPet = (animal: Animal) => {
    navigate(`/pets/${animal.id}`, { state: { animal } });
  };
  return (
    <View {...rest} style={styles().petCard}>
      <Pressable onPress={() => navigateToPet(pet)}>
        <Image
          style={styles({ resizeMode: imageUrl ? "cover" : "center" }).petImage}
          source={
            imageUrl
              ? { uri: imageUrl }
              : require("../../assets/images/avatar.png")
          }
        />
      </Pressable>
      <Pressable onPress={() => navigateToPet(pet)} style={{ padding: 12 }}>
        <Label
          weight="medium"
          styles={{ color: theme.colors.secondary[400] }}
          numberOfLines={1}
          sz="large"
        >
          {name}
        </Label>

        <Label styles={{ paddingTop: 4 }} sz="small">
          {distance ? `${distance.toFixed(1)} mi away` : ""}
        </Label>
        <Label numberOfLines={1} styles={{ paddingTop: 4 }} sz="small">
          {breed}
        </Label>
        <Label styles={{ paddingTop: 4 }} sz="small">
          {cityState ? cityState : ""}
        </Label>
      </Pressable>
    </View>
  );
};

const styles = (props?: any) =>
  StyleSheet.create({
    petCard: {
      backgroundColor: "white",
      flex: 1,
      margin: 6,
      minWidth: 155,
      width: Dimensions.get("window").width / 2.5,
      maxWidth: 400,
      borderWidth: 2,
      borderRadius: 12,
      overflow: "hidden",
      borderColor: theme.colors.secondary[100],
      flexDirection: "column",
      justifyContent: "center",
    },
    petImage: {
      width: "100%",
      height: 165,
      resizeMode: props,
    },
  });

export default PetCard;
