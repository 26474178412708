import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { isEmailAddress } from "../../config/formatter";
import { useNavigate } from "react-router-dom";
import { StyleSheet, View, Dimensions } from "react-native-web";
import LabeledInput from "../../components/LabeledInput";
import Label from "../../components/Label";
import { theme } from "../../config/theme";
import {
  auth,
  loginUser,
  signInWithGooglePopup,
} from "../../services/firebaseService";
import { logAnalyticsEvent } from "../../services/firebaseService";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import { fetchFoster, fetchOrganization } from "../../services/apiService";
import { setIsRescueAccount } from "../../config/utils";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "login_visited" });
  }, []);

  const onSubmitLogin = async () => {
    if (!isEmailAddress(email.trim())) {
      setError("Invalid email address");
      return;
    }
    if (!password || password.length < 6) {
      setError("Password must be 6 or more characters");
      return;
    }

    const res = await loginUser(email.trim(), password);
    if (res.success) {
      if (auth.currentUser) {
        const res = await fetchFoster({ userId: auth.currentUser?.uid });
        if (res.application) {
          setIsRescueAccount(false);
          navigate("/update-account");
        } else {
          setIsRescueAccount(true);
          navigate("/rescue");
        }
      }
    } else {
      setError(res.error || "Failed");
    }
  };

  const onPressGoogle = async () => {
    const res = await signInWithGooglePopup();
    if (res?.user) {
      const appRes = await fetchFoster({ userId: res.user.uid });
      if (!appRes.application?.id) {
        // No application, check if it's an organization logging in...
        const orgRes = await fetchOrganization({ userId: res.user.uid });
        if (orgRes.organization) {
          setIsRescueAccount(true);
          navigate("/rescue");
        } else {
          // No application, no rescue, must be accidental sign up on login page -- assume fo
          setError(
            "No account found. Please try signing up or reaching out to our support team for further assistance."
          );
        }
      } else {
        navigate("/update-account");
      }
    }
  };

  return (
    <View style={styles.backgroundContainer}>
      <View style={styles.container}>
        <Label styles={styles.headerText} sz="xxlarge" weight="bold">
          Welcome back!
        </Label>
        <Label sz="medium" styles={styles.text}>
          Log in to access your account and view your dashboard.
        </Label>
        <GoogleLoginButton
          borderWidth={1}
          width={Dimensions.get("window").width - 50}
          onPress={onPressGoogle}
        />
        <Label sz="medium" styles={styles.orText}>
          OR
        </Label>
        <LabeledInput
          label="Email"
          value={email}
          onChange={setEmail}
          placeholder="Enter your email"
          width={Dimensions.get("window").width - 50}
        />
        <LabeledInput
          isSecure
          label="Password"
          value={password}
          onChange={setPassword}
          placeholder="******"
          width={Dimensions.get("window").width - 50}
        />
        <Label styles={{}} sz="small">
          {error}
        </Label>
        <Button
          borderColor="black"
          width={Dimensions.get("window").width - 50}
          onPress={onSubmitLogin}
        >
          Log in
        </Button>

        <Label styles={{ paddingTop: 24 }} sz="medium">
          Just getting started?{" "}
          <Label
            onPress={() => navigate("/create-account")}
            weight="medium"
            sz="medium"
            styles={{ color: theme.colors.secondary[400] }}
          >
            Sign up now
          </Label>
        </Label>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundContainer: {
    flex: 1,
    backgroundColor: theme.colors.secondary[100],
    alignItems: "center",
    width: Dimensions.get("window").width,
  },
  container: {
    borderRadius: 12,
    backgroundColor: "white",
    marginTop: 36,
    padding: 24,
    alignItems: "center",
  },
  text: {
    width: "100%",
    maxWidth: 800,
    paddingBottom: 32,
  },
  orText: {
    width: "100%",
    maxWidth: 800,
    textAlign: "center",
    paddingTop: 24,
    paddingBottom: 8,
  },
  headerText: {
    width: "100%",
    maxWidth: 800,
    paddingBottom: 8,
  },
});

export default Login;
