import * as React from "react";
import Svg, { Path } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

export const IconGoogle = ({ width = 23, height = 22 }: Props) => (
  <Svg width={width} height={height} viewBox="0 0 23 22" fill="none">
    <Path
      d="M22.5555 10.0415H21.75V10H12.75V14H18.4015C17.577 16.3285 15.3615 18 12.75 18C9.4365 18 6.75 15.3135 6.75 12C6.75 8.6865 9.4365 6 12.75 6C14.2795 6 15.671 6.577 16.7305 7.5195L19.559 4.691C17.773 3.0265 15.384 2 12.75 2C7.2275 2 2.75 6.4775 2.75 12C2.75 17.5225 7.2275 22 12.75 22C18.2725 22 22.75 17.5225 22.75 12C22.75 11.3295 22.681 10.675 22.5555 10.0415Z"
      fill="#FFC107"
    />
    <Path
      d="M3.90295 7.3455L7.18845 9.755C8.07745 7.554 10.2305 6 12.75 6C14.2795 6 15.671 6.577 16.7305 7.5195L19.559 4.691C17.773 3.0265 15.384 2 12.75 2C8.90895 2 5.57795 4.1685 3.90295 7.3455Z"
      fill="#FF3D00"
    />
    <Path
      d="M12.75 22.0001C15.333 22.0001 17.68 21.0116 19.4545 19.4041L16.3595 16.7851C15.3218 17.5743 14.0538 18.0011 12.75 18.0001C10.149 18.0001 7.94053 16.3416 7.10853 14.0271L3.84753 16.5396C5.50253 19.7781 8.86353 22.0001 12.75 22.0001Z"
      fill="#4CAF50"
    />
    <Path
      d="M22.5555 10.0415H21.75V10H12.75V14H18.4015C18.0071 15.1082 17.2967 16.0766 16.358 16.7855L16.3595 16.7845L19.4545 19.4035C19.2355 19.6025 22.75 17 22.75 12C22.75 11.3295 22.681 10.675 22.5555 10.0415Z"
      fill="#1976D2"
    />
  </Svg>
);
