import React, { useState } from "react";
import { View } from "react-native-web";
import Label from "../Label";
import { FaMapMarkerAlt } from "react-icons/fa";
import { theme } from "../../config/theme";

type Props = {
  onLocationFound: () => void;
};

const UseCurrentLocation = ({ onLocationFound, ...rest }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const fetchLocation = () => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          window.localStorage.setItem("LAT_LNG", `${latitude},${longitude}`);
          onLocationFound();
          setIsLoading(false);
        },
        (error) => {
          console.error(`Error getting current position: ${error.message}`);
          setIsLoading(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setIsLoading(false);
    }
  };
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Label
        onPress={() => {
          fetchLocation();
        }}
        sz="small"
        numberOfLines={1}
        weight="medium"
        styles={{
          paddingLeft: 12,
          paddingBottom: 2,
          paddingRight: 2,
          textDecorationLine: "underline",
          color: theme.colors.text.link,
        }}
      >
        {isLoading ? "Fetching..." : "Use current location"}
      </Label>

      <FaMapMarkerAlt
        style={{ paddingBottom: 2 }}
        size={12}
        color={theme.colors.text.link}
      />
    </View>
  );
};

export default UseCurrentLocation;
