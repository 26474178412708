// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Urbanist-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Urbanist-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Urbanist-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Urbanist';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: 300; /* 'lighter' is not a valid font-weight value in @font-face */
  font-style: normal;
}

/* Apply the font globally */
body {
  font-family: 'Urbanist', sans-serif;
}

code {
  font-family: 'Urbanist';
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,+DAAkE;EAClE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,+DAA+D;EAC/D,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,+DAAgE;EAChE,gBAAgB,EAAE,6DAA6D;EAC/E,kBAAkB;AACpB;;AAEA,4BAA4B;AAC5B;EACE,mCAAmC;AACrC;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["@font-face {\n  font-family: 'Urbanist';\n  src: url('./assets/fonts/Urbanist-Regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Urbanist';\n  src: url('./assets/fonts/Urbanist-Bold.ttf') format('truetype');\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Urbanist';\n  src: url('./assets/fonts/Urbanist-Light.ttf') format('truetype');\n  font-weight: 300; /* 'lighter' is not a valid font-weight value in @font-face */\n  font-style: normal;\n}\n\n/* Apply the font globally */\nbody {\n  font-family: 'Urbanist', sans-serif;\n}\n\ncode {\n  font-family: 'Urbanist';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
