import {
  View,
  Pressable,
  Image,
  StyleSheet,
  Dimensions,
} from "react-native-web";
import { useNavigate } from "react-router-dom";
import Label from "../Label";
import { theme } from "../../config/theme";

type Props = {
  id: string;
  imagePath: NodeRequire;
  name: string;
};
const BlogCard = ({ id, name, imagePath, ...rest }: Props) => {
  const navigate = useNavigate();

  const navigateToBlog = () => {
    navigate(`/foster-tails/${id}`);
  };
  return (
    <Pressable onPress={() => navigateToBlog()}>
      <View {...rest} style={styles.blogCard}>
        <Image style={styles.blogImage} source={imagePath} />
        <View
          style={{
            backgroundColor: "black",
            opacity: 0.25,
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        />
        <Label
          weight="medium"
          styles={{
            color: "white",
            position: "absolute",
            bottom: 16,
            left: 16,
          }}
          numberOfLines={2}
          sz="xlarge"
        >
          {name}
        </Label>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  blogCard: {
    elevation: 5,
    backgroundColor: "white",
    flex: 1,
    margin: 6,
    minWidth: 155,
    width: Dimensions.get("window").width - 40,
    maxWidth: 550,
    borderWidth: 2,
    borderRadius: 12,
    overflow: "hidden",
    borderColor: theme.colors.info,
    flexDirection: "column",
    justifyContent: "center",
  },
  blogImage: {
    width: "100%",
    height: 200,
    resizeMode: "cover",
  },
});

export default BlogCard;
