import * as React from "react";
import Svg, { Defs, G, Path, Rect } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const IconHome = ({ width = 60, height = 61, fill = "#FFFFFF" }: Props) => (
  <Svg width="60" height="61" viewBox="0 0 60 61" fill="none">
    <G clipPath="url(#clip0_257_4)">
      <Path
        d="M30 0.48819L0 17.9883L1.77535 21.0319L3.8327 19.8319V51.3544H14.5488V50.5721C14.5488 42.0522 21.4802 35.1209 30 35.1209C38.5198 35.1209 45.4512 42.0523 45.4512 50.5721V51.3544H56.1673V19.8319L58.2247 21.0319L60 17.9883L30 0.48819ZM43.1387 27.8957C42.9708 30.6034 40.8021 32.7721 38.0942 32.9401C37.979 32.9472 37.8639 32.9508 37.7496 32.9508C35.8096 32.9508 34.0373 31.9296 33.0655 30.2286C32.744 29.6658 32.1096 29.3162 31.4101 29.3162H28.5897C27.8901 29.3162 27.2559 29.6658 26.9343 30.2286C25.9053 32.0297 23.9792 33.0683 21.9055 32.94C19.1977 32.772 17.029 30.6033 16.8611 27.8954C16.7693 26.4168 17.2648 25.0084 18.2578 23.9201C17.2648 22.8317 16.7693 21.4233 16.8611 19.9446C17.0291 17.2368 19.1978 15.0681 21.9055 14.9C23.9776 14.7705 25.9053 15.8103 26.9343 17.6114C27.2558 18.1742 27.8901 18.5238 28.5897 18.5238H31.4101C32.1096 18.5238 32.7439 18.1742 33.0655 17.6115C34.0945 15.8103 36.0209 14.7708 38.0942 14.9C40.8021 15.0681 42.9708 17.2368 43.1386 19.9446C43.2305 21.4232 42.7349 22.8316 41.7419 23.9199C42.7352 25.0085 43.2307 26.4169 43.1387 27.8957Z"
        fill={fill}
      />
      <Path
        d="M37.753 25.682V22.1583C38.2753 22.1583 38.76 21.9486 39.118 21.5678C39.4754 21.1874 39.6543 20.6886 39.6218 20.1631C39.5646 19.2413 38.7976 18.4744 37.8758 18.4171C37.1539 18.3719 36.4826 18.7333 36.125 19.3596C35.1778 21.0176 33.3711 22.0477 31.41 22.0477H28.5896C26.6286 22.0477 24.8219 21.0176 23.8746 19.3596C23.5168 18.7334 22.8464 18.3726 22.1238 18.4172C21.2019 18.4744 20.435 19.2414 20.3778 20.1631C20.3453 20.6887 20.5241 21.1876 20.8816 21.5678C21.2396 21.9487 21.7243 22.1583 22.2465 22.1583V25.682C21.7242 25.682 21.2395 25.8918 20.8814 26.2727C20.524 26.653 20.3453 27.1517 20.3778 27.6772C20.435 28.5991 21.202 29.366 22.1237 29.4232C22.8486 29.4673 23.5169 29.1069 23.8746 28.4807C24.8217 26.8227 26.6284 25.7927 28.5896 25.7927H31.41C33.371 25.7927 35.1777 26.8227 36.125 28.4807C36.4828 29.1069 37.1551 29.469 37.8758 29.4232C38.7977 29.366 39.5646 28.5991 39.6218 27.6773C39.6543 27.1517 39.4755 26.653 39.1181 26.2727C38.7601 25.8918 38.2754 25.682 37.753 25.682Z"
        fill={fill}
      />
      <Path
        d="M29.9998 38.6444C23.423 38.6444 18.0723 43.9951 18.0723 50.572V60.3847H41.9273V50.572C41.9273 43.9951 36.5766 38.6444 29.9998 38.6444Z"
        fill={fill}
      />
      <Path d="M3.83252 54.8779H14.5486V60.3847H3.83252V54.8779Z" fill={fill} />
      <Path d="M45.4512 54.8779H56.1673V60.3847H45.4512V54.8779Z" fill={fill} />
    </G>
    <Defs>
      <clipPath id="clip0_257_4">
        <Rect
          width="60"
          height="60"
          fill="white"
          transform="translate(0 0.436523)"
        />
      </clipPath>
    </Defs>
  </Svg>
);

export default IconHome;
