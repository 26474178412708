import { Pressable, StyleSheet, View } from "react-native-web";
import Label from "../Label";
import { theme } from "../../config/theme";
import { useEffect, useState } from "react";
import { Organization } from "../../types/types";
import { timeAgo } from "../../config/formatter";
import {
  createApplication,
  fetchOrganizationById,
  submitApplication,
} from "../../services/apiService";
import Button from "../Button";

type Props = {
  didSubmit: boolean;
  userId: string;
  createdAt: string;
  organization_id: string;
};
const FosterRequestCard = ({
  didSubmit,
  userId,
  createdAt,
  organization_id,
  ...rest
}: Props) => {
  const [organization, setOrganization] = useState<Organization>();
  const [didPress, setDidPress] = useState(false);
  useEffect(() => {
    const getOrg = async () => {
      const res = await fetchOrganizationById(organization_id);
      if (res.organization) {
        setOrganization(res.organization);
      }
    };
    getOrg();
  }, [organization_id]);

  return (
    <Pressable style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
        <View style={styles.avatar}>
          <Label styles={{ color: "white" }} sz="small" weight="medium">
            {organization?.name?.charAt(0)}
          </Label>
        </View>
        <View style={{ flex: 1 }}>
          <Label
            weight="medium"
            styles={{
              color: "black",
            }}
            numberOfLines={2}
            sz="small"
          >
            {organization?.name || "The rescue"}{" "}
            <Label weight="light" sz="small">
              has requested your interest in fostering for them.
            </Label>
          </Label>
          <Label styles={{ paddingVertical: 4 }} weight="light" sz="xsmall">
            {timeAgo(createdAt)}
          </Label>
        </View>
        {!didSubmit && (
          <Button
            isDisabled={didPress}
            width={110}
            onPress={() => {
              setDidPress(true);
              submitApplication({
                auth_user_id: userId,
                organization_email: organization?.email || "",
                organization_name: organization?.name || "",
                pet_name: "",
              });
              createApplication({
                auth_user_id: userId,
                organization_petfinder_id: organization?.petfinder_id,
                organization_id: organization?.id,
              });
            }}
          >
            {didPress ? "Sent!" : "Submit application"}
          </Button>
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    marginVertical: 8,
    padding: 12,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.colors.border,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.colors.secondary[200],
    borderRadius: 18,
    width: 36,
    height: 36,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default FosterRequestCard;
