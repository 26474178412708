import { View, Pressable, Dimensions } from "react-native-web";
import Modal from "react-modal";
import { IconSuccess } from "../../../assets/icons/IconSuccess";
import Label from "../../Label";
import IconX from "../../../assets/icons/IconX";
import Button from "../../Button";
import { theme } from "../../../config/theme";
import { useState } from "react";
import LabeledInput from "../../LabeledInput";
import LabeledCheckBox from "../../LabeledCheckBox";
import useOrganization from "../../../hooks/useOrganization";
import { createAnimal } from "../../../services/animalService";
import DragAndDropImageUpload from "../../DragAndDropImageUpload";
import { createMediaFile } from "../../../services/mediaFileService";

type Props = {
  isVisible: boolean;
  onDismiss: () => void;
};
const SubmitPetModal = ({ isVisible, onDismiss }: Props) => {
  const organization = useOrganization();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [didSubmit, setDidSubmit] = useState(false);

  const [photoUrl, setPhotoUrl] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [breed, setBreed] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [size, setSize] = useState("");
  const [status, setStatus] = useState("Available");
  const [tags, setTags] = useState<string[]>([]);
  const [isCampaign, setIsCampaign] = useState(false);

  const onResetAndDismiss = () => {
    setDidSubmit(false);
    setPhotoUrl("");
    setName("");
    setDescription("");
    setBreed("");
    setAge("");
    setGender("");
    setSize("");
    setStatus("");
    setTags([]);
    setIsCampaign(false);

    onDismiss();
  };

  const addRescueAnimal = async () => {
    try {
      if (!organization) {
        return;
      }
      setIsLoading(true);
      setError("");
      const res = await createAnimal({
        organization_id: organization.id,
        name,
        type,
        description,
        breed,
        age,
        gender,
        size,
        tags,
        status,
      });
      if (res.error) {
        setError(res.error.toString());
      } else {
        if (!res.animal) {
          setError("Failed to create animal");
          return;
        }
        const mediaRes = await createMediaFile({
          url: photoUrl,
          related_id: res.animal?.id,
          related_type: "animal",
          media_type: "image",
        });
        if (mediaRes.error) {
          setError(mediaRes.error);
        } else {
          setDidSubmit(true);
        }
      }
    } catch (error) {
      setError("Failed with exception");
    }
    setIsLoading(false);
  };

  const SuccessState = () => {
    return (
      <View
        style={{
          height: "60%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconSuccess color={theme.colors.secondary[200]} />
        <Label
          styles={{ paddingTop: 28, paddingBottom: 8, textAlign: "center" }}
          sz="large"
          weight="medium"
        >
          Your Rescue Animal is Live!
        </Label>
        <Label
          styles={{
            paddingBottom: 28,
            paddingStart: 8,
            paddingEng: 8,
            textAlign: "center",
          }}
          sz="medium"
        >
          {`We have notified our community of foster homes and ${
            name || "this rescue"
          } is now listed on our available pets page.\n\nHold tight for now. You should begin receiving applications soon if there is a match.`}
        </Label>
        <Button
          backgroundColor="white"
          borderColor={theme.colors.secondary[300]}
          textColor={theme.colors.secondary[300]}
          onPress={onResetAndDismiss}
          width={Dimensions.get("window").width - 80}
        >
          Dismiss
        </Button>
      </View>
    );
  };
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "#00000065",
        },
        content: {
          alignItems: "center",
          justifySelf: "center",
          maxWidth: 800,
          color: theme.colors.secondary[100],
          borderRadius: "12px",
        },
      }}
      isOpen={isVisible}
      onRequestClose={onDismiss}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 24,
        }}
      >
        <Pressable onPress={onResetAndDismiss}>
          <IconX width={36} height={36} />
        </Pressable>
        {!didSubmit && (
          <Label
            styles={{
              color: "black",
              textAlign: "center",
            }}
            sz="large"
            weight="medium"
          >
            Add New Animal
          </Label>
        )}

        <View style={{ width: 36 }}></View>
      </View>

      {didSubmit ? (
        <SuccessState />
      ) : (
        <View style={{ alignItems: "center" }}>
          <DragAndDropImageUpload
            uploadedImageUrl={photoUrl}
            onUploadComplete={(url: string) => {
              setPhotoUrl(url);
            }}
            onUploadError={(e) => {
              setError(e);
            }}
          />
          <View style={{ paddingTop: 16, width: "100%" }}>
            <Label
              styles={{
                color: "black",
              }}
              sz="medium"
              weight="medium"
            >
              Animal Type
            </Label>
            <LabeledCheckBox
              onPress={(v) => {
                if (v.target.checked) {
                  setType("Dog");
                }
              }}
              isSelected={type === "Dog"}
              label=""
              value="Dog"
            />
            <LabeledCheckBox
              onPress={(v) => {
                if (v.target.checked) {
                  setType("Cat");
                }
              }}
              isSelected={type === "Cat"}
              label=""
              value="Cat"
            />
            <LabeledInput
              label="Name"
              value={name}
              onChange={setName}
              placeholder="Enter animal name"
            />
            <LabeledInput
              height={100}
              label="Description (up to 1000 characters)"
              value={description}
              multiline
              onChange={(v) => {
                setDescription(v.slice(0, 1000));
              }}
              placeholder="Enter a brief description about their history or what they need most."
            />
            <LabeledInput
              label="Breed"
              value={breed}
              onChange={setBreed}
              placeholder="You can put 'mixed' or 'mutt' if not sure"
            />
            <LabeledInput
              label="Age"
              value={age}
              onChange={setAge}
              placeholder="Please specify in years or months"
            />
          </View>
          <View style={{ width: "100%" }}>
            <Label
              styles={{
                paddingTop: 16,
                color: "black",
              }}
              sz="medium"
              weight="medium"
            >
              Gender
            </Label>
            <LabeledCheckBox
              onPress={(v) => {
                if (v.target.checked) {
                  setGender("Male");
                }
              }}
              isSelected={gender === "Male"}
              label=""
              value="Male"
            />
            <LabeledCheckBox
              onPress={(v) => {
                if (v.target.checked) {
                  setGender("Female");
                }
              }}
              isSelected={gender === "Female"}
              label=""
              value="Female"
            />
          </View>
          <View style={{ width: "100%", paddingBottom: 16 }}>
            <Label
              styles={{
                paddingBottom: 8,
                paddingTop: 16,
                color: "black",
              }}
              sz="medium"
              weight="medium"
            >
              Size
            </Label>
            <LabeledCheckBox
              onPress={(v) => {
                if (v.target.checked) {
                  setSize("Small");
                }
              }}
              isSelected={size === "Small"}
              label=""
              value="Small"
            />
            <LabeledCheckBox
              onPress={(v) => {
                if (v.target.checked) {
                  setSize("Medium-Large");
                }
              }}
              isSelected={size === "Medium-Large"}
              label=""
              value="Medium-Large"
            />
          </View>

          <View style={{ paddingBottom: 16 }}>
            <LabeledCheckBox
              onPress={(v) => {
                setIsCampaign(v.target.checked);
              }}
              isSelected={isCampaign}
              label="Email Campaign"
              value="Create campaign"
            />
            <Label
              styles={{ marginTop: -8, color: theme.colors.text.subtle }}
              sz="xsmall"
            >
              If you choose to create a campaign, we will notify all members in
              our community that look like a potential match for this rescue
              animal.
            </Label>
          </View>
          {error && (
            <Label sz="small" styles={{ color: theme.colors.error }}>
              {error || ""}
            </Label>
          )}
          <Button
            isDisabled={isLoading}
            onPress={addRescueAnimal}
            width={Dimensions.get("window").width - 80}
          >
            Add Rescue Animal
          </Button>
        </View>
      )}
    </Modal>
  );
};
export default SubmitPetModal;
