import { StyleSheet, Picker } from "react-native-web";
import { theme } from "../../config/theme";

type Props = {
  onPressItem: (v: string, i: number) => void;
  items: string[];
  selectedValue: string;
};

const ItemPicker = ({ onPressItem, items, selectedValue }: Props) => {
  return (
    <Picker
      selectedValue={selectedValue}
      onValueChange={(v: string, i: number) => onPressItem(v, i)}
      style={styles.container}
    >
      {items.map((item, index) => {
        return (
          <Picker.Item
            key={index}
            color={theme.colors.selector}
            label={item}
            value={item}
          />
        );
      })}
    </Picker>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: "white",
    backgroundColor: "white",
    borderWidth: 0,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default ItemPicker;
