import { useNavigate } from "react-router-dom";
import { signOut } from "../../services/firebaseService";
import { theme } from "../../config/theme";
import { Dimensions, View, Pressable, StyleSheet } from "react-native-web";
import Label from ".././Label";
import { isDesktop, isMobile, isRescueAccount } from "../../config/utils";
import { IconLogoName } from "../../assets/icons/IconLogoName";
import { useEffect, useState } from "react";
import { IconLogoMark } from "../../assets/icons/IconLogoMark";

type Props = {
  isAuthed: boolean;
  hamburgerOpen: boolean;
  setHamburgerOpen: (v: boolean) => void;
};
const NavigationHeader = ({
  isAuthed,
  hamburgerOpen,
  setHamburgerOpen,
}: Props) => {
  const navigate = useNavigate();
  const width = Dimensions.get("window").width;

  const [isRescue, setIsRescue] = useState(false);

  useEffect(() => {
    const checkForRescue = async () => {
      const rescue = await isRescueAccount();
      setIsRescue(rescue);
    };
    checkForRescue();
  }, []);

  const goToLogin = () => {
    navigate("/login");
  };
  const goToSignUp = () => {
    navigate("/create-account");
  };
  const goToAccount = () => {
    navigate("/update-account");
  };
  const goToLogout = async () => {
    await signOut();
    navigate("/");
  };
  const goToRescueDashboard = () => {
    navigate("/rescue");
  };

  return (
    <View>
      <View style={styles.topContainer}>
        {isMobile(width) ? (
          <Pressable onPress={() => setHamburgerOpen(!hamburgerOpen)}>
            <Label styles={{}} sz="xxxlarge">
              =
            </Label>
          </Pressable>
        ) : (
          <Pressable
            style={{ alignItems: "center", flexDirection: "row" }}
            onPress={() => navigate("/")}
          >
            <IconLogoMark />
            <IconLogoName />
          </Pressable>
        )}

        {isMobile(width) ? (
          <Pressable
            style={{ alignItems: "center" }}
            onPress={() => navigate("/")}
          >
            <IconLogoMark />
            <View style={{ height: 6 }} />
            <IconLogoName />
          </Pressable>
        ) : (
          <View style={styles.row}>
            <Pressable
              onPress={() =>
                isRescue && isAuthed
                  ? goToRescueDashboard()
                  : isAuthed
                  ? goToAccount()
                  : goToSignUp()
              }
            >
              <Label styles={{}} sz="medium">
                {isRescue && isAuthed
                  ? "Dashboard"
                  : isAuthed
                  ? "My Application"
                  : "Sign up"}
              </Label>
            </Pressable>
            <Pressable onPress={() => (isAuthed ? goToLogout() : goToLogin())}>
              <Label styles={{}} sz="medium">
                {isAuthed ? "Log out" : "Login"}
              </Label>
            </Pressable>
          </View>
        )}
      </View>

      {isDesktop(width) && (
        <View
          style={[
            styles.bottomContainer,
            { backgroundColor: theme.colors.secondary[400] },
          ]}
        >
          <Pressable
            onPress={() => navigate("/pets", { state: { petType: "Dog" } })}
          >
            <Label styles={styles.text} weight="medium" sz="medium">
              FOSTER A DOG
            </Label>
          </Pressable>

          <Pressable
            onPress={() => navigate("/pets", { state: { petType: "Cat" } })}
          >
            <Label weight="medium" styles={styles.text} sz="medium">
              FOSTER A CAT
            </Label>
          </Pressable>

          <Pressable onPress={() => navigate("/organizations")}>
            <Label styles={styles.text} weight="medium" sz="medium">
              FIND A RESCUE
            </Label>
          </Pressable>

          <Pressable onPress={() => navigate("/foster-tails")}>
            <Label styles={styles.text} weight="medium" sz="medium">
              {"FOSTER RESOURCES"}
            </Label>
          </Pressable>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  topContainer: {
    backgroundColor: "white",
    width: "100%",
    paddingLeft: isDesktop(Dimensions.get("window").width) ? 36 : 16,
    paddingRight: isDesktop(Dimensions.get("window").width) ? 36 : 16,
    paddingTop: 16,
    paddingBottom: 16,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  bottomContainer: {
    width: "100%",
    paddingLeft: isDesktop(Dimensions.get("window").width) ? 36 : 16,
    paddingRight: isDesktop(Dimensions.get("window").width) ? 36 : 16,
    paddingTop: 12,
    paddingBottom: 12,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  image: {
    width: 100,
    height: 75,
  },
  text: {
    color: "white",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: 205,
  },
});

export default NavigationHeader;
