import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { createFoster, createOrganization } from "./apiService";
import { getStorage } from "firebase/storage";

// Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics();
export const storage = getStorage(app);

// Initialize Firebase Auth provider for Google login
const provider = new GoogleAuthProvider();
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account",
});

type AuthResult = {
  success: boolean;
  error?: string;
};

export const createUser = async (
  email: string,
  password: string,
  isOrganization?: boolean,
  name?: string,
  petfinder_id?: string,
  rescuegroup_id?: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    if (res.user) {
      const actionCodeSettings = {
        url: "https://petfosterz.com",
      };
      sendEmailVerification(res.user, actionCodeSettings);
      logAnalyticsEvent({ eventName: "contact_information_created" });
      if (isOrganization) {
        await createOrganization({
          auth_user_id: res.user.uid,
          email,
          name,
          petfinder_id,
          rescuegroup_id,
        });
      } else {
        await createFoster({
          auth_user_id: res.user.uid,
          email,
        });
      }

      return { success: true } as AuthResult;
    } else {
      const errorMessage = "Failed to get user";
      return { success: false, error: errorMessage } as AuthResult;
    }
  } catch (e) {
    const errorMessage =
      "An account may already exist for this email or an error occurred";
    return { success: false, error: errorMessage } as AuthResult;
  }
};

export const loginUser = async (email: string, password: string) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    if (res.user) {
      return { success: true } as AuthResult;
    } else {
      const errorMessage =
        "An account already exists for this email. Try to login instead or reset your password.";
      return { success: false, error: errorMessage } as AuthResult;
    }
  } catch (e) {
    const errorMessage =
      "Failed to sign in. Please try again later or try contacting our support team.";
    return { success: false, error: errorMessage } as AuthResult;
  }
};

export const isAuthenticated = () => {
  return !!auth.currentUser;
};

export const signOut = async () => {
  auth.signOut();
};

export const signInWithGooglePopup = async () => {
  try {
    return signInWithPopup(auth, provider);
  } catch (e) {
    console.log(e);
    return null;
  }
};

type Props = {
  eventName: string;
  data?: any;
};
export const logAnalyticsEvent = ({ eventName, data }: Props) => {
  logEvent(analytics, eventName, data);
};
