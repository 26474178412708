import * as React from "react";
import Svg, { Defs, G, Path, Rect } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const IconMedical = ({ width = 61, height = 62, fill = "#2F6B61" }: Props) => (
  <Svg width="60" height="61" viewBox="0 0 61 62" fill="none">
    <G clipPath="url(#clip0_257_28)">
      <Path
        d="M32.0784 46.2856C31.0235 43.3175 31.7781 40.007 34.0151 37.7892C34.915 36.8352 35.6942 35.7743 36.3353 34.6303C37.3298 33.0062 38.3494 31.3439 40.3036 30.4714V27.6098C39.5007 28.3314 38.4594 28.7306 37.3799 28.7307C35.9992 28.6942 34.6938 28.0934 33.7679 27.0685C32.5818 25.7938 31.8252 24.1792 31.6045 22.4521C31.3298 20.733 31.6011 18.971 32.3801 17.4141C33.0621 15.9643 34.4097 14.9394 35.9889 14.6691C37.6247 14.4997 39.2389 15.1476 40.3036 16.4011V8.06152C40.2988 3.85234 36.8878 0.441326 32.6786 0.436523H28.3214C24.1122 0.441326 20.7012 3.85234 20.6964 8.06152V18.9544C20.6964 20.1576 19.721 21.133 18.5179 21.133H7.625C3.41582 21.1378 0.00480227 24.5488 0 28.758V33.1151C0.00480227 37.3243 3.41582 40.7353 7.625 40.7401H18.5179C19.721 40.7401 20.6964 41.7155 20.6964 42.9187V53.8115C20.7012 58.0207 24.1122 61.4317 28.3214 61.4365H32.6786C36.8878 61.4317 40.2988 58.0207 40.3036 53.8115V48.5426C39.2932 49.1662 38.1235 49.4829 36.9366 49.4544C34.8083 49.538 32.8597 48.2671 32.0784 46.2856ZM7.625 25.4901C5.82021 25.4901 4.35714 26.9532 4.35714 28.758C4.35714 29.3595 3.86945 29.8472 3.26786 29.8472C2.66626 29.8472 2.17857 29.3595 2.17857 28.758C2.18217 25.7515 4.61851 23.3151 7.625 23.3115C8.2266 23.3115 8.71429 23.7992 8.71429 24.4008C8.71429 25.0024 8.2266 25.4901 7.625 25.4901ZM13.0714 25.4901H11.9821C11.3805 25.4901 10.8929 25.0024 10.8929 24.4008C10.8929 23.7992 11.3805 23.3115 11.9821 23.3115H13.0714C13.673 23.3115 14.1607 23.7992 14.1607 24.4008C14.1607 25.0024 13.673 25.4901 13.0714 25.4901ZM29.3584 36.3775C27.2799 36.2316 25.4568 34.9369 24.6342 33.0225C23.3271 30.3189 24.0177 27.2678 26.1799 26.2199C28.3421 25.172 31.1656 26.5173 32.476 29.2198C33.7864 31.9223 33.0936 34.9734 30.9314 36.0213C30.441 36.2582 29.903 36.38 29.3584 36.3775Z"
        fill={fill}
      />
      <Path
        d="M36.2982 16.8259C35.4137 17.0072 34.6716 17.6055 34.307 18.4315C33.7419 19.5812 33.5514 20.8791 33.7623 22.1427C33.9169 23.4143 34.4654 24.6057 35.3309 25.55C35.8797 26.2858 36.787 26.6626 37.6957 26.532C38.6043 26.4014 39.3687 25.7843 39.6881 24.9237C40.1077 24.0909 40.3189 23.1687 40.3035 22.2364V22.2037C40.3022 21.872 40.2778 21.5408 40.2305 21.2125C39.8525 18.5829 38.0563 16.5765 36.2982 16.8259Z"
        fill={fill}
      />
      <Path
        d="M27.7769 28.0357C27.5508 28.0331 27.3272 28.0827 27.1234 28.1806C26.0613 28.6948 25.8162 30.4779 26.5885 32.0715C27.3608 33.6652 28.9098 34.5769 29.9751 34.0617C31.0404 33.5464 31.2823 31.7644 30.5089 30.1707C30.0472 29.0205 29.0047 28.2057 27.7769 28.0357Z"
        fill={fill}
      />
      <Path
        d="M48.949 35.7675C47.57 33.5138 46.6582 32.0258 43.5712 32.0258C40.4842 32.0258 39.5724 33.5138 38.1934 35.7675C37.4628 37.0615 36.5744 38.2598 35.5486 39.3349C33.9563 40.9425 33.3951 43.3048 34.0944 45.4567C34.54 46.6209 35.6925 47.3587 36.9364 47.2758C37.9296 47.2984 38.8998 46.9745 39.6803 46.3597C42.0011 44.6765 45.1413 44.6765 47.4621 46.3597C48.2426 46.9745 49.2128 47.2984 50.206 47.2758C51.4499 47.3587 52.6024 46.6209 53.048 45.4567C53.7467 43.3044 53.1847 40.942 51.5916 39.3349C50.5666 38.2597 49.6789 37.0614 48.949 35.7675Z"
        fill={fill}
      />
      <Path
        d="M53.3817 22.1427C53.0821 24.2276 51.5386 26.8267 49.4494 26.5293C47.3601 26.2319 46.6118 23.3115 46.9124 21.2125C47.2392 18.962 48.8612 16.5405 50.8447 16.8259C52.9198 17.1244 53.6856 20.0339 53.3817 22.1427Z"
        fill={fill}
      />
      <Path
        d="M60.5481 32.0716C59.7747 33.6641 58.2236 34.5769 57.1626 34.0617C56.1016 33.5465 55.8555 31.7644 56.6278 30.1708C57.2813 28.8244 58.8063 27.5968 60.0133 28.1806C61.0753 28.6948 61.3204 30.4779 60.5481 32.0716Z"
        fill={fill}
      />
    </G>
    <Defs>
      <clipPath id="clip0_257_28">
        <Rect
          width="61"
          height="61"
          fill="white"
          transform="translate(0 0.436523)"
        />
      </clipPath>
    </Defs>
  </Svg>
);

export default IconMedical;
