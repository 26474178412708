import {
  View,
  Pressable,
  Image,
  StyleSheet,
  Dimensions,
} from "react-native-web";
import Label from "../Label";
import { theme } from "../../config/theme";
import { Animal } from "../../types/types";
import { isMobile } from "../../config/utils";
import useOrganization from "../../hooks/useOrganization";
import useAddress from "../../hooks/useAddress";
import { useNavigate } from "react-router-dom";

type Props = {
  animal: Animal;
  imageUrl?: string;
  name: string;
  breed: string;
  age: string;
};
const AnimalCard = ({ animal, imageUrl, name, breed, age, ...rest }: Props) => {
  const navigate = useNavigate();
  const navigateToPet = (animal: Animal) => {
    navigate(`/animals/${animal.id}`, { state: { animal } });
  };
  const organization = useOrganization(animal?.organization_id);
  const address = useAddress(organization?.auth_user_id);
  return (
    <View {...rest} style={styles().petCard}>
      <Pressable onPress={() => navigateToPet(animal)}>
        <Image
          style={styles({ resizeMode: imageUrl ? "cover" : "center" }).petImage}
          source={
            imageUrl
              ? { uri: imageUrl }
              : require("../../assets/images/avatar.png")
          }
        />
      </Pressable>
      <Pressable onPress={() => navigateToPet(animal)} style={{ padding: 12 }}>
        <Label
          weight="medium"
          styles={{ color: theme.colors.secondary[400] }}
          numberOfLines={1}
          sz="large"
        >
          {name}
        </Label>
        <Label numberOfLines={1} styles={{ paddingTop: 4 }} sz="small">
          {breed}
        </Label>
        <Label numberOfLines={1} styles={{ paddingTop: 4 }} sz="small">
          {address?.city ? `${address.city}, ${address.state}` : ""}
        </Label>
      </Pressable>
    </View>
  );
};

const styles = (props?: any) =>
  StyleSheet.create({
    petCard: {
      backgroundColor: "white",
      flex: 1,
      margin: 6,
      minWidth: 185,
      width: Dimensions.get("window").width / 2.5,
      maxWidth: 200,
      borderWidth: 2,
      borderRadius: 12,
      overflow: "hidden",
      borderColor: theme.colors.secondary[100],
      flexDirection: "column",
      justifyContent: "center",
    },
    petImage: {
      width: "100%",
      height: isMobile(Dimensions.get("window").width) ? 155 : 165,
      resizeMode: props,
    },
  });

export default AnimalCard;
