import { StyleSheet, View, Dimensions } from "react-native-web";
import { theme } from "../../../config/theme";
import Label from "../../Label";
import LabeledInput from "../../LabeledInput";
import { useState } from "react";
import { Foster } from "../../../types/types";
import { logAnalyticsEvent } from "../../../services/firebaseService";
import { updateFoster } from "../../../services/apiService";
import Button from "../../Button";
import LabeledSwitch from "../../LabeledSwitch";

type Props = {
  isEditable: boolean;
  application?: Foster;
  userId: string;
  onRefresh?: () => void;
};
const HomeInformation = ({
  isEditable,
  application,
  userId,
  onRefresh,
}: Props) => {
  const [isEditing, setIsEditing] = useState(
    !application?.residence_description &&
      !application?.roommates_description &&
      isEditable
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [residence_description, setResidence_description] = useState(
    application?.residence_description || ""
  );
  const [residence_owned, setResidence_owned] = useState(
    application?.residence_owned || false
  );
  const [existing_pets_description, setExisting_pets_description] = useState(
    application?.existing_pets_description || ""
  );

  const [roommatesDescription, setRoommatesDescription] = useState(
    application?.roommates_description || ""
  );

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }
    setError("");
    setIsLoading(true);

    logAnalyticsEvent({ eventName: "home_information_updated" });
    if (application?.id) {
      const res = await updateFoster({
        id: application?.id,
        auth_user_id: userId,
        residence_description: residence_description,
        residence_owned: residence_owned,
        roommates_description: roommatesDescription,
        existing_pets_description: existing_pets_description,
      });
      if (res.success) {
        setIsEditing(false);
        onRefresh && onRefresh();
      } else {
        setError("Failed to save. Please try again later.");
      }
    }

    setIsLoading(false);
  };

  return (
    <View style={{ width: "100%" }}>
      {isEditable && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Label styles={{ paddingRight: 16 }} weight="medium" sz="large">
            Home Information
          </Label>
          {isEditing ? (
            <Label
              weight="medium"
              onPress={() => setIsEditing(false)}
              sz="medium"
              styles={styles.clickableText}
            >
              Cancel
            </Label>
          ) : (
            <Label
              weight="medium"
              onPress={() => setIsEditing(true)}
              sz="medium"
              styles={styles.clickableText}
            >
              Edit
            </Label>
          )}
        </View>
      )}

      {isEditing && !application?.residence_description && (
        <Label
          styles={{ paddingRight: 16, paddingBottom: 16, paddingTop: 6 }}
          sz="medium"
        >
          Your foster application is almost complete!
        </Label>
      )}

      <View>
        {isEditing ? (
          <View style={{ paddingTop: 16 }}>
            <LabeledInput
              label="Residence description"
              value={residence_description}
              onChange={setResidence_description}
              placeholder="Apartment or home, fenced yard, etc."
              width={Dimensions.get("window").width - 40}
            />
          </View>
        ) : (
          <View>
            <Label styles={styles.labelText} sz="small">
              Residence description
            </Label>
            <Label sz="medium">
              {application?.residence_description || "-"}
            </Label>
          </View>
        )}

        {isEditing ? (
          <View style={{}}>
            <LabeledSwitch
              onPress={(v) => {
                setResidence_owned(v.target.checked);
              }}
              isSelected={residence_owned}
              label="Own or rent"
              value="I own my residence"
            />
          </View>
        ) : (
          <View>
            <Label styles={styles.labelText} sz="small">
              Residence owned
            </Label>
            <Label sz="medium">
              {application?.residence_owned ? "Yes" : "No"}
            </Label>
          </View>
        )}

        {isEditing ? (
          <LabeledInput
            label="Roommates description (optional)"
            value={roommatesDescription}
            onChange={setRoommatesDescription}
            placeholder="Describe who you live with (age, relationship, etc.)"
            width={Dimensions.get("window").width - 40}
          />
        ) : (
          <View>
            <Label styles={styles.labelText} sz="small">
              Roommates description
            </Label>
            <Label sz="medium">
              {application?.roommates_description || "None"}
            </Label>
          </View>
        )}

        {isEditing ? (
          <LabeledInput
            label="Existing pets (optional)"
            value={existing_pets_description}
            onChange={setExisting_pets_description}
            placeholder="List any pets you have in your home."
            width={Dimensions.get("window").width - 40}
          />
        ) : (
          <View>
            <Label styles={styles.labelText} sz="small">
              Existing pets
            </Label>
            <Label sz="medium">
              {application?.existing_pets_description || "None"}
            </Label>
          </View>
        )}

        {error ? (
          <Label styles={styles.errorText} sz="small">
            {error}
          </Label>
        ) : null}
        {isEditing ? (
          <Button
            isDisabled={isLoading || !residence_description}
            width={Dimensions.get("window").width - 40}
            onPress={onSubmit}
          >
            {"Update"}
          </Button>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: { paddingTop: 16, paddingRight: 16 },
  errorText: {
    color: theme.colors.error,
  },
  clickableText: {
    color: theme.colors.secondary[400],
    alignSelf: "start",
    textDecorationLine: "underline",
    paddingTop: 4,
  },
  labelText: {
    paddingBottom: 6,
    paddingTop: 16,
    color: "black",
  },
});
export default HomeInformation;
