import { useState, useEffect } from 'react';
import { Animal } from '../types/types';
import { fetchFeaturedAnimals } from '../services/animalService';

const useAnimals = (): Animal[] | null => {
  const [animals, setAnimals] = useState<Animal[] | null>(null);

  useEffect(() => {
    const getAnimals = async () => {
      const res = await fetchFeaturedAnimals();
      if (res.animals) {
        setAnimals(res.animals);
      }
    };

    if ( !animals) {
      getAnimals();
    }
  }, [animals]);

  return animals;
};

export default useAnimals;