import * as React from "react";
import Svg, { Path } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const IconX = ({ width = 26, height = 26, fill = "#000000" }: Props) => (
  <Svg width={width} height={height} viewBox="0 0 89 88" fill="none">
    <Path
      d="M44.5 44.0001L26.1666 25.6667M44.5 44.0001L62.8333 62.3334M44.5 44.0001L62.8333 25.6667M44.5 44.0001L26.1666 62.3334"
      stroke={fill}
      strokeWidth="6.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default IconX;
