import React from "react";
import { Pressable, StyleSheet } from "react-native-web";
import Label from "../Label";
import { IconGoogle } from "../../assets/icons/IconGoogle";

type Props = {
  onPress: () => void;
  width?: number;
  borderWidth?: number;
};

const GoogleLoginButton = ({ onPress, width, borderWidth, ...rest }: Props) => {
  return (
    <Pressable
      style={[
        styles.button,
        {
          width: width ? width : 250,
          borderWidth: borderWidth ? borderWidth : 0,
        },
      ]}
      onPress={onPress}
    >
      <IconGoogle />
      <Label styles={[styles.text]} sz="medium" weight="medium">
        Continue with Google
      </Label>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
    paddingLeft: 10,
  },
  button: {
    flexDirection: "row",
    maxWidth: 600,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 1,
    height: 48,
    backgroundColor: "white",
    padding: 8,
    marginTop: 16,
  },
});

export default GoogleLoginButton;
