import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import WaysToHelp from "../pages/WaysToHelp";
import Organizations from "../pages/Organizations";
import NavigationHeader from "../components/NavigationHeader";
import Signup from "../pages/Signup";
import Pets from "../pages/Pets";
import UpdateAccount from "../pages/UpdateAccount";
import { auth } from "../services/firebaseService";
import { onAuthStateChanged } from "firebase/auth";
import HamburgerMenu from "../components/HamburgerMenu";
import PetDetail from "../pages/PetDetail";
import NavigationFooter from "../components/NavigationFooter";
import OrgDetail from "../pages/OrgDetail";
import FosterApplication from "../pages/FosterApplication";
import Contact from "../pages/Contact";
import BlogsList from "../pages/BlogsList";
import BlogDetail from "../pages/BlogDetail";
import SignupRescue from "../pages/SignupRescue";
import Dashboard from "../pages/Rescue/Dashboard";
import Account from "../pages/Rescue/Account";
import RescueApp from "../pages/Rescue/RescueApp";
import Animals from "../pages/Rescue/Animals";
import Fosters from "../pages/Rescue/Fosters";
import AnimalDetail from "../pages/AnimalDetail";

const App = () => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        window.localStorage.setItem("uid", uid);
        const email = user.email || "";
        window.localStorage.setItem("email", email);
        setIsAuthed(true);
      } else {
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("IS_RESCUE");
        setIsAuthed(false);
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <AppContent
        isAuthed={isAuthed}
        hamburgerOpen={hamburgerOpen}
        setHamburgerOpen={setHamburgerOpen}
      />
    </BrowserRouter>
  );
};

const AppContent: React.FC<{
  isAuthed: boolean;
  hamburgerOpen: boolean;
  setHamburgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isAuthed, hamburgerOpen, setHamburgerOpen }) => {
  const location = useLocation();
  return (
    <>
      <HamburgerMenu
        isVisible={hamburgerOpen}
        isAuthed={isAuthed}
        setHamburgerOpen={setHamburgerOpen}
      />

      {location.pathname.startsWith("/rescue") ? null : (
        <NavigationHeader
          isAuthed={isAuthed}
          hamburgerOpen={hamburgerOpen}
          setHamburgerOpen={setHamburgerOpen}
        />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<Signup />} />
        <Route path="/create-rescue-account" element={<SignupRescue />} />
        <Route path="/update-account" element={<UpdateAccount />} />
        <Route path="/pets" element={<Pets />} />
        <Route path="/ways-to-help" element={<WaysToHelp />} />
        <Route path="/organizations" element={<Organizations />} />
        <Route path="/pets/:id" element={<PetDetail />} />
        <Route path="/animals/:id" element={<AnimalDetail />} />
        <Route path="/organization-detail" element={<OrgDetail />} />
        <Route path="/application/:id" element={<FosterApplication />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/foster-tails" element={<BlogsList />} />
        <Route path="/foster-tails/:id" element={<BlogDetail />} />
        <Route path="/rescue" element={<RescueApp />}>
          <Route index element={<Dashboard />} />
          <Route path="/rescue/account" element={<Account />} />
          <Route path="/rescue/animals" element={<Animals />} />
          <Route path="/rescue/fosters" element={<Fosters />} />
        </Route>
      </Routes>

      <NavigationFooter isAuthed={isAuthed} />
    </>
  );
};

export default App;
