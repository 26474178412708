import {
    MediaFile,
  } from "../types/types";
  import axios from "axios";
 
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const API_URL = `${BASE_URL}/media`
  
  type CreateMediaFileParams = {
    url: string;
    related_id: string;
    related_type: string;
    media_type: string;
   
  };
  type UpdateMediaFileParams = {
    id: string;
    url: string;
    related_id: string;
    related_type: string;
    media_type: string;
  };

  type MediaFileResponse = {
    error?: string;
    mediaFile?: MediaFile;
  }

  type MediaFilesResponse = {
    error?: string;
    mediaFiles: MediaFile[];
  }
  
  export const createMediaFile = async (
    params: CreateMediaFileParams): Promise<MediaFileResponse> => {
    try {
      const response = await axios.post(
        API_URL,
        {
          ...params,
        }
      );
      return {mediaFile: response.data} as MediaFileResponse
    } catch (error) {
      return {error} as MediaFileResponse
    }
  };

  export const updateMediaFile= async (
    params: UpdateMediaFileParams): Promise<MediaFileResponse> => {
    try {
      const response = await axios.put(
        `${API_URL}/${params.id}`,
        {
          ...params,
        }
      );
      return {mediaFile: response.data} as MediaFileResponse
    } catch (error) {
      return {error} as MediaFileResponse
    }
  };

  export const fetchMediaForAnimal = async (id: string): Promise<MediaFilesResponse> => {
    try {
      const response = await axios.get(`${API_URL}/animals/${id}`);
      return {mediaFiles: response.data} as MediaFilesResponse
    } catch (error) {
      return {error} as MediaFilesResponse
    }
  };


  