import { View, Pressable } from "react-native-web";
import Label from "../Label";
import IconX from "../../assets/icons/IconX";
import Button from "../Button";
import { theme } from "../../config/theme";
import Modal from "react-modal";

type Props = {
  title: string;
  message: string;
  confirmBtn?: string;
  cancelBtn: string;
  isVisible: boolean;
  onConfirm?: () => void;
  onDismiss: () => void;
};
const ConfirmationModal = ({
  title,
  message,
  confirmBtn,
  cancelBtn,
  isVisible,
  onConfirm,
  onDismiss,
}: Props) => {
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "#00000065",
        },
        content: {
          alignItems: "center",
          justifySelf: "center",
          maxWidth: 800,
          color: theme.colors.secondary[100],
          borderRadius: "12px",
        },
      }}
      isOpen={isVisible}
      onRequestClose={onDismiss}
    >
      <View
        style={{
          padding: 20,
        }}
      >
        <Pressable onPress={onDismiss}>
          <IconX width={36} height={36} />
        </Pressable>

        <View
          style={{
            paddingTop: 72,
            alignItems: "center",
          }}
        >
          <Label
            styles={{ paddingTop: 22, paddingBottom: 8, textAlign: "center" }}
            sz="large"
            weight="medium"
          >
            {title}
          </Label>
          <Label
            styles={{ paddingBottom: 22, textAlign: "center" }}
            sz="medium"
          >
            {message}
          </Label>

          {confirmBtn && onConfirm ? (
            <Button onPress={onConfirm} width={200}>
              {confirmBtn}
            </Button>
          ) : null}

          <Button
            backgroundColor="white"
            textColor={theme.colors.secondary[300]}
            borderColor={theme.colors.secondary[300]}
            onPress={onDismiss}
            width={200}
          >
            {cancelBtn}
          </Button>
        </View>
      </View>
    </Modal>
  );
};
export default ConfirmationModal;
