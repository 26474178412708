import { useState, useEffect } from 'react';
import { Organization } from '../types/types';
import { auth, isAuthenticated } from '../services/firebaseService';
import { fetchOrganization, fetchOrganizationById } from '../services/apiService';

const useOrganization = (organizationId?: string): Organization | null => {
  const [organization, setOrganization] = useState<Organization | null>(null);
  const isAuthed = isAuthenticated();
  useEffect(() => {
    const getOrganization = async () => {
      if ((!auth.currentUser && !organizationId) || organization) {
        // Return if not authed or already fetched organization
        return;
      }
      if (organizationId) {
        const res = await fetchOrganizationById(organizationId);
        if (res.organization) {
          setOrganization(res.organization);
        }
      } else {
        const res = await fetchOrganization({ userId: auth.currentUser?.uid  });
        if (res.organization) {
          setOrganization(res.organization);
        }
      }
    
    };
    
    getOrganization();
    
  }, [isAuthed, organization, organizationId]);

  return organization;
};

export default useOrganization;