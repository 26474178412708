import { useEffect } from "react";
import { StyleSheet, View, Image } from "react-native-web";
import { logAnalyticsEvent } from "../../services/firebaseService";

import ContactForm from "../../components/ContactForm";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "contact_visited" });
  }, []);

  return (
    <View style={styles.contactContainer}>
      <Image
        style={styles.pawImage}
        source={require("../../assets/images/paws.png")}
      />
      <ContactForm />
    </View>
  );
};

const styles = StyleSheet.create({
  contactContainer: {
    paddingTop: 24,
    paddingBottom: 24,
    alignItems: "center",
  },
});

export default Contact;
