import { useEffect } from "react";
import { StyleSheet, View, Image } from "react-native-web";
import { logAnalyticsEvent } from "../../services/firebaseService";

import Label from "../../components/Label";
import BlogCard from "../../components/BlogCard";

const BlogsList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "blog_list_visited" });
  }, []);

  return (
    <View style={styles.container}>
      <Image
        style={styles.pawImage}
        source={require("../../assets/images/paws.png")}
      />
      <Label sz="xxxlarge" weight="bold">
        Foster Tails
      </Label>
      <Label
        styles={{ paddingBottom: 36, paddingTop: 12, textAlign: "center" }}
        sz="medium"
      >
        Learn more on what it means to become a foster and how you can help be a
        part of the mission.
      </Label>

      <View style={styles.wrapContainer}>
        <BlogCard
          id="foster-journey"
          name="A Foster Journey: How One Family Gave a Dog a Second Chance"
          imagePath={require("../../assets/images/dog-high-five.jpeg")}
        />
        <BlogCard
          id="how-does-pet-fostering-work"
          name="How Pet Fostering Works: What To Know"
          imagePath={require("../../assets/images/foster-family.jpeg")}
        />
        <BlogCard
          id="why-become-a-pet-foster"
          name="Why Pet Fostering Matters: Your Role in Changing Lives"
          imagePath={require("../../assets/images/shelter-dog.jpeg")}
        />
        <BlogCard
          id="explore-the-world-of-dog-fostering"
          name="Explore the World of Fostering Dogs"
          imagePath={require("../../assets/images/foster-dogs.jpeg")}
        />
        <BlogCard
          id="all-about-cat-fostering"
          name="All About Cat Fostering"
          imagePath={require("../../assets/images/foster-cat.jpeg")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    alignItems: "center",
  },
  wrapContainer: {
    maxWidth: 1200,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
});

export default BlogsList;
