import { View, StyleSheet } from "react-native-web";
import Label from "../Label";
import { theme } from "../../config/theme";
import GooglePlacesAutocomplete, {
  geocodeByLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Address } from "../../types/types";
import UseCurrentLocation from "../UseCurrentLocation";

type Props = {
  label: string;
  value: any;
  width: number;
  placeholder: string;
  error?: string;
  success?: string;
  isSecure?: boolean;
  showPassword?: boolean;
  disabled?: boolean;
  rightElement?: any;
  leftElement?: any;
  onChange: (place: any) => void;
  onFocus?: () => void;
  isFocused?: boolean;
};

export const LabeledAddressInput = ({
  label,
  value,
  width,
  placeholder,
  onChange,
  error,
  success,
  isSecure = false,
  showPassword = false,
  disabled,
  leftElement,
  onFocus,
  isFocused,
  ...rest
}: Props) => {
  const mapToAddress = (placeDetails: google.maps.GeocoderResult): Address => {
    const getComponent = (type: string) => {
      const component = placeDetails.address_components.find((comp) =>
        comp.types.includes(type)
      );
      return component ? component.long_name : "";
    };

    return {
      street_1: `${getComponent("street_number")} ${getComponent("route")}`,
      city: getComponent("locality"),
      state: getComponent("administrative_area_level_1"),
      postal_code: getComponent("postal_code"),
      country: getComponent("country"),
    } as Address;
  };
  const handleSelect = async (place: any) => {
    if (!place?.value?.place_id) {
      return;
    }
    const results = await geocodeByPlaceId(place.value.place_id);
    if (results.length > 0) {
      const mappedAddress = mapToAddress(results[0]);
      onChange(mappedAddress);
    }
  };

  const onLocationFound = async (lat: number, lng: number) => {
    const results = await geocodeByLatLng({ lat, lng });
    if (results.length > 0) {
      const mappedAddress = mapToAddress(results[0]);
      onChange(mappedAddress);
    }
  };
  return (
    <View style={{ zIndex: 10, width, maxWidth: 600 }} {...rest}>
      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          alignItems="center"
          space="2"
        >
          <Label styles={styles.text} sz="medium" weight="medium">
            {label}
          </Label>
          <UseCurrentLocation
            onLocationFound={() => {
              const latLng = window.localStorage.getItem("LAT_LNG");
              if (latLng?.split(",") && latLng.split(",").length > 1) {
                const lat = latLng?.split(",")[0];
                const lng = latLng?.split(",")[1];
                onLocationFound(Number(lat), Number(lng));
              }
            }}
          />
        </View>
      </View>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAPS_KEY}
        selectProps={{
          value: value?.street_1,
          placeholder: "Enter a location",
          onChange: handleSelect,
          styles: {
            container: (provided) => ({
              ...provided,
              marginBottom: 16,
              zIndex: 10,
            }),
            input: (provided) => ({
              ...provided,
            }),
            option: (provided) => ({
              ...provided,
            }),
          },
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    flex: 1,
    paddingBottom: 6,
    color: "black",
  },
  input: {
    outlineColor: theme.colors.secondary[300],
    borderRadius: 8,
    borderWidth: 1.5,
    borderColor: theme.colors.tertiary,
    marginBottom: 16,
  },
});
export default LabeledAddressInput;
