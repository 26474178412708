import { useEffect, useState } from "react";
import { View, Image, StyleSheet, Dimensions } from "react-native-web";
import { theme } from "../../config/theme";
import Label from "../../components/Label";
import { Animal } from "../../types/types";
import PetCard from "../../components/PetCard";
import { fetchPetFinderAnimals } from "../../services/petfinderService";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import FilterSearchInput from "../../components/FilterSearchInput";
import { isDesktop, isMobile } from "../../config/utils";
import IconMedical from "../../assets/icons/IconMedical";
import InfoCard from "../../components/InfoCard";
import IconHome from "../../assets/icons/IconHome";
import IconDogWalk from "../../assets/icons/IconDogWalk";
import Loading from "../../components/Loading";
import { logAnalyticsEvent } from "../../services/firebaseService";
import Signup from "../Signup";
import { Reveal } from "../../components/Reveal";
import BlogCard from "../../components/BlogCard";
import UseCurrentLocation from "../../components/UseCurrentLocation";

const Home = () => {
  const navigate = useNavigate();
  const [featuredPets, setFeaturedPets] = useState<Animal[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchFeaturedPets = async (location: string) => {
    logAnalyticsEvent({ eventName: "home_screen_fetch", data: { location } });
    setIsLoading(true);
    const res = await fetchPetFinderAnimals({ location, limit: "4" });
    if (res?.animals) {
      setFeaturedPets(
        res.animals?.sort((a) => (a.photos?.length > 0 ? 1 : -1))
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFeaturedPets(window.localStorage.getItem("LAT_LNG") || "33301");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hasLocationSet, setHasLocationSet] = useState(
    !!window.localStorage.getItem("LAT_LNG")
  );

  const onPressSearch = () => {
    logAnalyticsEvent({
      eventName: "home_screen_Search",
      data: { search },
    });
    navigate("/pets", { state: { search } });
  };

  const onPressSignup = () => {
    logAnalyticsEvent({ eventName: "home_screen_signup" });
    navigate("/create-account");
  };

  const onPressViewMore = () => {
    logAnalyticsEvent({ eventName: "home_screen_view_more" });
    navigate("/pets");
  };

  return (
    <View style={{ alignItems: "center" }}>
      <Image
        style={styles.image}
        source={require("../../assets/images/hero-hugging-dog.jpeg")}
      />
      <View style={styles.overLay} />

      <View
        style={{
          position: "absolute",
          zIndex: 10,
          top: isMobile(Dimensions.get("window").width) ? "4%" : "7%",
        }}
      >
        <Reveal width="100%">
          <View style={{ alignItems: "center", height: 420 }}>
            <Label
              styles={{
                color: "white",
                textAlign: "center",
              }}
              weight={"medium"}
              sz="xxxxlarge"
            >
              Fostering Hope
            </Label>
            <Label
              styles={{
                color: "white",
                textAlign: "center",
                paddingBottom: 36,
              }}
              weight={"medium"}
              sz="xxlarge"
            >
              One paw at a time
            </Label>
            <Button borderColor="black" onPress={onPressSignup} width={300}>
              BECOME A FOSTER
            </Button>
          </View>
        </Reveal>
      </View>

      <View style={styles.divider}></View>

      <View style={styles.bgContainer}>
        <Reveal width="100%">
          <View style={styles.petsBgContainer}>
            <Label weight={"medium"} styles={styles.header} sz="xxxlarge">
              Find a foster pet
            </Label>
            {!hasLocationSet ? (
              <UseCurrentLocation
                onLocationFound={() => {
                  setHasLocationSet(true);
                  fetchFeaturedPets(
                    window.localStorage.getItem("LAT_LNG") || ""
                  );
                }}
              />
            ) : null}

            {isLoading || !featuredPets?.length ? (
              <Loading height={200} />
            ) : (
              <>
                <View style={{ paddingTop: 16, margin: 6 }}>
                  <FilterSearchInput
                    placeholder="Try dalmatian, cat, labrador, etc."
                    search={search}
                    setSearch={setSearch}
                    onPressSearch={onPressSearch}
                  />
                </View>
                <View style={styles.petsContainer}>
                  {featuredPets
                    .sort((a, b) => (a.photos.length ? 1 : -1))
                    .map((pet, i) => {
                      const formattedName = pet.name
                        .toLowerCase()
                        .split(" ")
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(" ");
                      return (
                        <PetCard
                          key={i}
                          pet={pet}
                          name={formattedName}
                          imageUrl={pet.photos.find((p) => p.medium)?.medium}
                          breed={pet.breeds.primary}
                          age={pet.age}
                          distance={pet.distance}
                        />
                      );
                    })}
                </View>
              </>
            )}

            {featuredPets?.length ? (
              <Label
                onPress={onPressViewMore}
                weight="medium"
                styles={styles.clickableLabel}
                sz="large"
              >
                View more
              </Label>
            ) : null}
          </View>
        </Reveal>

        <View>
          <Reveal width="100%">
            <View style={styles.planningContainer}>
              <Label weight={"medium"} styles={styles.header} sz="xxxlarge">
                Planning to foster or help a rescue?
              </Label>

              <View
                style={{
                  height: isMobile(Dimensions.get("window").width)
                    ? 520
                    : undefined,

                  paddingTop: 24,
                  justifyContent: isMobile(Dimensions.get("window").width)
                    ? "space-between"
                    : "space-around",
                  flexDirection: isMobile(Dimensions.get("window").width)
                    ? "column"
                    : "row",
                }}
              >
                <InfoCard
                  icon={<IconMedical />}
                  title="Transportation"
                  message="Help drive foster pets to and from foster homes, veterinary appointments, adoption events, and other locations as needed."
                />

                <InfoCard
                  backgroundColor={theme.colors.secondary[400]}
                  textColor={"white"}
                  icon={<IconHome />}
                  title="Pet Fostering"
                  message="Give a temporary home for a pet to help get them out of the shelters until adopted, all expenses paid."
                />

                <InfoCard
                  icon={<IconDogWalk />}
                  title="Volunteer Opportunities"
                  message="Get involved and receive notifications whenever our shelters need volunteers on demand."
                />
              </View>
            </View>
          </Reveal>
        </View>

        <View style={styles.signUpContainer}>
          {isMobile(Dimensions.get("window").width) ? (
            <Button backgroundColor="black" onPress={onPressSignup} width={300}>
              GET INVOLVED
            </Button>
          ) : null}
        </View>
      </View>

      {isDesktop(Dimensions.get("window").width) && (
        <Label weight={"medium"} styles={styles.header} sz="xxxlarge">
          We are on a mission
        </Label>
      )}
      <View>
        <Reveal width="100%">
          <View
            style={[
              styles.missionContainer,
              {
                flexDirection: isMobile(Dimensions.get("window").width)
                  ? "column"
                  : "row",
              },
            ]}
          >
            {isMobile(Dimensions.get("window").width) && (
              <Label weight={"medium"} styles={styles.header} sz="xxxlarge">
                Join our mission
              </Label>
            )}

            <Image
              source={require("../../assets/images/happy-dog.jpeg")}
              style={[
                styles.missionImage,
                {
                  height: isMobile(Dimensions.get("window").width)
                    ? undefined
                    : 420,
                  width: isMobile(Dimensions.get("window").width)
                    ? undefined
                    : 420,
                  marginRight: isMobile(Dimensions.get("window").width)
                    ? undefined
                    : 60,
                  borderRadius: isMobile(Dimensions.get("window").width)
                    ? 16
                    : 210,
                },
              ]}
            />
            <Label
              sz={isMobile(Dimensions.get("window").width) ? "medium" : "large"}
              styles={styles.body}
            >
              At PetFosterz, we are on a mission to empower animal rescues by
              connecting them to the largest online community of pet fosters and
              volunteers worldwide.{"\n\n"}Together we can connect loving homes
              and people to animals when they need it most, for rescues of all
              scale. We believe that by uniting people with a shared love for
              animals, we can make a pawsitive impact on the lives of countless
              pets, reduce the overcrowding in shelters, and foster a community
              where every pet has the opportunity to thrive and flourish.
            </Label>
          </View>
        </Reveal>
      </View>

      <View>
        <Reveal width="100%">
          <View style={styles.resourcesContainer}>
            <Label weight={"medium"} sz="xxxlarge" styles={styles.header}>
              Foster resources
            </Label>
            <View style={styles.petsContainer}>
              <BlogCard
                id="why-become-a-pet-foster"
                name="Why Pet Fostering Matters: Your Role in Changing Lives"
                imagePath={require("../../assets/images/shelter-dog.jpeg")}
              />
              <BlogCard
                id="how-does-pet-fostering-work"
                name="How Pet Fostering Works: What To Know"
                imagePath={require("../../assets/images/foster-family.jpeg")}
              />
              <BlogCard
                id="foster-journey"
                name="A Foster Journey: How One Family Gave a Dog a Second Chance"
                imagePath={require("../../assets/images/dog-high-five.jpeg")}
              />

              {!isMobile(Dimensions.get("window").width) && (
                <BlogCard
                  id="all-about-cat-fostering"
                  name="All About Cat Fostering"
                  imagePath={require("../../assets/images/foster-cat.jpeg")}
                />
              )}
            </View>

            <Label
              onPress={() => navigate("/foster-tails")}
              weight="medium"
              styles={styles.clickableLabel}
              sz="large"
            >
              View more
            </Label>
          </View>
        </Reveal>
      </View>

      <Reveal width="100%">
        <View style={styles.joinNowContainer}>
          <Signup />
        </View>
      </Reveal>
    </View>
  );
};

const styles = StyleSheet.create({
  signUpContainer: {
    maxWidth: 800,
    marginTop: 8,
    marginBottom: 28,
    borderRadius: 12,
    alignItems: "center",
  },
  joinNowContainer: {
    paddingTop: 24,
    paddingBottom: 0,
  },
  image: {
    width: "100%",
    height: isMobile(Dimensions.get("window").width) ? 475 : 600,
    resizeMode: "cover",
  },
  missionImage: {
    width: "100%",
    height: 220,
    resizeMode: "cover",
    marginBottom: 12,
  },
  pawsImage: {
    right: 10,
    top: 0,
    width: 400,
    height: 220,
    position: "absolute",
    zIndex: 20,
  },
  overLay: {
    position: "absolute",
    zIndex: 5,
    backgroundColor: "black",
    opacity: 0.45,
    width: "100%",
    height: isMobile(Dimensions.get("window").width) ? 475 : 600,
  },
  header: {
    textAlign: "center",
    width: "100%",
    maxWidth: 1200,
    paddingTop: 16,
    paddingBottom: 16,
  },
  body: {
    color: theme.colors.text.body,
    fontSize: 18,
    maxWidth: 490,
  },
  divider: {
    backgroundColor: theme.colors.secondary[200],
    height: 16,
    width: "100%",
  },
  bgContainer: {
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.colors.primary,
  },
  petsBgContainer: {
    padding: 16,
    alignItems: "center",
  },
  petsContainer: {
    alignSelf: "center",
    maxWidth: 1200,
    paddingTop: 8,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  planningContainer: {
    padding: 16,
    maxWidth: 1200,
  },
  missionContainer: {
    maxWidth: 1200,
    backgroundColor: "white",
    padding: 16,
    alignItems: "center",
  },
  resourcesContainer: {
    maxWidth: 1200,
    backgroundColor: "white",
    padding: 16,
    alignItems: "center",
    marginBottom: 16,
  },
  clickableLabel: {
    textDecorationLine: "underline",
    paddingTop: 24,
    alignSelf: "center",
    color: theme.colors.secondary[400],
  },
});
export default Home;
