import { useEffect } from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native-web";
import { logAnalyticsEvent } from "../../../services/firebaseService";

import Label from "../../../components/Label";
import { useParams } from "react-router-dom";
import { theme } from "../../../config/theme";
import { isMobile } from "../../../config/utils";

const ExploreDogFostering = () => {
  const { id } = useParams();

  useEffect(() => {
    logAnalyticsEvent({
      eventName: "blog_detail_visited",
      data: { blogId: id },
    });
  }, [id]);

  return (
    <View>
      <Image
        style={styles.image}
        source={require("../../../assets/images/foster-dogs.jpeg")}
      />
      <Label sz="xxxlarge">Explore the World of Dog Fostering</Label>
      <Label weight="bold" styles={styles.header} sz="large">
        How You Can Make a Difference
      </Label>
      <Label styles={styles.body} sz="medium">
        Are you a pet lover looking for a meaningful way to give back to your
        community? Fostering dogs and puppies might just be the answer you've
        been searching for. At PetFosterz.com, we believe in the power of foster
        care to transform the lives of homeless pets, one paw at a time.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Why Foster?
      </Label>
      <Label styles={styles.body} sz="medium">
        Fostering a dog or puppy provides them with a safe and loving
        environment while they await their furever home. Many of these animals
        have been abandoned, neglected, or surrendered, and fostering gives them
        a second chance at life along with new hope.{"\n\n"}By opening your home
        to a furry friend in need, you're not only saving a life but also
        freeing up space in overcrowded shelters. It is estimated that anywhere
        between 3-4 million dogs are being placed in shelters each year.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        The Rewards of Fostering
      </Label>
      <Label styles={styles.body} sz="medium">
        The rewards of pet fostering are immeasurable. From the joy of watching
        a scared and timid dog blossom into a confident and happy companion to
        the satisfaction of knowing you've played a role in their journey to a
        furever home, fostering is a deeply fulfilling experience. Plus, there's
        nothing quite like the unconditional love and gratitude of a grateful
        pup who knows they've been saved.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        How to Get Started
      </Label>
      <Label styles={styles.body} sz="medium">
        Getting started as a foster pet parent is easier than you might think.
        Simply visit PetFosterz.com and fill out our foster application. Once
        completed, you can apply for different rescue animals or shelters
        directly. We will also do our best to match you with a dog or puppy that
        fits your lifestyle and preferences.{"\n\n"}The rescue provides all the
        support and resources you need, from food and supplies to veterinary
        care and training assistance.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Spread the Word
      </Label>
      <Label styles={styles.body} sz="medium">
        You can also help support our mission by spreading the word about pet
        fostering. Share your fostering journey on social media, volunteer at
        local adoption events, or simply talk to your friends and family about
        the importance of fostering. Together, we can make a difference in the
        lives of dogs and puppies in need.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Join Us Today
      </Label>
      <Label styles={styles.body} sz="medium">
        Ready to make a difference in the lives of the many pets in need? Visit
        PetFosterz.com today to learn more about fostering opportunities in your
        area. Together, we can give every dog or puppy the chance they deserve
        for a happy and loving future.
      </Label>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    maxWidth: 1200,
    width: "100%",
    height: isMobile(Dimensions.get("window").width) ? 300 : 500,
    resizeMode: "cever",
    borderRadius: 8,
  },
  header: {
    paddingBottom: 16,
    paddingTop: 28,
  },
  subHeader: {
    paddingBottom: 16,
  },
  body: {
    maxWidth: 1200,
    paddingBottom: 16,
    color: theme.colors.text.body,
  },
});

export default ExploreDogFostering;
