import * as React from "react";
import Svg, { Path } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const IconArrowCircle = ({
  width = 28,
  height = 28,
  fill = "#2F6B61",
}: Props) => (
  <Svg width={width} height={height} viewBox="0 0 22 22" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3.4375C6.82335 3.4375 3.4375 6.82335 3.4375 11C3.4375 15.1767 6.82335 18.5625 11 18.5625C15.1767 18.5625 18.5625 15.1767 18.5625 11V9.6255C18.5625 9.24581 18.8703 8.9375 19.25 8.9375C19.6297 8.9375 19.9375 9.2453 19.9375 9.625V11C19.9375 15.936 15.936 19.9375 11 19.9375C6.06395 19.9375 2.0625 15.936 2.0625 11C2.0625 6.06395 6.06395 2.0625 11 2.0625L12.3743 2.0625L12.375 2.75L12.375 3.4375L11 3.4375ZM13.0625 2.75C13.0625 3.1297 12.7547 3.4375 12.375 3.4375L12.375 2.75L12.3743 2.0625C12.754 2.0625 13.0625 2.37031 13.0625 2.75Z"
      fill={fill}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.85714 9.71429C8.38376 9.71429 8 9.33053 8 8.85714C8 8.38376 8.38376 8 8.85714 8H13.1429C13.6162 8 14 8.38376 14 8.85714V13.1429C14 13.6162 13.6162 14 13.1429 14C12.6695 14 12.2857 13.6162 12.2857 13.1429V10.9265L9.46323 13.7489C9.1285 14.0837 8.58579 14.0837 8.25105 13.7489C7.91632 13.4142 7.91632 12.8715 8.25105 12.5368L11.0735 9.71429H8.85714Z"
      fill="#2F6B61"
    />
  </Svg>
);

export default IconArrowCircle;
