import {
  StyleSheet,
  View,
  Pressable,
  Image,
  Dimensions,
} from "react-native-web";
import Label from "../../components/Label";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { theme } from "../../config/theme";
import { isMobile } from "../../config/utils";
import { Animal } from "../../types/types";
import Button from "../../components/Button";
import {
  auth,
  isAuthenticated,
  logAnalyticsEvent,
} from "../../services/firebaseService";
import {
  createApplication,
  fetchAddress,
  fetchDistanceInMiles,
  submitApplication,
} from "../../services/apiService";

import SuccessModal from "../../components/SuccessModal";
import { FaArrowLeft } from "react-icons/fa";
import ConfirmationModal from "../../components/ConfirmationModal";
import useAddress from "../../hooks/useAddress";
import useOrganization from "../../hooks/useOrganization";

const AnimalDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const animal: Animal = state.animal;
  const organization = useOrganization(animal.organization_id);
  const address = useAddress(organization?.auth_user_id);

  const [isLoading, setIsLoading] = useState(false);
  const [didSendApplication, setDidSendApplication] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formattedName = animal?.name
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

  const onSubmitApplication = async () => {
    if (isAuthenticated()) {
      setIsLoading(true);
      logAnalyticsEvent({
        eventName: "submit_application",
        data: { screen: "pet" },
      });
      const userId = auth.currentUser?.uid || "";
      const fosterAddress = await fetchAddress({ userId });
      // Validate user location requirements
      if (
        (fosterAddress.address?.city ||
          window.localStorage.getItem("LAT_LNG")) &&
        address
      ) {
        const userAddress = fosterAddress.address
          ? `${fosterAddress.address.city}, ${fosterAddress.address?.state}`
          : window.localStorage.getItem("LAT_LNG") || "";

        const distanceRes = await fetchDistanceInMiles(
          userAddress,
          `${address.city}, ${address.state}` // animal address
        );
        if (distanceRes > 150) {
          //show alert
          setIsConfirmationVisible(true);
          return;
        }
      } else {
        window.alert(
          "Location not set. Please update your application address or enable location permissions."
        );
        return;
      }

      const createAppRes = await createApplication({
        auth_user_id: userId,
        organization_id: organization?.id,
      });
      if (createAppRes.success) {
        const submitRes = await submitApplication({
          organization_email: organization?.email || "",
          auth_user_id: userId,
          organization_name: organization?.name || "",
          pet_name: animal?.name,
          organization_id: organization?.id,
        });
        if (submitRes.success) {
          setDidSendApplication(true);
        }
      }

      setIsLoading(false);
    } else {
      logAnalyticsEvent({
        eventName: "submit_application_attempt",
        data: { screen: "pet" },
      });
      navigate("/create-account");
    }
  };

  return (
    <View
      style={{
        backgroundColor: theme.colors.secondary[100],
        padding: 16,
        width: "100%",
      }}
    >
      <Pressable style={{ alignSelf: "start" }} onPress={() => navigate(-1)}>
        <FaArrowLeft size={28} />
      </Pressable>

      <View style={{ paddingTop: 16 }}>
        <Image
          style={styles({ isSelected: false }).petImage}
          source={
            animal.photoUrl
              ? { uri: animal.photoUrl }
              : require("../../assets/images/avatar.png")
          }
        />

        <View style={styles({}).sectionContainer}>
          <Label styles={{}} sz="xlarge">
            {formattedName}
          </Label>
          <Label
            numberOfLines={1}
            styles={{
              paddingRight: 16,
              paddingTop: 8,
              color: theme.colors.secondary[400],
            }}
            weight="medium"
            sz="medium"
          >
            {animal.breed}
          </Label>
          <Label numberOfLines={1} styles={{ paddingTop: 8 }} sz="medium">
            {address?.city}, {address?.state}
          </Label>

          <View style={styles({}).divider} />
          <Label styles={{ paddingBottom: 8 }} sz="medium">
            {animal.age} • {animal.gender} • {animal.size}
          </Label>

          {animal.description ? (
            <Label styles={{}} sz="medium">
              {animal.description} Contact {organization?.name || "us"} for more
              information on {animal.name}.
            </Label>
          ) : (
            <Label styles={{}} sz="medium">
              Contact {organization?.name || "us"} for more information on{" "}
              {animal.name}.
            </Label>
          )}
        </View>

        {!window.localStorage.getItem("IS_RESCUE") && (
          <View
            style={[
              styles({}).sectionContainer,
              {
                backgroundColor: theme.colors.secondary[400],
                alignItems: "center",
                paddingBottom: 24,
              },
            ]}
          >
            <Label
              styles={{ textAlign: "center", color: "white", padding: 16 }}
              sz="xlarge"
            >
              Considering to help {formattedName}?
            </Label>
            <Button
              backgroundColor={"white"}
              textColor={theme.colors.secondary[400]}
              isDisabled={isLoading}
              onPress={onSubmitApplication}
              width={330}
            >
              APPLY TO FOSTER
            </Button>
          </View>
        )}

        {!!organization && !window.localStorage.getItem("IS_RESCUE") ? (
          <View
            style={[
              styles({}).sectionContainer,
              {
                alignItems: "center",
                paddingBottom: 24,
                borderWidth: 1,
                borderColor: theme.colors.secondary[400],
              },
            ]}
          >
            <Label sz="xlarge" styles={{ textAlign: "center", paddingTop: 8 }}>
              {organization?.name}
            </Label>
            <Label sz="medium" styles={{ textAlign: "center", paddingTop: 8 }}>
              {address?.city}, {address?.state}
            </Label>
            <Button
              borderColor="white"
              isDisabled={isLoading}
              onPress={onSubmitApplication}
              width={330}
            >
              CONTACT RESCUE
            </Button>
          </View>
        ) : null}
      </View>

      <SuccessModal
        isVisible={didSendApplication}
        onDismiss={() => setDidSendApplication(false)}
      />
      <ConfirmationModal
        isVisible={isConfirmationVisible}
        title="You look a bit far 🤔"
        message="You appear to be more than 150 miles away from this rescue. If this is incorrect, please enable location permissions or update your address on your application."
        cancelBtn="Dismiss"
        onDismiss={() => {
          setIsConfirmationVisible(false);
        }}
      />
    </View>
  );
};

const styles = (props: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: 10,
    },
    divider: {
      height: 1,
      width: "100%",
      borderRadius: 2,
      backgroundColor: theme.colors.tertiary,
      marginBottom: 16,
      marginTop: 16,
    },
    sectionContainer: {
      marginTop: 16,
      marginBottom: 16,
      backgroundColor: "white",
      padding: 12,
      borderRadius: 8,
    },
    icon: {
      fontSize: 24,
      lineHeight: 24,
    },
    pressable: {
      paddingLeft: 16,
      paddingTop: 12,
      paddingBottom: 12,
    },
    petImage: {
      borderRadius: 12,
      width: isMobile(Dimensions.get("window").width) ? "100%" : 400,
      height: 365,
      resizeMode: "cover",
    },
    petImageSmall: {
      borderWidth: props.isSelected ? 3 : 0,
      borderColor: theme.colors.secondary[400],
      borderRadius: 12,
      marginRight: 12,
      width: 130,
      height: 100,
      resizeMode: "cover",
    },
    readMoreLink: {
      color: theme.colors.text.link,
    },
    gridContainer: {
      paddingTop: 12,
      width: isMobile(Dimensions.get("window").width) ? "100%" : "60%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
  });

export default AnimalDetail;
