import { getQueryString } from "../config/utils";
import { Animal, PetFinderOrganization } from "../types/types";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type FetchAnimalsResponse = {
  animals: Animal[];
};

type FetchAnimalsParams = {
  location?: string;
  search?: string;
  type?: string;
  page?: string;
  limit?: string;
  organization?: string;
};

type FetchOrganizationParams = {
  id: string;
};

type FetchOrganizationResponse = {
  organization: PetFinderOrganization;
  error?: string;
};

type FetchOrganizationsResponse = {
  organizations: PetFinderOrganization[];
};

type FetchOrganizationsParams = {
  location: string;
  query?: string;
  page?: string;
  limit?: string;
};

export const fetchPetFinderAnimals = async (
  fetchParams: FetchAnimalsParams
) => {
  try {
    const params = getQueryString(fetchParams);

    const response = await fetch(`${BASE_URL}/petfinder/animals?${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      // Api key limit reached default to rescue groups
      const rgResponse = await fetch(
        `${BASE_URL}/rescuegroups/animals?${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!rgResponse.ok) {
        return { animals: [] } as FetchAnimalsResponse;
      } else {
        const data = await rgResponse.json();
        const animals: Animal[] = data;
        return { animals } as FetchAnimalsResponse;
      }
    } else {
      const data = await response.json();

      const animals: Animal[] = data.animals;
      return { animals } as FetchAnimalsResponse;
    }
  } catch (error) {
    return { animals: [] } as FetchAnimalsResponse;
  }
};

export const fetchPetfinderOrganization = async ({
  id,
}: FetchOrganizationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/petfinder/organizations/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    const organizationData = data.organization;
    return {
      organization: organizationData,
    } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const fetchPetfinderOrganizations = async (
  fetchParams: FetchOrganizationsParams
) => {
  try {
    const params = getQueryString(fetchParams);
    const response = await fetch(
      `${BASE_URL}/petfinder/organizations?${params}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    const organizations: PetFinderOrganization[] = data.organizations;
    return { organizations } as FetchOrganizationsResponse;
  } catch (error) {
    console.error("Error:", error);
  }
};
