import { useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native-web";
import { logAnalyticsEvent } from "../../services/firebaseService";

import { useNavigate, useParams } from "react-router-dom";
import ExploreDogFostering from "../Blogs/ExploreDogFostering";
import HowDoesFosteringWork from "../Blogs/HowDoesFosteringWork";
import AllAboutCatFostering from "../Blogs/AllAboutCatFostering";
import Button from "../../components/Button";
import WhyBecomeAPetFoster from "../Blogs/WhyBecomeAPetFoster";
import { FaArrowLeft } from "react-icons/fa";
import FosterJourney from "../Blogs/FosterJourney";

const BlogDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({
      eventName: "blog_detail_visited",
      data: { blogId: id },
    });
  }, [id]);

  return (
    <View style={styles.container}>
      <Pressable
        style={{ alignSelf: "start", paddingBottom: 16 }}
        onPress={() => navigate(-1)}
      >
        <FaArrowLeft size={28} />
      </Pressable>
      {id === "how-does-pet-fostering-work" ? (
        <HowDoesFosteringWork />
      ) : id === "explore-the-world-of-dog-fostering" ? (
        <ExploreDogFostering />
      ) : id === "all-about-cat-fostering" ? (
        <AllAboutCatFostering />
      ) : id === "why-become-a-pet-foster" ? (
        <WhyBecomeAPetFoster />
      ) : id === "foster-journey" ? (
        <FosterJourney />
      ) : null}

      <View style={{ alignSelf: "center", marginTop: 16 }}>
        <Button onPress={() => navigate("/create-account")} width={300}>
          Become a Foster
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    alignItems: "center",
  },
});

export default BlogDetail;
