import { Pressable, StyleSheet, View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import Label from "../Label";
import { theme } from "../../config/theme";
import { useEffect, useState } from "react";
import { fetchFoster } from "../../services/apiService";
import { Foster } from "../../types/types";
import IconArrowCircle from "../../assets/icons/IconArrowCircle";
import { timeAgo } from "../../config/formatter";

type Props = {
  createdAt: string;
  foster_auth_id: string;
  organization_petfinder_id: string;
};
const ApplicationCard = ({
  createdAt,
  foster_auth_id,
  organization_petfinder_id,
  ...rest
}: Props) => {
  const navigate = useNavigate();

  const navigateToApplication = () => {
    navigate(
      `/application/${foster_auth_id}?organization=${organization_petfinder_id}`
    );
  };

  const [foster, setFoster] = useState<Foster | undefined>(undefined);
  useEffect(() => {
    const getFoster = async () => {
      const res = await fetchFoster({ userId: foster_auth_id });
      if (res.application) {
        setFoster(res.application);
      }
    };
    getFoster();
  }, [foster_auth_id]);

  return (
    <Pressable style={styles.container} onPress={navigateToApplication}>
      <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
        <View style={styles.avatar}>
          <Label styles={{ color: "white" }} sz="small" weight="medium">
            {foster?.first_name.charAt(0)}
            {foster?.last_name.charAt(0)}
          </Label>
        </View>
        <View style={{ flex: 1 }}>
          <Label
            weight="medium"
            styles={{
              color: "black",
            }}
            numberOfLines={2}
            sz="small"
          >
            {foster?.first_name} {foster?.last_name}{" "}
            <Label weight="light" sz="small">
              applied to foster
            </Label>
          </Label>
          <Label styles={{ paddingVertical: 4 }} weight="light" sz="xsmall">
            {timeAgo(createdAt)}
          </Label>
        </View>
      </View>

      <IconArrowCircle />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    marginVertical: 8,
    padding: 12,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.colors.border,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.colors.secondary[200],
    borderRadius: 18,
    width: 36,
    height: 36,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ApplicationCard;
