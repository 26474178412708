import * as React from "react";
import Svg, { Defs, G, Path, Rect } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const IconDogWalk = ({ width = 63, height = 64, fill = "#2F6B61" }: Props) => (
  <Svg width={width} height={height} viewBox="0 0 63 64" fill="none">
    <G clipPath="url(#clip0_257_43)">
      <Path
        d="M20.3584 1.83009C19.7361 0.585285 18.2224 0.0807213 16.9776 0.703094C15.7327 1.32553 15.2282 2.83922 15.8506 4.08403L27.536 27.4549C27.874 28.1308 28.3436 28.7323 28.9174 29.2242C33.1022 32.8111 37.4323 36.5226 41.6644 40.1501C42.721 41.0558 44.3119 40.9335 45.2176 39.8768C45.8304 39.1618 45.9726 38.2024 45.6733 37.3761C46.4637 37.1156 47.3024 36.9768 48.1632 36.9768C53.6564 36.9768 58.1092 32.5238 58.1092 27.0308V26.0361C58.1092 25.0688 57.3248 24.2845 56.3578 24.2845H53.2683C51.217 24.2845 50.2443 22.6698 49.9098 20.8936C49.4039 18.2117 47.0608 16.2694 44.3315 16.2694C44.1748 16.2694 44.0477 16.1423 44.0477 15.9856V12.3966C44.0477 10.6826 42.342 10.6615 41.1972 11.1813C35.1789 13.9139 32.7915 19.3453 32.2567 25.5533C32.0209 25.3142 31.8244 25.0385 31.6752 24.7364L20.3584 1.83009Z"
        fill={fill}
      />
      <Path
        d="M27.2393 31.1056L40.6697 42.2031L38.2009 47.7658C37.4867 49.3751 37.1177 51.1163 37.1177 52.877V60.2865C37.1177 62.026 35.7074 63.4365 33.9677 63.4365H31.391C29.6513 63.4365 28.241 62.026 28.241 60.2865V52.7266C28.241 50.9869 26.8307 49.5766 25.0911 49.5766H16.8444C15.1048 49.5766 13.6944 50.9869 13.6944 52.7266V60.2865C13.6944 62.026 12.2841 63.4365 10.5445 63.4365H7.70949C5.96982 63.4365 4.55951 62.026 4.55951 60.2865V54.8213C4.55951 53.6408 4.39351 52.4659 4.06648 51.3313L0.85936 40.2068C-0.014507 37.1755 0.0223481 33.9541 0.965388 30.9436L2.46453 26.158C3.14876 23.9736 5.17256 22.4868 7.46158 22.4868H7.62967C9.87396 22.4868 11.4893 24.6421 10.8595 26.7962C10.2297 28.9503 11.845 31.1056 14.0893 31.1056H27.2393Z"
        fill={fill}
      />
      <Path
        d="M39.9629 50.3367C40.2994 50.5415 40.7842 50.7273 40.7842 51.1906V53.3565C40.7842 55.096 42.1945 56.5065 43.9341 56.5065H47.0841C48.8238 56.5065 50.2341 55.096 50.2341 53.3565V48.7106C50.2341 47.675 49.725 46.7055 48.8725 46.1175L43.3874 42.3348C43.3874 42.3348 41.4521 46.6521 40.5042 48.788C40.2814 49.29 40.1006 49.808 39.9629 50.3367Z"
        fill={fill}
      />
      <Path
        d="M16.3368 63.4366H15.2598C15.8629 62.5357 16.2145 61.4521 16.2145 60.2866V52.7267C16.2145 52.3788 16.4966 52.0967 16.8445 52.0967H20.7505L19.4519 60.7541C19.2206 62.2957 17.896 63.4366 16.3368 63.4366Z"
        fill={fill}
      />
    </G>
    <Defs>
      <clipPath id="clip0_257_43">
        <Rect
          width="63"
          height="63"
          fill="white"
          transform="translate(0 0.436523)"
        />
      </clipPath>
    </Defs>
  </Svg>
);

export default IconDogWalk;
