import { StyleSheet, View, Image } from "react-native-web";

type Props = {
  height?: number;
};

const Loading = ({ height }: Props) => {
  return (
    <View style={[styles.container, { height }]}>
      <Image
        style={styles.image}
        source={require("../../assets/images/loading.gif")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 80,
    height: 80,
  },
});

export default Loading;
