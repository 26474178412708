import { StyleSheet, View, Pressable, Modal } from "react-native-web";
import Label from "../Label";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../services/firebaseService";
import IconX from "../../assets/icons/IconX";
import { theme } from "../../config/theme";
import IconLogo from "../../assets/icons/IconLogo";
import { useEffect, useState } from "react";
import { isRescueAccount } from "../../config/utils";
import { FaBook, FaCat, FaDog, FaHome } from "react-icons/fa";

type Props = {
  isVisible: boolean;
  isAuthed: boolean;
  setHamburgerOpen: (v: boolean) => void;
};
const HamburgerMenu = ({ isVisible, isAuthed, setHamburgerOpen }: Props) => {
  const navigate = useNavigate();

  const [isRescue, setIsRescue] = useState(false);

  useEffect(() => {
    const checkForRescue = async () => {
      const rescue = await isRescueAccount();
      setIsRescue(rescue);
    };
    checkForRescue();
  }, []);

  const goToLogin = () => {
    onPressRoute("/login");
  };
  const goToLogout = async () => {
    await signOut();
    onPressRoute("/");
  };

  const onPressRoute = (route: string, data?: any) => {
    setHamburgerOpen(false);
    navigate(route, data);
  };

  return (
    <Modal visible={isVisible}>
      <View
        style={{
          height: "100%",
          backgroundColor: theme.colors.primary,
          padding: 16,
        }}
      >
        <View style={styles.row}>
          <Pressable
            style={[styles.pressable, { alignItems: "center" }]}
            onPress={() => onPressRoute("/")}
          >
            <IconLogo fill={theme.colors.secondary[400]} />
          </Pressable>

          <Pressable
            style={styles.pressable}
            onPress={() => setHamburgerOpen(false)}
          >
            <IconX width={42} height={42} />
          </Pressable>
        </View>

        <View>
          <Pressable
            style={styles.pressable}
            onPress={() => onPressRoute("/pets", { state: { petType: "Dog" } })}
          >
            <FaDog size={22} color={theme.colors.secondary[400]} />
            <Label styles={styles.text} weight="medium" sz="large">
              FOSTER A DOG
            </Label>
          </Pressable>

          <Pressable
            style={styles.pressable}
            onPress={() => onPressRoute("/pets", { state: { petType: "Cat" } })}
          >
            <FaCat size={20} color={theme.colors.secondary[400]} />
            <Label styles={styles.text} weight="medium" sz="large">
              FOSTER A CAT
            </Label>
          </Pressable>

          <Pressable
            style={styles.pressable}
            onPress={() => onPressRoute("/organizations")}
          >
            <FaHome size={22} color={theme.colors.secondary[400]} />
            <Label styles={styles.text} weight="medium" sz="large">
              FIND RESCUES
            </Label>
          </Pressable>

          <Pressable
            style={styles.pressable}
            onPress={() => onPressRoute("/foster-tails")}
          >
            <FaBook size={20} color={theme.colors.secondary[400]} />
            <Label styles={styles.text} weight="medium" sz="large">
              FOSTER RESOURCES
            </Label>
          </Pressable>

          <br></br>

          <Pressable
            style={styles.pressable}
            onPress={() =>
              onPressRoute(
                isRescue && isAuthed
                  ? "/rescue"
                  : isAuthed
                  ? "/update-account"
                  : "/create-account"
              )
            }
          >
            <Label styles={styles.authText} weight="bold" sz="large">
              {isRescue && isAuthed
                ? "DASHBOARD"
                : isAuthed
                ? "MY APPLICATION"
                : "BECOME A FOSTER"}
            </Label>
          </Pressable>

          <Pressable
            style={styles.pressable}
            onPress={() => (isAuthed ? goToLogout() : goToLogin())}
          >
            <Label styles={styles.authText} weight="bold" sz="large">
              {isAuthed ? "LOG OUT" : "LOGIN"}
            </Label>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
  },
  icon: {
    fontSize: 24,
    lineHeight: 24,
  },
  text: {
    paddingStart: 8,
    color: theme.colors.secondary[400],
  },
  authText: {
    color: theme.colors.secondary[400],
  },
  pressable: {
    alignItems: "center",
    flexDirection: "row",
    padding: 16,
  },
  logo: {
    width: 100,
    height: 75,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default HamburgerMenu;
