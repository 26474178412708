import React from "react";
import { Pressable, StyleSheet, Switch, View } from "react-native-web";
import { theme } from "../../config/theme";
import Label from "../Label";

type Props = {
  onPress: (v: any) => void;
  isSelected: boolean;
  label: string;
  value: string;
};

const LabeledSwitch = ({ onPress, isSelected, label, value }: Props) => {
  return (
    <View>
      <Label sz="small" styles={styles.text}>
        {label}
      </Label>
      <Pressable style={styles.container} onPress={onPress}>
        <Switch value={isSelected} />
        <Label styles={styles.valueText} sz="medium">
          {value}
        </Label>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    paddingBottom: 12,
    color: theme.colors.text.subtle,
  },
  valueText: {
    paddingLeft: 8,
  },
  container: {
    paddingBottom: 16,
    maxWidth: 800,
    alignItems: "center",
    flexDirection: "row",
  },
});

export default LabeledSwitch;
