import {
    Animal,
  } from "../types/types";
  import axios from "axios";
 
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const API_URL = `${BASE_URL}/animals`
  
  type CreateAnimalParams = {
    name: string;
    type: string;
    organization_id: string;
    description?: string;
    breed?: string;
    age?: string;
    gender?: string;
    size?: string;
    tags?: string[];
    status?: string;
  };
  type UpdateAnimalParams = {
    id: string;
    name: string;
    organization_id: string;
    description: string;
    breed: string;
    age: string;
    gender: string;
    size: string;
    tags: string[];
    status: string;
  };

  type AnimalResponse = {
    error?: string;
    animal?: Animal;
  }

  type AnimalsResponse = {
    error?: string;
    animals?: Animal[];
  }
  
  export const createAnimal = async (
    params: CreateAnimalParams): Promise<AnimalResponse> => {
    try {
      const response = await axios.post(
        API_URL,
        {
          ...params,
        }
      );
      return {animal: response.data} as AnimalResponse
    } catch (error) {
      return {error} as AnimalResponse
    }
  };

  export const updateAnimal = async (
    params: UpdateAnimalParams): Promise<AnimalResponse> => {
    try {
      const response = await axios.put(
        `${API_URL}/${params.id}`,
        {
          ...params,
        }
      );
      return {animal: response.data} as AnimalResponse
    } catch (error) {
      return {error} as AnimalResponse
    }
  };

  export const fetchAnimalsForOrganization = async (organization_id: string): Promise<AnimalsResponse> => {
    try {
      const response = await axios.get(`${API_URL}/${organization_id}`);
      return {animals: response.data} as AnimalsResponse
    } catch (error) {
      return {error} as AnimalsResponse
    }
  };

  export const fetchFeaturedAnimals = async (): Promise<AnimalsResponse> => {
    try {
      const response = await axios.get(API_URL);
      return {animals: response.data} as AnimalsResponse
    } catch (error) {
      return {error} as AnimalsResponse
    }
  };

  export const fetchAnimalById = async (id: string): Promise<AnimalsResponse> => {
    try {
      const response = await axios.get(`${API_URL}/${id}`);
      if (response.status === 500) {
        return {error: 'Failed with 500'} as AnimalResponse
      }
      return {animal: response.data} as AnimalResponse
    } catch (error) {
      return {error} as AnimalResponse
    }
  };
  