import { Pressable, StyleSheet, View } from "react-native-web";
import Label from "../Label";
import { theme } from "../../config/theme";
import { useEffect, useState } from "react";
import {
  createFosterRequest,
  fetchFosterRequests,
  submitFosterContactRequest,
} from "../../services/apiService";
import { Foster } from "../../types/types";

type Props = {
  foster: Foster;
  organization_name: string;
  organization_petfinder_id: string;
  organization_id: string;
};
const FosterCard = ({
  foster,
  organization_petfinder_id,
  organization_name,
  organization_id,
  ...rest
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const address = foster.address;
  const [didContact, setDidContact] = useState(false);

  useEffect(() => {
    const checkRequested = async () => {
      const res = await fetchFosterRequests({
        foster_id: foster?.id,
        organization_id: organization_id,
      });
      if (res.fosterRequests && res.fosterRequests?.length > 0) {
        setDidContact(true);
      }
    };
    if (!didContact) {
      checkRequested();
    }
  }, [foster?.id, organization_id, didContact]);

  const submitContactRequest = async () => {
    if (didContact) {
      return;
    }
    setIsLoading(true);
    try {
      const fosterRequestRes = await createFosterRequest({
        foster_id: foster?.id,
        organization_id,
      });
      if (fosterRequestRes.success) {
        const res = await submitFosterContactRequest({
          foster_email: foster?.email,
          foster_name: foster?.first_name,
          organization_name: organization_name,
        });
        if (res.success) {
          setDidContact(true);
        } else {
          console.log(res.error);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row" }}>
        <View style={styles.avatar}>
          <Label styles={{ color: "white" }} sz="small" weight="medium">
            {foster?.first_name?.charAt(0).toUpperCase()}
            {foster?.last_name?.charAt(0).toUpperCase()}
          </Label>
        </View>
        <View style={{ flex: 1 }}>
          <Label
            weight="medium"
            styles={{
              color: "black",
            }}
            numberOfLines={2}
            sz="small"
          >
            {foster?.first_name} {foster?.last_name?.charAt(0).toUpperCase()}.
          </Label>
          {address?.city ? (
            <Label styles={{ paddingVertical: 4 }} sz="xsmall">
              {address?.city}, {address?.state}
            </Label>
          ) : (
            <Label styles={{ paddingVertical: 4 }} sz="xsmall">
              Address available upon request
            </Label>
          )}
        </View>

        <Pressable
          style={{
            backgroundColor: isLoading
              ? theme.colors.secondary[200]
              : didContact
              ? "black"
              : theme.colors.secondary[400],
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 8,
            padding: 8,
            isDisabled: isLoading,
          }}
          onPress={submitContactRequest}
        >
          <Label
            styles={{ paddingVertical: 4, color: "white" }}
            weight="medium"
            sz="small"
          >
            {didContact ? "Request sent" : "Contact"}
          </Label>
        </Pressable>
      </View>

      <View
        style={{
          paddingTop: 12,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flex: 1 }}>
          <Label styles={{ paddingVertical: 4 }} weight="bold" sz="xsmall">
            General information
          </Label>
          <Label styles={{ paddingVertical: 4 }} sz="xsmall">
            <Label sz="xsmall" styles={{ color: theme.colors.secondary[300] }}>
              Breed preference:
            </Label>{" "}
            {foster.breed_preference || "None"}
          </Label>

          <Label styles={{ paddingVertical: 4 }} sz="xsmall">
            <Label sz="xsmall" styles={{ color: theme.colors.secondary[300] }}>
              Has fostered before:
            </Label>{" "}
            {foster.has_foster_experience ? "Yes" : "No"}
          </Label>

          <Label numberOfLines={2} styles={{ paddingVertical: 4 }} sz="xsmall">
            <Label sz="xsmall" styles={{ color: theme.colors.secondary[300] }}>
              Existing pets:
            </Label>{" "}
            {foster.existing_pets_description || "None"}
          </Label>
        </View>

        <View>
          <Label styles={{ paddingVertical: 4 }} weight="bold" sz="xsmall">
            Preferences
          </Label>
          <Label styles={{ paddingVertical: 4 }} sz="xsmall">
            <Label sz="xsmall" styles={{ color: theme.colors.secondary[300] }}>
              Availability:
            </Label>{" "}
            Up to {foster.preferred_foster_duration_high} month
            {Number(foster.preferred_foster_duration_high) === 1 ? "" : "s"}
          </Label>
          <Label styles={{ paddingVertical: 4 }} sz="xsmall">
            <Label sz="xsmall" styles={{ color: theme.colors.secondary[300] }}>
              Age range:
            </Label>{" "}
            {foster.animal_age_preference_low} -{" "}
            {foster.animal_age_preference_high} years old
          </Label>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 600,
    marginVertical: 8,
    padding: 18,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.colors.border,
  },
  avatar: {
    backgroundColor: theme.colors.secondary[200],
    borderRadius: 18,
    width: 36,
    height: 36,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default FosterCard;
