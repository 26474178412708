import React from "react";
import { View, StyleSheet } from "react-native";
import { Pressable, Image } from "react-native-web";
import { theme } from "../../../config/theme";
import Label from "../../Label";
import { FaSignOutAlt } from "react-icons/fa";
import { signOut } from "../../../services/firebaseService";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const onPressLogout = async () => {
    await signOut();
    navigate("/");
  };
  const onPressLogo = () => {
    navigate("/");
  };
  return (
    <View style={styles.header}>
      <Pressable style={styles.logo} onPress={onPressLogo}>
        <Image
          style={{
            width: 130,
            height: 36,
            resizeMode: "contain",
          }}
          source={require("../../../assets/images/fosterfi-logo.png")}
        />
      </Pressable>
      <Pressable style={styles.logo} onPress={onPressLogout}>
        <Label weight="medium" sz="small" styles={styles.title}>
          Log out
        </Label>
        <FaSignOutAlt color={theme.colors.secondary[400]} size="20" />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: "white",
    padding: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    alignItems: "center",
    flexDirection: "row",
  },
  title: {
    color: theme.colors.secondary[400],
    paddingHorizontal: 8,
  },
});

export default Header;
