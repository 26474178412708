import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { isEmailAddress } from "../../config/formatter";
import { useNavigate } from "react-router-dom";
import { StyleSheet, View, Dimensions, Linking } from "react-native-web";
import LabeledInput from "../../components/LabeledInput";
import Label from "../../components/Label";
import { theme } from "../../config/theme";
import { createUser, isAuthenticated } from "../../services/firebaseService";
import { logAnalyticsEvent } from "../../services/firebaseService";
import { isMobile, setIsRescueAccount } from "../../config/utils";

const SignupRescue = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const isAuthed = isAuthenticated();
  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "rescue_signup_visited" });
    if (isAuthed && window.localStorage.getItem("IS_RESCUE")) {
      navigate("/rescue");
    }
  }, [isAuthed, navigate]);

  const onSubmitSignup = async () => {
    logAnalyticsEvent({ eventName: "rescue_signup_submit" });

    if (!name) {
      setError("Please enter your organization name");
      return;
    }
    if (!isEmailAddress(email)) {
      setError("Invalid email address");
      return;
    }
    if (!password || password.length < 6) {
      setError("Password must be 6 or more characters");
      return;
    }
    setIsLoading(true);
    const res = await createUser(
      email.toLowerCase().trim(),
      password.trim(),
      true,
      name
    );
    setIsLoading(false);
    if (res.success) {
      setIsRescueAccount(true);
      navigate("/rescue");
    } else {
      setError(res.error || "Failed");
    }
  };

  return (
    <View style={styles.backgroundContainer}>
      <View style={styles.container}>
        <Label styles={styles.headerText} sz="xxlarge" weight="bold">
          Expand Your Foster Program with FosterFi
        </Label>
        <Label styles={styles.text} sz="medium">
          Effortlessly expand your reach and streamline your rescue operations.
        </Label>

        <View style={styles.benefitsContainer}>
          <Label styles={styles.benefitText} sz="medium">
            🤝 Connect with a community of fosters
          </Label>
          <Label styles={styles.benefitText} sz="medium">
            📊 Track and manage their expenses
          </Label>
          <Label styles={styles.benefitText} sz="medium">
            🌟 Streamline your operations
          </Label>
        </View>

        <LabeledInput
          label="Organization name"
          value={name}
          onChange={setName}
          placeholder="Your organization"
          width={Dimensions.get("window").width - 50}
        />
        <LabeledInput
          label="Email"
          value={email}
          onChange={setEmail}
          placeholder="abc@email.com"
          width={Dimensions.get("window").width - 50}
        />
        <LabeledInput
          isSecure
          label="Password"
          value={password}
          onChange={setPassword}
          placeholder="6 or more characters"
          width={Dimensions.get("window").width - 50}
        />
        <Label sz="small" styles={{ color: theme.colors.error }}>
          {error}
        </Label>

        <Button
          borderColor="black"
          isDisabled={isLoading}
          width={Dimensions.get("window").width - 50}
          onPress={onSubmitSignup}
        >
          Sign up
        </Button>

        <Label styles={{ paddingTop: 24, paddingBottom: 6 }} sz="medium">
          Already have an account?{" "}
          <Label
            onPress={() => navigate("/login")}
            weight="medium"
            sz="medium"
            styles={{ color: theme.colors.secondary[400] }}
          >
            Login
          </Label>
        </Label>

        <Label
          onPress={() => Linking.openURL("https://tryfosterfi.com")}
          sz="medium"
          styles={{
            color: theme.colors.secondary[400],
            textDecorationLine: "underline",
            paddingVertical: 18,
          }}
        >
          Learn more
        </Label>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundContainer: {
    flex: 1,
    backgroundColor: theme.colors.secondary[100],
    alignItems: "center",
    width: Dimensions.get("window").width,
  },
  container: {
    borderRadius: 12,
    backgroundColor: "white",
    marginTop: 36,
    padding: 24,
    alignItems: "center",
  },
  benefitsContainer: {
    marginBottom: 28,
    height: 100,
    width: "100%",
    justifyContent: "space-between",
  },
  text: {
    width: "100%",
    maxWidth: 600,
    textAlign: isMobile(Dimensions.get("window").width) ? "left" : "center",
    paddingBottom: "20px",
    paddingTop: "10px",
  },
  orText: {
    width: "100%",
    maxWidth: 800,
    textAlign: "center",
    paddingTop: 24,
    paddingBottom: 8,
  },
  headerText: {
    width: "100%",
    maxWidth: 800,
    textAlign: isMobile(Dimensions.get("window").width) ? "left" : "center",
    paddingBottom: 8,
  },
});

export default SignupRescue;
