import { View, StyleSheet, Dimensions } from "react-native-web";
import Label from "../Label";
import { theme } from "../../config/theme";
import { isMobile } from "../../config/utils";

type Props = {
  icon?: JSX.Element;
  title: string;
  message: string;
  backgroundColor?: string;
  textColor?: string;
};
const InfoCard = ({
  icon,
  title,
  message,
  backgroundColor,
  textColor,
}: Props) => {
  return (
    <View style={[styles.container, { backgroundColor }]}>
      {icon}
      <View>
        <Label
          styles={[styles.header, { color: textColor }]}
          weight="medium"
          sz={isMobile(Dimensions.get("window").width) ? "medium" : "large"}
        >
          {title}
        </Label>
        <Label
          styles={[styles.message, { color: textColor }]}
          sz={isMobile(Dimensions.get("window").width) ? "small" : "small"}
        >
          {message}
        </Label>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-evenly",
    maxWidth: 400,
    alignItems: "center",
    padding: 16,
    marginLeft: 6,
    marginRight: 6,
    paddingTop: 28,
    paddingBottom: 36,
    borderRadius: 12,
    backgroundColor: "white",
    flexDirection: "row",
  },
  header: {
    paddingLeft: 8,
  },
  message: {
    maxWidth: 260,
    paddingLeft: 8,
    paddingTop: 8,
    color: theme.colors.tertiary,
  },
});

export default InfoCard;
