import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Linking,
  ScrollView,
} from "react-native";
import Label from "../../../components/Label";
import {
  isAuthenticated,
  logAnalyticsEvent,
} from "../../../services/firebaseService";
import { fetchApplications } from "../../../services/apiService";
import { Application } from "../../../types/types";
import ApplicationCard from "../../../components/ApplicationCard";
import { theme } from "../../../config/theme";
import { IconLogoMark } from "../../../assets/icons/IconLogoMark";
import IconCardNfc from "../../../assets/icons/IconCardNfc";
import { isMobile } from "../../../config/utils";
import useOrganization from "../../../hooks/useOrganization";

const Dashboard = () => {
  const width = Dimensions.get("window").width;
  const organization = useOrganization();
  const isAuthed = isAuthenticated();
  const [applications, setApplications] = useState<Application[]>([]);
  useEffect(() => {
    logAnalyticsEvent({ eventName: "rescue_dashboard_visited" });
    const getApplications = async () => {
      if (!organization) {
        return;
      }
      const res = await fetchApplications({
        organization_id: organization.id,
      });
      if (res.applications) {
        setApplications(res.applications);
      }
    };

    if (isAuthed) {
      getApplications();
    }
  }, [isAuthed, organization]);
  return (
    <View style={styles.container}>
      <Label styles={styles.headerText} sz="xlarge" weight="bold">
        Dashboard
      </Label>
      <View style={{ height: 600 }}>
        <View
          style={{
            maxWidth: 340,
            backgroundColor: theme.colors.secondary[400],
            padding: 16,
            borderRadius: 16,
          }}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <IconLogoMark fill={theme.colors.secondary[200]} />
            <IconCardNfc />
          </View>

          <Label
            styles={{ color: "white", paddingVertical: 16 }}
            weight="medium"
            sz="xlarge"
          >
            {organization?.name}
          </Label>
          <Label styles={{ color: "white" }} sz="xsmall">
            Balance amount (Coming soon!)
          </Label>
          <Label
            styles={{
              paddingTop: 6,
              color: "white",
              filter: "blur(2px)", // Adjust the blur intensity here
            }}
            sz="xlarge"
          >
            $0.00
          </Label>
          <Label
            onPress={() => Linking.openURL("https://tryfosterfi.com")}
            sz="small"
            weight="medium"
            styles={{
              color: "white",
              textDecorationLine: "underline",
              paddingTop: 8,
            }}
          >
            Learn more about FosterFi
          </Label>
        </View>
        <View
          style={{
            flexDirection: isMobile(width) ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flex: 1,
              paddingVertical: 16,
              height: 400,
              maxWidth: isMobile(Dimensions.get("window").width)
                ? undefined
                : "40%",
            }}
          >
            <Label styles={styles.sectionText} sz="large" weight="medium">
              My Foster Applications
            </Label>

            <ScrollView
              style={{
                height: 300,
                width: "100%",
              }}
            >
              {applications?.length > 0 ? (
                applications?.map((app) => {
                  return (
                    <ApplicationCard
                      createdAt={app.createdAt}
                      foster_auth_id={app.foster_auth_id}
                      organization_petfinder_id={
                        organization?.petfinder_id || ""
                      }
                    />
                  );
                })
              ) : (
                <Label
                  sz="medium"
                  styles={{ paddingTop: 8, color: theme.colors.text.body }}
                >
                  🤔 No applications so far. Try checking back later or keep a
                  lookout in your email.
                </Label>
              )}
            </ScrollView>
          </View>

          {/* <View style={{ flex: 1, paddingVertical: 16 }}>
            <Label styles={styles.sectionText} sz="medium" weight="medium">
              Fosters Near Me
            </Label>

            <Label styles={styles.sectionText} sz="small">
              Coming soon!
            </Label>
          </View> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  headerText: {
    color: theme.colors.secondary[400],
    width: "100%",
    maxWidth: 800,
    paddingBottom: 16,
  },
  sectionText: {
    width: "100%",
    maxWidth: 800,
    paddingBottom: 8,
    paddingTop: 8,
  },
});

export default Dashboard;
