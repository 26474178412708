import { useEffect } from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native-web";
import { logAnalyticsEvent } from "../../../services/firebaseService";

import Label from "../../../components/Label";
import { useParams } from "react-router-dom";
import { isMobile } from "../../../config/utils";
import { theme } from "../../../config/theme";

const AllAboutCatFostering = () => {
  const { id } = useParams();

  useEffect(() => {
    logAnalyticsEvent({
      eventName: "blog_detail_visited",
      data: { blogId: id },
    });
  }, [id]);

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../../assets/images/foster-cat.jpeg")}
      />
      <Label weight="medium" sz="xxlarge">
        All About Cat Fostering
      </Label>
      <Label weight="bold" styles={styles.header} sz="large">
        The Purrfect Way to Make a Difference
      </Label>
      <Label styles={styles.body} sz="medium">
        Are you a cat lover with a big heart and a desire to help animals in
        need? Fostering a cat might be just the opportunity you've been looking
        for! Let's dive into the world of cat fostering and discover why it's a
        rewarding experience for both you and your temporary feline friend.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        What is Cat Fostering?
      </Label>
      <Label styles={styles.body} sz="medium">
        Cat fostering involves providing a temporary home for rescue cats and
        kittens until they find their furever families. It's a crucial step in
        the adoption process. By offering cats a safe and loving environment,
        you are increasing their chances of getting adopted and helping them
        thrive along the way.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Why Foster a Cat or Kitten?
      </Label>
      <Label styles={styles.body} sz="medium">
        Fostering a cat is a deeply rewarding experience that enriches both your
        life and the life of your temporary companion. Not only do you provide a
        second chance at happiness for a shelter cat, but you also experience
        the joy of unconditional love, companionship, and the satisfaction of
        making a difference in the lives of animals in need.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        How to Get Started
      </Label>
      <Label styles={styles.body} sz="medium">
        Getting started as a foster pet parent is easier than you might think.
        Simply visit PetFosterz.com and fill out our foster application. Once
        completed, you can apply for different rescue animals or shelters
        directly. We will also do our best to match you with a car or kitten
        that fits your lifestyle and preferences.{"\n\n"}The rescue provides all
        the support and resources you need, from food and supplies to veterinary
        care and training assistance.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Conclusion
      </Label>

      <Label styles={styles.body} sz="medium">
        Ready to embark on your cat fostering journey? Join us at PetFosterz.com
        in our mission to unleash love and save lives, one paw at a time.
      </Label>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    marginBottom: 16,
    maxWidth: 1200,
    width: "100%",
    height: isMobile(Dimensions.get("window").width) ? 300 : 500,
    resizeMode: "cever",
    borderRadius: 8,
  },
  header: {
    paddingBottom: 16,
    paddingTop: 28,
  },
  body: {
    maxWidth: 1200,
    paddingBottom: 16,
    color: theme.colors.text.body,
  },
});

export default AllAboutCatFostering;
