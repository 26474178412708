import React, { useEffect, useState } from "react";
import { View, Dimensions, StyleSheet } from "react-native-web";
import { useLocation } from "react-router-dom";
import { logAnalyticsEvent } from "../../services/firebaseService";
import FilterSearchInput from "../../components/FilterSearchInput";
import Loading from "../../components/Loading";
import { theme } from "../../config/theme";
import { debounce, isDesktop, isMobile } from "../../config/utils";
import OrgCard from "../../components/OrgCard";
import useDebounce from "../../config/useDebounce";
import useAddress from "../../hooks/useAddress";
import useFetchPetFinderOrganizations from "../../hooks/useFetchPetFinderOrganizations";
import Label from "../../components/Label";

const Organizations = () => {
  const { state } = useLocation();
  const [search, setSearch] = useState(state?.query || "");
  const [page, setPage] = useState("1");
  const location = window.localStorage.getItem("LOCATION") || "33101";

  const address = useAddress();
  const { organizationsList, isLoading, fetchData } =
    useFetchPetFinderOrganizations(location, search, page, address);

  useDebounce(
    () => {
      if (search.length === 1 || search.length === 2) {
        // Don't search 1 or 2 character inputs
        return;
      }
      setPage("1");
      fetchData("1");
    },
    [search],
    500
  );

  useEffect(() => {
    logAnalyticsEvent({
      eventName: "organizations_fetch_page",
      data: { page },
    });

    fetchData();
  }, [page, fetchData]);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.documentElement;
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const clientHeight = element.clientHeight;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      if (isAtBottom && !isLoading) {
        setPage((prevPage) => (Number(prevPage) + 1).toString());
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [isLoading]);

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <FilterSearchInput
          placeholder="Try name, city, state"
          search={search}
          setSearch={setSearch}
          onPressSearch={() => fetchData("1")}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          width: "100%",
          paddingLeft: isDesktop(Dimensions.get("window").width) ? 36 : 0,
          paddingRight: isDesktop(Dimensions.get("window").width) ? 36 : 0,
          justifyContent: "center",
        }}
      >
        <View style={styles.gridContainer}>
          {organizationsList.map((org, i) => {
            const formattedName = org.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            const orgImageUrl = org.photos.find((p) => p.medium)?.medium;
            return (
              <OrgCard
                key={i}
                org={org}
                imageUrl={orgImageUrl}
                name={formattedName}
                distance={org.distance}
              />
            );
          })}
          {isLoading && <Loading height={100} />}
          {organizationsList.length === 0 && !isLoading && (
            <Label
              sz="small"
              styles={{
                textAlign: "center",
                paddingHorizontal: 16,
                paddingVertical: 8,
              }}
            >
              We are experiencing more traffic than normal. Please check back
              later! 😕
            </Label>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.primary,
  },
  searchContainer: {
    alignItems: "center",
    marginTop: 16,
    marginBottom: 16,
  },
  filterContainer: {
    padding: 24,
    width: 220,
  },
  gridContainer: {
    width: isMobile(Dimensions.get("window").width) ? "100%" : "60%",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default Organizations;
