import {
  StyleSheet,
  View,
  Pressable,
  Image,
  Dimensions,
  ScrollView,
} from "react-native-web";
import Label from "../../components/Label";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { theme } from "../../config/theme";
import { isMobile } from "../../config/utils";
import he from "he";
import { Animal, PetFinderOrganization } from "../../types/types";
import Button from "../../components/Button";
import {
  auth,
  isAuthenticated,
  logAnalyticsEvent,
} from "../../services/firebaseService";
import {
  createApplication,
  fetchAddress,
  fetchDistanceInMiles,
  submitApplication,
} from "../../services/apiService";
import {
  fetchPetFinderAnimals,
  fetchPetfinderOrganization,
} from "../../services/petfinderService";
import SuccessModal from "../../components/SuccessModal";
import PetCard from "../../components/PetCard";
import { FaArrowLeft } from "react-icons/fa";
import ConfirmationModal from "../../components/ConfirmationModal";

const PetDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const animal: Animal = state.animal;

  const [isLoading, setIsLoading] = useState(false);
  const [didSendApplication, setDidSendApplication] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const [petImageUrl, setPetImageUrl] = useState(
    animal?.photos?.find((p) => p.full)?.full
  );
  const [organization, setOrganization] =
    useState<PetFinderOrganization | null>(null);
  const [petsList, setPetsList] = useState<Animal[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchOrganizationDetails = async () => {
      const res = await fetchPetfinderOrganization({
        id: animal?.organization_id,
      });
      if (res.organization) {
        setOrganization(res.organization);
      }
    };
    if (!animal?.is_rescue_groups) {
      fetchOrganizationDetails();
    }
  }, [animal?.organization_id, animal?.is_rescue_groups]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchPetFinderAnimals({
        location: window.localStorage.getItem("LAT_LNG") || "",
        organization: organization?.id,
        page: "1",
        limit: "12",
      });
      if (res?.animals) {
        setPetsList(res.animals);
      }
    };
    if (
      !petsList.length &&
      organization &&
      !window.localStorage.getItem("IS_RESCUE")
    ) {
      fetchData();
    }
  }, [organization, petsList]);

  const formattedName = animal?.name
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

  const onSubmitApplication = async () => {
    if (isAuthenticated()) {
      setIsLoading(true);
      logAnalyticsEvent({
        eventName: "submit_application",
        data: { screen: "pet" },
      });
      const userId = auth.currentUser?.uid || "";
      const addressRes = await fetchAddress({ userId });
      // Validate user location requirements
      if (addressRes.address?.city || window.localStorage.getItem("LAT_LNG")) {
        const userAddress = addressRes.address?.city
          ? `${addressRes.address?.city}, ${addressRes.address?.state}`
          : window.localStorage.getItem("LAT_LNG") || "";
        const animalAddress = animal.contact?.address?.city
          ? `${animal.contact.address.city}, ${animal.contact.address.state}`
          : `${organization?.address.city}, ${organization?.address.state}`;
        const distanceRes = await fetchDistanceInMiles(
          userAddress,
          animalAddress
        );
        if (distanceRes > 150) {
          //show alert
          setIsConfirmationVisible(true);
          return;
        }
      } else {
        window.alert(
          "Location not set. Please update your application address or enable location permissions."
        );
        return;
      }
      if (!animal?.is_rescue_groups) {
        // PetFinder Organization
        const orgRes = await fetchPetfinderOrganization({
          id: animal.organization_id,
        });
        const createAppRes = await createApplication({
          auth_user_id: userId,
          organization_petfinder_id: orgRes?.organization?.id,
        });
        if (createAppRes.success) {
          const submitRes = await submitApplication({
            organization_email:
              animal.contact?.email ||
              orgRes?.organization.email ||
              "achall@toplev.io", // TODO: change this
            auth_user_id: userId,
            organization_name: orgRes?.organization?.name,
            pet_name: animal?.name,
            organization_petfinder_id: orgRes?.organization?.id,
          });
          if (submitRes.success) {
            setDidSendApplication(true);
          }
        }
      } else {
        // Non petfinder organization
        const createAppRes = await createApplication({
          auth_user_id: userId,
          organization_rescuegroup_id: animal?.organization_id,
        });
        if (createAppRes.success) {
          const submitRes = await submitApplication({
            organization_email: animal.contact?.email || "achall@toplev.io", // TODO: change this
            auth_user_id: userId,
            organization_name: animal?.contact?.org_name || "Rescue Group",
            pet_name: animal?.name,
            organization_rescuegroup_id: animal?.organization_id,
          });
          if (submitRes.success) {
            setDidSendApplication(true);
          }
        }
      }

      setIsLoading(false);
    } else {
      logAnalyticsEvent({
        eventName: "submit_application_attempt",
        data: { screen: "pet" },
      });
      navigate("/create-account");
    }
  };

  return (
    <View
      style={{
        backgroundColor: theme.colors.secondary[100],
        padding: 16,
        width: "100%",
      }}
    >
      <Pressable style={{ alignSelf: "start" }} onPress={() => navigate(-1)}>
        <FaArrowLeft size={28} />
      </Pressable>

      <View
        style={{
          alignSelf: "center",
          alignItems: "center",
          paddingTop: 16,
          width: "100%",
          maxWidth: 1000,
        }}
      >
        <Image
          style={styles({ isSelected: false }).petImage}
          source={
            petImageUrl
              ? { uri: petImageUrl }
              : require("../../assets/images/avatar.png")
          }
        />

        <ScrollView
          style={{ width: "100%", maxWidth: 1000 }}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
        >
          <View
            style={{
              maxWidth: 1000,
              width: "100%",
              paddingTop: 16,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {animal.photos.map((photo, i) => {
              return (
                <Pressable key={i} onPress={() => setPetImageUrl(photo.full)}>
                  <Image
                    style={
                      styles({ isSelected: photo.full === petImageUrl })
                        .petImageSmall
                    }
                    source={{ uri: photo.full }}
                  />
                </Pressable>
              );
            })}
          </View>
        </ScrollView>

        <View style={styles({}).sectionContainer}>
          <Label styles={{}} sz="xlarge">
            {formattedName}
          </Label>
          <Label
            numberOfLines={1}
            styles={{
              paddingRight: 16,
              paddingTop: 8,
              color: theme.colors.secondary[400],
            }}
            weight="medium"
            sz="medium"
          >
            {animal.species === "Dog"
              ? animal.breeds.primary
              : `${animal.species} | ${animal.breeds.primary}`}
          </Label>
          <Label numberOfLines={1} styles={{ paddingTop: 8 }} sz="medium">
            {animal.distance ? `${animal.distance.toFixed(1)} mi away • ` : ""}
            {animal.contact.address.city}
          </Label>

          <View style={styles({}).divider} />
          <Label styles={{ paddingBottom: 8 }} sz="medium">
            {animal.age} • {animal.gender} • {animal.size}{" "}
            {animal.coat ? `• ${animal.coat}` : ""}
          </Label>

          {animal?.is_rescue_groups ? (
            <Label styles={{}} sz="medium">
              {he.decode(animal.description || "")} Contact{" "}
              {organization?.name || "us"} for more information on {animal.name}
              .
            </Label>
          ) : animal.description ? (
            <Label styles={{}} sz="medium">
              {animal.description.split(".").length > 0
                ? he.decode(animal.description.split(".")[0])
                : ""}
              . Contact {organization?.name || "us"} for more information on{" "}
              {animal.name}.
            </Label>
          ) : (
            <Label styles={{}} sz="medium">
              Contact {organization?.name || "us"} for more information on{" "}
              {animal.name}.
            </Label>
          )}
        </View>

        {(animal.tags?.length ||
          animal.environment.cats ||
          animal.environment.dogs ||
          animal.environment.children) && (
          <View style={styles({}).sectionContainer}>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {animal.environment.cats && (
                <Label
                  styles={{
                    marginRight: 8,
                    marginBottom: 8,
                    padding: 8,
                    color: "white",
                    backgroundColor: theme.colors.secondary[400],
                    borderRadius: 30,
                  }}
                  sz="medium"
                >
                  Good with cats
                </Label>
              )}

              {animal.environment.dogs && (
                <Label
                  styles={{
                    marginRight: 8,
                    marginBottom: 8,
                    padding: 8,
                    color: "white",
                    backgroundColor: theme.colors.secondary[400],
                    borderRadius: 30,
                  }}
                  sz="medium"
                >
                  Good with dogs
                </Label>
              )}

              {animal.environment.children && (
                <Label
                  styles={{
                    padding: 8,
                    marginRight: 8,
                    marginBottom: 8,
                    color: "white",
                    backgroundColor: theme.colors.secondary[400],
                    borderRadius: 30,
                  }}
                  sz="medium"
                >
                  Good with children
                </Label>
              )}

              {animal.tags.map((tag) => {
                return (
                  <Label
                    styles={{
                      padding: 8,
                      marginBottom: 8,
                      marginRight: 8,
                      color: "white",
                      backgroundColor: theme.colors.secondary[400],
                      borderRadius: 30,
                    }}
                    sz="medium"
                  >
                    {tag}
                  </Label>
                );
              })}
            </View>
          </View>
        )}

        <View
          style={{
            alignItems: "center",
            width: "100%",
            maxWidth: 1000,
            justifyContent: "space-between",
            flexDirection: isMobile(Dimensions.get("window").width)
              ? "column"
              : "row",
          }}
        >
          {!window.localStorage.getItem("IS_RESCUE") && (
            <View
              style={[
                styles({}).sectionContainer,
                {
                  backgroundColor: theme.colors.secondary[400],
                  alignItems: "center",
                  paddingBottom: 24,
                  justifyContent: "center",
                  maxWidth: 480,
                  height: 280,
                },
              ]}
            >
              <Label
                styles={{ textAlign: "center", color: "white", padding: 16 }}
                sz="xlarge"
              >
                Considering to help {formattedName}?
              </Label>
              <Button
                backgroundColor={"white"}
                textColor={theme.colors.secondary[400]}
                isDisabled={isLoading}
                onPress={onSubmitApplication}
                width={330}
              >
                APPLY TO FOSTER
              </Button>
            </View>
          )}

          {!!organization && !window.localStorage.getItem("IS_RESCUE") ? (
            <View
              style={[
                styles({}).sectionContainer,
                {
                  alignItems: "center",
                  paddingBottom: 24,
                  maxWidth: 480,
                  height: 280,
                },
              ]}
            >
              <View style={{ flexDirection: "row", alignItem: "center" }}>
                <Pressable
                  onPress={() =>
                    navigate("/organization-detail", {
                      state: { organization },
                    })
                  }
                >
                  <Image
                    style={{
                      borderRadius: 45,
                      height: 90,
                      width: 90,
                      borderWidth: 1,
                      borderColor: theme.colors.secondary[400],
                    }}
                    source={{
                      uri: organization?.photos.find((p) => !!p.medium)?.medium,
                    }}
                  />
                </Pressable>
              </View>
              <Label
                sz="xlarge"
                styles={{ textAlign: "center", paddingTop: 8 }}
              >
                {organization?.name}
              </Label>
              <Label
                sz="medium"
                styles={{ textAlign: "center", paddingTop: 8 }}
              >
                {organization?.address.city}, {organization?.address.state}
              </Label>
              <Button
                borderColor={theme.colors.secondary[400]}
                isDisabled={isLoading}
                onPress={onSubmitApplication}
                width={330}
              >
                CONTACT RESCUE
              </Button>
            </View>
          ) : null}
        </View>

        {!window.localStorage.getItem("IS_RESCUE") && petsList.length > 0 && (
          <>
            <Label sz="xlarge">{"Other animals at this rescue"}</Label>

            <View style={styles({}).gridContainer}>
              {petsList.map((pet, i) => {
                const formattedName = pet.name
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ");
                const petImageUrl =
                  pet.photos.find((p) => p.medium)?.medium ||
                  pet.primary_photo_cropped?.medium;
                return (
                  <PetCard
                    key={i}
                    pet={pet}
                    imageUrl={petImageUrl}
                    name={formattedName}
                    breed={pet.breeds.primary}
                    age={pet.age}
                    distance={pet.distance}
                  />
                );
              })}
            </View>
          </>
        )}
      </View>

      <SuccessModal
        isVisible={didSendApplication}
        onDismiss={() => setDidSendApplication(false)}
      />
      <ConfirmationModal
        isVisible={isConfirmationVisible}
        title="You look a bit far 🤔"
        message="You appear to be more than 150 miles away from this rescue. If this is incorrect, please enable location permissions or update your address on your application."
        cancelBtn="Dismiss"
        onDismiss={() => {
          setIsConfirmationVisible(false);
        }}
      />
    </View>
  );
};

const styles = (props: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: 10,
    },
    divider: {
      height: 1,
      width: "100%",
      borderRadius: 2,
      backgroundColor: theme.colors.tertiary,
      marginBottom: 16,
      marginTop: 16,
    },
    sectionContainer: {
      marginTop: 16,
      marginBottom: 16,
      backgroundColor: "white",
      padding: 12,
      borderRadius: 8,
      maxWidth: 1000,
      width: "100%",
      alignSelf: "center",
    },
    icon: {
      fontSize: 24,
      lineHeight: 24,
    },
    pressable: {
      paddingLeft: 16,
      paddingTop: 12,
      paddingBottom: 12,
    },
    petImage: {
      borderRadius: 12,
      width: isMobile(Dimensions.get("window").width) ? "100%" : "60%",
      height: 365,
      alignSelf: "start",
      resizeMode: "cover",
    },
    petImageSmall: {
      borderWidth: props.isSelected ? 3 : 0,
      borderColor: theme.colors.secondary[400],
      borderRadius: 12,
      marginRight: 12,
      width: 130,
      height: 100,
      resizeMode: "cover",
    },
    readMoreLink: {
      color: theme.colors.text.link,
    },
    gridContainer: {
      paddingTop: 12,
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
  });

export default PetDetail;
