import { StyleSheet, View, Dimensions } from "react-native-web";
import { theme } from "../../../config/theme";
import Label from "../../Label";
import LabeledInput from "../../LabeledInput";
import LabeledCheckBox from "../../LabeledCheckBox";
import { useState } from "react";
import { Foster } from "../../../types/types";
import { logAnalyticsEvent } from "../../../services/firebaseService";
import { updateFoster } from "../../../services/apiService";
import Button from "../../Button";
import LabeledSwitch from "../../LabeledSwitch";

type Props = {
  isEditable: boolean;
  application?: Foster;
  userId: string;
  onRefresh?: () => void;
};
const FosterPreferences = ({
  isEditable,
  application,
  userId,
  onRefresh,
}: Props) => {
  const [isEditing, setIsEditing] = useState(
    isEditable && !application?.foster_services_available
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [breedPreference, setBreedPreference] = useState(
    application?.breed_preference || ""
  );
  const [fosterServicesAvailable, setFosterServicesAvailable] = useState(
    application?.foster_services_available?.toString() || ""
  );
  const [hasFosterExperience, setHasFosterExperience] = useState(
    application?.has_foster_experience || false
  );
  const [dogAgePreferenceLow, setDogAgePreferenceLow] = useState(0);
  const [dogAgePreferenceHigh, setDogAgePreferenceHigh] = useState(2);
  const [preferredFosterDurationLow, setPreferredFosterDurationLow] =
    useState(0);
  const [preferredFosterDurationHigh, setPreferredFosterDurationHigh] =
    useState(1);

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }
    setError("");
    setIsLoading(true);

    logAnalyticsEvent({ eventName: "foster_preferences_updated" });
    if (application?.id) {
      const res = await updateFoster({
        id: application?.id,
        auth_user_id: userId,
        has_foster_experience: hasFosterExperience,
        foster_services_available: fosterServicesAvailable
          .split(",")
          .map((s) => s.trim()),
        breed_preference: breedPreference,
        animal_age_preference_low: dogAgePreferenceLow,
        animal_age_preference_high: dogAgePreferenceHigh,
        preferred_foster_duration_low: preferredFosterDurationLow,
        preferred_foster_duration_high: preferredFosterDurationHigh,
      });
      if (res.success) {
        setIsEditing(false);
        onRefresh && onRefresh();
      } else {
        setError("Failed to save. Please try again later.");
      }
    }

    setIsLoading(false);
  };

  const updateFosterServices = (v: string, isChecked: boolean) => {
    if (isChecked) {
      const newVal = fosterServicesAvailable + " " + v;
      setFosterServicesAvailable(newVal.trim());
    } else {
      setFosterServicesAvailable(fosterServicesAvailable.replace(v, "").trim());
    }
  };

  return (
    <View style={{ width: "100%" }}>
      {isEditable && (
        <View
          style={{
            paddingBottom: isEditing ? 16 : 0,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {application?.foster_services_available ? (
            <Label styles={{ paddingRight: 16 }} weight="medium" sz="large">
              Foster preferences
            </Label>
          ) : (
            <Label
              styles={{ paddingRight: 16, color: theme.colors.text.body }}
              sz="medium"
            >
              This information will help us connect you to the right Animal
              Rescues 🐶🏠
            </Label>
          )}

          {application?.foster_services_available && isEditing ? (
            <Label
              weight="medium"
              onPress={() => setIsEditing(false)}
              sz="medium"
              styles={styles.clickableText}
            >
              Cancel
            </Label>
          ) : application?.foster_services_available ? (
            <Label
              weight="medium"
              onPress={() => setIsEditing(true)}
              sz="medium"
              styles={styles.clickableText}
            >
              Edit
            </Label>
          ) : null}
        </View>
      )}

      <View>
        {isEditing ? (
          <LabeledSwitch
            onPress={(v) => setHasFosterExperience(v.target.checked)}
            isSelected={hasFosterExperience}
            label="Foster experience"
            value="I have prior experience fostering a dog or cat"
          />
        ) : (
          <View>
            <Label styles={styles.labelText} sz="small">
              Foster experience
            </Label>
            <Label sz="medium">
              {application?.has_foster_experience ? "Yes" : "No"}
            </Label>
          </View>
        )}
        {isEditing ? (
          <View style={{ paddingBottom: 16 }}>
            <Label
              styles={{
                paddingBottom: 8,
                paddingTop: 16,
                color: "black",
              }}
              sz="medium"
              weight="medium"
            >
              Foster availability (select all that apply)
            </Label>
            <LabeledCheckBox
              onPress={(v) => updateFosterServices("foster", v.target.checked)}
              isSelected={fosterServicesAvailable.includes("foster")}
              label=""
              value="I am able to foster a pet in my home"
            />
            <LabeledCheckBox
              onPress={(v) =>
                updateFosterServices("transportation", v.target.checked)
              }
              isSelected={fosterServicesAvailable.includes("transportation")}
              label=""
              value="I am interested to help with transportation"
            />
            <LabeledCheckBox
              onPress={(v) =>
                updateFosterServices("volunteer", v.target.checked)
              }
              isSelected={fosterServicesAvailable.includes("volunteer")}
              label=""
              value="I am interested to volunteer my time at rescues"
            />
          </View>
        ) : (
          <View>
            <Label styles={styles.labelText} sz="small">
              Foster availability
            </Label>
            <Label sz="medium">
              {`Available for ${
                application?.foster_services_available
                  ?.toString()
                  .replace("foster", "fostering")
                  .replace("volunteer", "volunteering")
                  .replace(" ", ", ") || "anything as needed"
              }`}
            </Label>
          </View>
        )}

        {fosterServicesAvailable.includes("foster") ? (
          <>
            {isEditing ? (
              <LabeledInput
                label="Breed preference (optional)"
                value={breedPreference}
                onChange={setBreedPreference}
                placeholder="Dalmatian, Labrador, Cats"
                width={Dimensions.get("window").width - 40}
              />
            ) : (
              <View>
                <Label styles={styles.labelText} sz="small">
                  Breed preference
                </Label>
                <Label sz="medium">
                  {application?.breed_preference || "None"}
                </Label>
              </View>
            )}

            {isEditing ? (
              <View>
                <Label
                  styles={{
                    paddingBottom: 8,
                    paddingTop: 16,
                    color: "black",
                  }}
                  sz="medium"
                  weight="medium"
                >
                  Prefered Age Range
                </Label>
                <LabeledCheckBox
                  onPress={(v) => {
                    setDogAgePreferenceLow(0);
                    setDogAgePreferenceHigh(2);
                  }}
                  isSelected={dogAgePreferenceLow === 0}
                  label=""
                  value="0-2 years old"
                />
                <LabeledCheckBox
                  onPress={() => {
                    setDogAgePreferenceLow(2);
                    setDogAgePreferenceHigh(5);
                  }}
                  isSelected={dogAgePreferenceLow === 2}
                  label=""
                  value="2-5 years old"
                />

                <LabeledCheckBox
                  onPress={() => {
                    setDogAgePreferenceLow(5);
                    setDogAgePreferenceHigh(15);
                  }}
                  isSelected={dogAgePreferenceLow === 5}
                  label=""
                  value="5 years+"
                />
              </View>
            ) : (
              <View>
                <Label styles={styles.labelText} sz="small">
                  Preferred Age Range
                </Label>
                <Label sz="medium">
                  {application?.animal_age_preference_low}-
                  {application?.animal_age_preference_high} years old
                </Label>
              </View>
            )}

            {isEditing ? (
              <View>
                <Label
                  styles={{
                    paddingBottom: 8,
                    paddingTop: 16,
                    color: "black",
                  }}
                  weight="medium"
                  sz="medium"
                >
                  Preferred Foster Duration
                </Label>
                <LabeledCheckBox
                  onPress={(v) => {
                    setPreferredFosterDurationLow(0);
                    setPreferredFosterDurationHigh(1);
                  }}
                  isSelected={preferredFosterDurationLow === 0}
                  label=""
                  value="Less than 1 month"
                />
                <LabeledCheckBox
                  onPress={() => {
                    setPreferredFosterDurationLow(1);
                    setPreferredFosterDurationHigh(3);
                  }}
                  isSelected={preferredFosterDurationLow === 1}
                  label=""
                  value="1-3 months"
                />

                <LabeledCheckBox
                  onPress={() => {
                    setPreferredFosterDurationLow(3);
                    setPreferredFosterDurationHigh(6);
                  }}
                  isSelected={preferredFosterDurationLow === 3}
                  label=""
                  value="More than 3 months"
                />
              </View>
            ) : (
              <View>
                <Label styles={styles.labelText} sz="small">
                  Preferred foster duration
                </Label>
                <Label sz="medium">
                  {application?.preferred_foster_duration_low}-
                  {application?.preferred_foster_duration_high} months
                </Label>
              </View>
            )}
          </>
        ) : null}

        {error ? (
          <Label styles={styles.errorText} sz="small">
            {error}
          </Label>
        ) : null}
        {isEditing ? (
          <Button
            isDisabled={isLoading}
            width={Dimensions.get("window").width - 40}
            onPress={onSubmit}
          >
            {application?.foster_services_available ? "Update" : "Continue"}
          </Button>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: { paddingTop: 16, paddingRight: 16 },
  errorText: {
    color: theme.colors.error,
  },
  clickableText: {
    color: theme.colors.secondary[400],
    alignSelf: "start",
    textDecorationLine: "underline",
    paddingTop: 4,
  },
  labelText: {
    paddingBottom: 6,
    paddingTop: 16,
    color: "black",
  },
});
export default FosterPreferences;
