import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native-web";
import Label from "../../../components/Label";
import { auth, logAnalyticsEvent } from "../../../services/firebaseService";
import {
  createAddress,
  fetchAddress,
  fetchAllFosters,
  fetchOrganization,
  updateAddress,
} from "../../../services/apiService";
import { Address, Foster, Organization } from "../../../types/types";
import FosterCard from "../../../components/FosterCard";
import { theme } from "../../../config/theme";
import { debounce, isMobile } from "../../../config/utils";
import Loading from "../../../components/Loading";
import LabeledAddressInput from "../../../components/LabeledAddressInput";
import { fromAddress } from "react-geocode";

const Fosters = () => {
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [fosters, setFosters] = useState<Foster[]>([]);
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const [isEditingLocation, setIsEditingLocation] = useState(false);

  useEffect(() => {
    logAnalyticsEvent({ eventName: "rescue_fosters_visited" });

    const getOrg = async () => {
      if (!auth.currentUser?.uid) {
        return;
      }
      const orgRes = await fetchOrganization({ userId: auth.currentUser?.uid });
      if (orgRes.organization) {
        setOrganization(orgRes.organization);
      }
    };

    const getOrgAddress = async () => {
      if (!auth.currentUser?.uid) {
        return;
      }
      const addressRes = await fetchAddress({ userId: auth.currentUser?.uid });
      if (addressRes.address) {
        setAddress(addressRes.address);
      }
    };

    getOrg();
    getOrgAddress();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      if (isAtBottom) {
        setPageSize(pageSize + 20);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [pageSize]);

  const getAllFosters = async (lat?: number, lon?: number) => {
    setIsLoading(true);
    const res = await fetchAllFosters(lat, lon);
    if (res.fosters) {
      setFosters(res.fosters);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchFosters = async (address?: Address) => {
      const res = await fromAddress(
        `${address?.city}, ${address?.state}`,
        process.env.REACT_APP_MAPS_KEY
      );
      if (res.results.length > 0) {
        const { lat, lng } = res.results[0].geometry.location;
        getAllFosters(lat, lng);
      } else {
        getAllFosters();
      }
    };

    if (!isEditingLocation && address?.city) {
      fetchFosters(address);
    }
  }, [address, isEditingLocation]);

  return (
    <View style={styles.container}>
      <Label styles={styles.headerText} sz="xlarge" weight="bold">
        Find new fosters
      </Label>
      {address?.city ? (
        <View>
          <View style={{ flexDirection: "row" }}>
            <Label weight="medium" sz="small">
              Sorted by location
            </Label>
            <Label
              onPress={() => {
                setIsEditingLocation(true);
                setAddress(undefined);
              }}
              weight="medium"
              styles={{
                color: theme.colors.text.link,
                paddingLeft: 8,
                textDecorationLine: "underline",
              }}
              sz="small"
            >
              Edit
            </Label>
          </View>
          <Label
            styles={{ paddingVertical: 8 }}
            sz="xsmall"
          >{`${address?.city}, ${address?.state}`}</Label>
        </View>
      ) : (
        <View
          style={{
            paddingTop: 16,
            flexDirection: "row",
            zIndex: 10,
          }}
        >
          <LabeledAddressInput
            label="Set your location to filter fosters near you"
            value={{
              label: "",
            }}
            onChange={(v) => {
              if (v) {
                if (!address) {
                  createAddress({ ...v, auth_user_id: auth.currentUser?.uid });
                } else {
                  updateAddress({ ...v, auth_user_id: auth.currentUser?.uid });
                }
                setIsEditingLocation(false);
                setAddress(v);
              }
            }}
            placeholder=""
            width={
              isMobile(Dimensions.get("window").width)
                ? Dimensions.get("window").width - 96
                : 490
            }
          />
        </View>
      )}

      {isLoading ? (
        <Loading height={150} />
      ) : (
        <View style={styles.blurContainer}>
          {fosters
            .slice(0, pageSize)
            .filter((f) => !!f.first_name)
            .map((foster) => {
              return (
                <FosterCard
                  organization_name={organization?.name || "Petfosterz Rescue"}
                  foster={foster}
                  organization_petfinder_id={organization?.petfinder_id || ""}
                  organization_id={organization?.id || ""}
                />
              );
            })}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    minHeight: 600,
  },
  headerText: {
    color: theme.colors.secondary[400],
    width: "100%",
    maxWidth: 800,
    paddingBottom: 16,
  },
  blurContainer: {
    maxWidth: 1400,
    width: "100%",
  },
});

export default Fosters;
