import { useEffect } from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native-web";
import { logAnalyticsEvent } from "../../../services/firebaseService";

import Label from "../../../components/Label";
import { useParams } from "react-router-dom";
import { isMobile } from "../../../config/utils";
import { theme } from "../../../config/theme";

const WhyBecomeAPetFoster = () => {
  const { id } = useParams();

  useEffect(() => {
    logAnalyticsEvent({
      eventName: "blog_detail_visited",
      data: { blogId: id },
    });
  }, [id]);

  return (
    <View>
      <Image
        style={styles.image}
        source={require("../../../assets/images/shelter-dog.jpeg")}
      />
      <Label styles={styles.header} weight="medium" sz="xxxlarge">
        Why is Pet Fostering Important?
      </Label>
      <Label weight="medium" styles={styles.header} sz="large">
        We started PetFosterz.com because we believe fostering shelter animals
        may be even more important than adopting pets.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        The Reality
      </Label>
      <Label styles={styles.body} sz="medium">
        The reality of animal shelters today is a sobering reflection of our
        society's capacity for compassion, but also its shortcomings. Despite
        the tireless efforts of dedicated staff and volunteers, the numbers
        paint a grim picture.{"\n\n"}Across the United States alone, millions of
        dogs and cats find themselves in shelters each year, often through no
        fault of their own. While some are strays or abandoned by their owners,
        others are surrendered due to unforeseen circumstances such as financial
        hardship or changes in living situations. Regardless of their origin,
        these animals face an uncertain future, with euthanasia waiting as a
        grim possibility. No animal should deserve to die due to our
        shortcomings.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        The Numbers
      </Label>

      <Label styles={styles.body} sz="medium">
        The rate of euthanasia in shelters remains alarmingly high, with
        approximately{" "}
        <Label sz="medium" weight="bold">
          1.5 million shelter animals being euthanized annually.
        </Label>{" "}
        This staggering figure serves as a sad reminder of the harsh reality
        faced by many homeless pets, whose lives depend on our limited
        resources. This equates to over 4,100 animals daily and primarily due to
        lack of space in the many rescues and shelters across the world.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Pet Fostering: A glimpse of hope
      </Label>
      <Label styles={styles.body} sz="medium">
        The truth is, it is just not realistic to expect every single pet to get
        adopted. However, fostering provides a lifeline for shelter animals,
        offering them a temporary refuge where they can thrive in a loving home
        environment while awaiting their furever family. By opening our hearts
        and homes to foster pets, individuals can make a tangible difference in
        the lives of animals in need. Pet fostering is not only important; it's
        essential.
        {"\n\n"}It helps alleviate overcrowding in shelters, reduces the stress
        and anxiety experienced by animals in a shelter environment, and
        increases their chances of finding permanent homes. Moreover, fostering
        allows potential adopters to experience the joys of pet ownership
        without the long-term commitment, making it a win-win for both animals
        and humans alike.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Our Vision
      </Label>
      <Label styles={styles.body} sz="medium">
        We envision a world where pet fostering can become as widely understood
        and accepted as pet adoption. By doing so we can even reduce the amount
        of rehomed pets by encouraging pet fostering as a way to try out the new
        lifestyle and responsibilities involved in owning a pet before making
        the longterm commitment.
      </Label>

      <Label styles={styles.body} sz="medium">
        In our quest to create a more compassionate society and shining a light
        on the challenges faced by homeless pets, we can all play a part in
        making a difference, one paw at a time. Together, we can give voice to
        the voiceless and ensure that every animal has the chance to live a life
        filled with love, dignity, and purpose.
      </Label>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    maxWidth: 1200,
    width: "100%",
    height: isMobile(Dimensions.get("window").width) ? 300 : 500,
    resizeMode: "cever",
    borderRadius: 8,
  },
  header: {
    paddingBottom: 16,
    paddingTop: 28,
  },
  subHeader: {
    color: "black",
    paddingBottom: 8,
  },
  body: {
    maxWidth: 1200,
    paddingBottom: 16,
    color: theme.colors.text.body,
  },
});

export default WhyBecomeAPetFoster;
