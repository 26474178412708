import { useState, useCallback } from 'react';
import { Address, PetFinderOrganization } from '../types/types';
import { fetchPetfinderOrganizations } from '../services/petfinderService';

const useFetchPetFinderOrganizations = (
  location: string | null,
  search: string,
  page: string,
  address: Address | null
) => {
  const [organizationsList, setOrganizationsList] = useState<PetFinderOrganization[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = useCallback(async (forcePage?: string) => {
    setIsLoading(true);
    setError("");
    const pageToUse = forcePage || page;

    try {
      const res = await fetchPetfinderOrganizations({
        location: location || `${address?.city}, ${address?.state}` || "33427",
        query: search,
        page: pageToUse
      });
      if (res?.organizations) {
        setOrganizationsList(prev => Number(pageToUse) > 1 ? [...prev, ...res.organizations] : res.organizations);
      } else {
        setOrganizationsList([]);
        setError("No results were found in your area. Try checking back later.");
      }
    } catch (err) {
      setError("Failed to fetch animals. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [location, search, page, address]);

  return { organizationsList, isLoading, error, fetchData };
};

export default useFetchPetFinderOrganizations;
