import { useCallback, useEffect, useState } from "react";
import { View, Dimensions, StyleSheet, ScrollView } from "react-native-web";
import PetCard from "../../components/PetCard";
import Loading from "../../components/Loading";
import { debounce, isDesktop, isMobile } from "../../config/utils";
import { theme } from "../../config/theme";
import { useLocation } from "react-router-dom";
import FilterSearchInput from "../../components/FilterSearchInput";
import { logAnalyticsEvent } from "../../services/firebaseService";
import Label from "../../components/Label";
import useDebounce from "../../config/useDebounce";
import useAddress from "../../hooks/useAddress";
import useFetchPets from "../../hooks/useFetchPets";
import UseCurrentLocation from "../../components/UseCurrentLocation";
import useAnimals from "../../hooks/useAnimals";
import AnimalCard from "../../components/AnimalCard";
import { Animal } from "../../types/types";

const Pets = () => {
  const { state } = useLocation();
  const [search, setSearch] = useState(state?.search || "");
  const [petType, setPetType] = useState(state?.petType || "All pets");
  const [page, setPage] = useState("1");

  const location = window.localStorage.getItem("LOCATION");
  const hasLocationSet = !!window.localStorage.getItem("LAT_LNG");

  const address = useAddress();
  const { petsList, isLoading, error, fetchData } = useFetchPets(
    location,
    search,
    petType,
    page,
    address
  );
  const featuredAnimals = useAnimals();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchData = useCallback(
    debounce((page?: string) => {
      fetchData(page);
    }, 300),
    [fetchData]
  );

  useDebounce(
    () => {
      logAnalyticsEvent({
        eventName: "pets_fetch_page",
        data: { page, search, petType },
      });
      setPage("1");
      debouncedFetchData("1");
    },
    [search, petType, address],
    400
  );

  useEffect(() => {
    logAnalyticsEvent({
      eventName: "pets_fetch_page",
      data: { page, search, petType },
    });
    debouncedFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      if (isAtBottom && !isLoading) {
        setPage((prevPage) => (Number(prevPage) + 1).toString());
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [isLoading]);

  return (
    <View style={styles.container}>
      {featuredAnimals && featuredAnimals.length > 0 && (
        <View
          style={{
            padding: "8px",
            alignItems: isMobile(Dimensions.get("window").width)
              ? "start"
              : "center",
          }}
        >
          <Label
            styles={{ padding: "8px", color: theme.colors.secondary[400] }}
            sz={isMobile(Dimensions.get("window").width) ? "large" : "large"}
            weight="medium"
          >
            Featured Foster Animals
          </Label>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              maxWidth: isMobile(Dimensions.get("window").width) ? "100%" : 800,
            }}
          >
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal={true}
            >
              {featuredAnimals?.map((animal: Animal) => {
                return (
                  <AnimalCard
                    animal={animal}
                    name={animal.name}
                    imageUrl={animal.photoUrl}
                    age={animal.age}
                    breed={animal.breed || ""}
                  />
                );
              })}
            </ScrollView>
          </View>
        </View>
      )}

      <View style={styles.searchContainer}>
        <FilterSearchInput
          placeholder="Search by breed"
          search={search}
          setSearch={setSearch}
          petType={petType}
          setPetType={setPetType}
          onPressSearch={() => fetchData("1")}
        />
        {!hasLocationSet ? (
          <View style={{ paddingTop: 12 }}>
            <UseCurrentLocation
              onLocationFound={() => {
                fetchData("1");
              }}
            />
          </View>
        ) : null}
      </View>

      <View
        style={{
          flexDirection: "row",
          width: "100%",
          paddingLeft: isDesktop(Dimensions.get("window").width) ? 36 : "8px",
          paddingRight: isDesktop(Dimensions.get("window").width) ? 36 : "8px",
          justifyContent: "center",
        }}
      >
        {/* {isDesktop(Dimensions.get("window").width) && (
          <View style={styles.filterContainer}>
            <Label weight="medium" sz="medium">
              Type
            </Label>

            <Label weight="medium" sz="medium">
              Sort by
            </Label>

            <Label weight="medium" sz="medium">
              Breed
            </Label>

            <Label weight="medium" sz="medium">
              Age
            </Label>

            <Label weight="medium" sz="medium">
              Size
            </Label>

            <Label weight="medium" sz="medium">
              Gender
            </Label>
          </View>
        )} */}

        <View style={styles.gridContainer}>
          {petsList.map((pet, i) => {
            const formattedName = pet.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            const petImageUrl =
              pet.photos.find((p) => p.medium)?.medium ||
              pet.primary_photo_cropped?.medium;
            return (
              <PetCard
                key={i}
                pet={pet}
                imageUrl={petImageUrl}
                name={formattedName}
                breed={pet.breeds.primary}
                age={pet.age}
                distance={pet.distance}
              />
            );
          })}
          {petsList.length === 0 &&
            !isLoading &&
            (!!address || hasLocationSet) && (
              <Label
                styles={{
                  textAlign: "center",
                  paddingVertical: "16px",
                  paddingHorizontal: "16px",
                }}
                sz="medium"
              >
                No results found in your area 😔{"\n"}Try checking back later!
              </Label>
            )}
          {isLoading ? <Loading height={150} /> : null}
          {error ? (
            <Label numberOfLines={2} sz="medium" styles={styles.error}>
              {error}
            </Label>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.primary,
  },
  searchContainer: {
    alignItems: "center",
    marginTop: 16,
    marginBottom: 16,
  },
  filterContainer: {
    padding: 24,
    width: 220,
  },
  gridContainer: {
    width: isMobile(Dimensions.get("window").width) ? "100%" : 800,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 300,
  },
  error: {
    color: theme.colors.text.header,
    height: 500,
    padding: 28,
    textAlign: "center",
  },
});

export default Pets;
