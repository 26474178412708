import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

export const IconSuccess = ({
  color = "#4B9D99",
  width = 61,
  height = 60,
}: Props) => (
  <Svg width={width} height={height} viewBox="0 0 61 60" fill="none">
    <Rect x="0.5" width="60" height="60" rx="30" fill={color} />
    <Path
      d="M26.1667 33.4666L38.9501 20.6832C39.3473 20.286 39.8528 20.0874 40.4667 20.0874C41.0806 20.0874 41.5862 20.286 41.9834 20.6832C42.3806 21.0805 42.5792 21.586 42.5792 22.1999C42.5792 22.8138 42.3806 23.3193 41.9834 23.7166L27.6834 38.0166C27.2501 38.4499 26.7445 38.6666 26.1667 38.6666C25.589 38.6666 25.0834 38.4499 24.6501 38.0166L19.0167 32.3832C18.6195 31.986 18.4209 31.4805 18.4209 30.8666C18.4209 30.2527 18.6195 29.7471 19.0167 29.3499C19.414 28.9527 19.9195 28.7541 20.5334 28.7541C21.1473 28.7541 21.6528 28.9527 22.0501 29.3499L26.1667 33.4666Z"
      fill="white"
    />
  </Svg>
);
