import * as React from "react";
import Svg, { Path } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const IconCardNfc = ({ width = 15, height = 24, fill = "#2F6B61" }: Props) => (
  <Svg width={width} height={height} viewBox="0 0 15 24" fill="none">
    <Path
      d="M10.9104 23.6127C13.3845 20.2361 14.7148 16.1683 14.7097 11.9945C14.7046 7.82072 13.3644 3.75612 10.882 0.38542C10.5734 -0.0335289 9.9808 -0.125142 9.55828 0.180797C9.13577 0.486736 9.04337 1.07437 9.35192 1.49332C11.5979 4.543 12.8104 8.2205 12.8151 11.9968C12.8197 15.773 11.6161 19.4534 9.3776 22.5085C9.07009 22.9282 9.16392 23.5156 9.58718 23.8205C10.0104 24.1255 10.6029 24.0324 10.9104 23.6127Z"
      fill="#A6D6C8"
    />
    <Path
      d="M10.9204 11.9991C10.9246 15.3779 9.84774 18.6709 7.84484 21.4044C7.53732 21.824 6.9449 21.9171 6.52164 21.6122C6.09838 21.3072 6.00455 20.7198 6.31207 20.3001C8.07933 17.8882 9.02949 14.9827 9.02584 12.0014C9.0222 9.02012 8.06494 6.11684 6.29179 3.7092C5.98324 3.29025 6.07563 2.70261 6.49815 2.39667C6.92067 2.09073 7.51331 2.18234 7.82185 2.60129C9.83143 5.32995 10.9163 8.62034 10.9204 11.9991Z"
      fill="#A6D6C8"
    />
    <Path
      d="M7.13125 12.0185C7.13125 9.43975 6.30794 6.92724 4.77931 4.84102C4.47179 4.42133 3.87938 4.32829 3.45612 4.63321C3.03285 4.93814 2.93902 5.52555 3.24654 5.94524C4.53999 7.7105 5.23664 9.83647 5.23664 12.0185C5.23663 14.2004 4.53998 16.3264 3.24653 18.0917C2.93901 18.5114 3.03284 19.0988 3.4561 19.4037C3.87936 19.7086 4.47178 19.6156 4.7793 19.1959C6.30793 17.1097 7.13124 14.5972 7.13125 12.0185Z"
      fill="#A6D6C8"
    />
    <Path
      d="M3.34203 12.0184C3.34203 10.2331 2.77205 8.49371 1.71377 7.0494C1.40625 6.62971 0.813833 6.53667 0.39057 6.8416C-0.0326929 7.14652 -0.126523 7.73393 0.180994 8.15362C1.0041 9.27697 1.44742 10.6299 1.44742 12.0184C1.44742 13.4069 1.00409 14.7598 0.180986 15.8832C-0.126532 16.3029 -0.032703 16.8903 0.39056 17.1952C0.813822 17.5001 1.40624 17.4071 1.71376 16.9874C2.77204 15.5431 3.34203 13.8037 3.34203 12.0184Z"
      fill="#A6D6C8"
    />
  </Svg>
);

export default IconCardNfc;
