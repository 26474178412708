import {
  View,
  Pressable,
  Image,
  StyleSheet,
  Dimensions,
} from "react-native-web";
import { useNavigate } from "react-router-dom";
import Label from "../Label";
import { theme } from "../../config/theme";
import { PetFinderOrganization } from "../../types/types";
import { useState } from "react";

type Props = {
  org: PetFinderOrganization;
  imageUrl?: string;
  name: string;
  distance: number;
};
const OrgCard = ({ org, imageUrl, name, distance, ...rest }: Props) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasLocationSet, setHasLocationSet] = useState(
    !!window.localStorage.getItem("LAT_LNG")
  );
  const cityState = org.address
    ? `${org.address.city}, ${org.address.state}`
    : "";

  const navigateToOrg = (organization: PetFinderOrganization) => {
    navigate("/organization-detail", { state: { organization } });
  };
  return (
    <View {...rest} style={styles().orgCard}>
      <Pressable onPress={() => navigateToOrg(org)}>
        <Image
          style={styles({ resizeMode: imageUrl ? "cover" : "center" }).orgImage}
          source={
            imageUrl
              ? { uri: imageUrl }
              : require("../../assets/images/avatar.png")
          }
        />
      </Pressable>
      <Pressable onPress={() => navigateToOrg(org)} style={{ padding: 12 }}>
        <Label
          weight="medium"
          styles={{ color: theme.colors.secondary[300] }}
          numberOfLines={1}
          sz="large"
        >
          {name}
        </Label>
        <Label styles={{ paddingTop: 4 }} sz="small">
          {hasLocationSet && distance ? `${distance.toFixed(1)} mi away` : ""}
        </Label>
        <Label styles={{ paddingTop: 4 }} sz="small">
          {cityState ? cityState : ""}
        </Label>
      </Pressable>
    </View>
  );
};

const styles = (props?: any) =>
  StyleSheet.create({
    orgCard: {
      backgroundColor: "white",
      flex: 1,
      margin: 6,
      minWidth: 155,
      width: Dimensions.get("window").width / 2.5,
      maxWidth: 400,
      height: 260,
      borderWidth: 2,
      borderRadius: 12,
      overflow: "hidden",
      borderColor: theme.colors.info,
      flexDirection: "column",
      justifyContent: "center",
    },
    orgImage: {
      width: "100%",
      height: 165,
      resizeMode: props,
    },
  });

export default OrgCard;
