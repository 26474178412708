import { useNavigate, useParams } from "react-router-dom";
import { View, StyleSheet, Dimensions, Linking } from "react-native-web";
import { useCallback, useEffect, useState } from "react";
import {
  createOrganization,
  fetchAddress,
  fetchFoster,
  fetchOrganization,
  fetchOrganizationById,
  updateApplication,
} from "../../services/apiService";
import Label from "../../components/Label";
import {
  Address,
  Foster,
  Organization,
  PetFinderOrganization,
} from "../../types/types";
import PersonalInformation from "../../components/ApplicationForm/PersonalInformation";
import Loading from "../../components/Loading";
import { theme } from "../../config/theme";
import FosterPreferences from "../../components/ApplicationForm/FosterPreferences";
import HomeInformation from "../../components/ApplicationForm/HomeInformation";
import Button from "../../components/Button";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import LabeledInput from "../../components/LabeledInput";
import { isEmailAddress } from "../../config/formatter";
import {
  createUser,
  isAuthenticated,
  logAnalyticsEvent,
  signInWithGooglePopup,
} from "../../services/firebaseService";
import { fetchPetfinderOrganization } from "../../services/petfinderService";
import { setIsRescueAccount } from "../../config/utils";

const FosterApplication = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const organizationId = params.get("organization") || undefined;
  const isAdmin = params.get("admin");

  const [application, setApplication] = useState<Foster | undefined>(undefined);
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );
  const [petfinderOrganization, setPetFinderOrganization] = useState<
    PetFinderOrganization | undefined
  >(undefined);
  const [fosterAddress, setFosterAddress] = useState<Address | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const isRescue = window.localStorage.getItem("IS_RESCUE");
  const [isBlurred, setIsBlurred] = useState(
    !isRescue && !organization && !isAdmin
  );
  const isAuthed = isAuthenticated();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const getApplication = useCallback(async () => {
    if (!id) return;

    logAnalyticsEvent({ eventName: "foster_application_viewed" });
    setIsLoading(true);

    try {
      const [res, addressRes] = await Promise.all([
        fetchFoster({ userId: id }),
        fetchAddress({ userId: id }),
      ]);

      if (res.application) {
        setApplication(res.application);
        setFosterAddress(addressRes.address);
      }
    } catch (err) {
      console.error("Error fetching application or address:", err);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getApplication();
  }, [getApplication]);

  useEffect(() => {
    const checkForOrganization = async (id: string) => {
      const pfRes = await fetchOrganization({ petfinder_id: id });
      if (pfRes.organization) {
        setOrganization(pfRes.organization);
      }
      const rgRes = await fetchOrganization({ rescuegroup_id: id });
      if (rgRes.organization) {
        setOrganization(rgRes.organization);
      }
      const res = await fetchOrganizationById(id);
      if (res.organization) {
        setOrganization(rgRes.organization);
      }

      if (!pfRes.organization && !rgRes.organization && !res.organization) {
        // No organization in our database, fallback to petfinder api
        const pfOrganization = await fetchPetfinderOrganization({ id });
        if (pfOrganization.organization) {
          setPetFinderOrganization(pfOrganization.organization);
          setEmail(pfOrganization.organization?.email || "");
        } else {
          // TODO: check rescuegroups organization api
        }
      } else {
        setIsBlurred(false);
        if (application) {
          updateApplication({
            application_id: application?.id,
            was_viewed: true,
          });
        }
      }
    };
    if (organizationId && application?.id) {
      checkForOrganization(organizationId);
    }
  }, [organizationId, application, isBlurred]);

  const onPressGoogle = async () => {
    logAnalyticsEvent({ eventName: "signup_submit_organization" });
    const res = await signInWithGooglePopup();
    if (res?.user) {
      await createOrganization({
        auth_user_id: res.user.uid,
        email: res.user.email?.toLowerCase() || "",
        name: petfinderOrganization?.name,
        petfinder_id: petfinderOrganization?.id,
        rescuegroup_id: !petfinderOrganization ? organizationId : undefined,
      });
      setIsBlurred(false);
      setIsRescueAccount(true);
      if (application) {
        updateApplication({
          application_id: application?.id,
          was_viewed: true,
        });
      }
    }
  };
  const onSubmitSignup = async () => {
    logAnalyticsEvent({ eventName: "signup_submit_organization" });

    if (!isEmailAddress(email)) {
      setError("Invalid email address");
      return;
    }
    if (!password || password.length < 6) {
      setError("Password must be 6 or more characters");
      return;
    }
    setIsLoading(true);
    const res = await createUser(
      email.toLowerCase().trim(),
      password.trim(),
      true,
      petfinderOrganization?.name,
      petfinderOrganization?.id,
      !petfinderOrganization ? organizationId : undefined
    );
    if (res.success) {
      setIsRescueAccount(true);
      setIsBlurred(false);
      if (application) {
        updateApplication({
          application_id: application?.id,
          was_viewed: true,
        });
      }
    } else {
      setError(res.error || "Failed");
    }
    setIsLoading(false);
  };

  return isLoading ? (
    <Loading height={400} />
  ) : (
    <View style={[styles.formContainer]}>
      <View
        style={[
          styles.sectionContainer,
          {
            backgroundColor: theme.colors.secondary[400],
            alignItems: "center",
            paddingBottom: 24,
            position: isBlurred ? "absolute" : undefined,
            top: isBlurred ? 64 : undefined,
            zIndex: isBlurred ? 10 : undefined,
          },
        ]}
      >
        <Label
          weight="bold"
          styles={{
            textAlign: "center",
            color: "white",
            paddingTop: 16,
            paddingBottom: 12,
          }}
          sz="xxlarge"
        >
          {isBlurred ? "Join Now!" : "Let's Talk!"}
        </Label>
        <Label
          styles={{
            textAlign: "center",
            color: "white",
            paddingBottom: 8,
          }}
          sz="medium"
        >
          {isBlurred
            ? "Get free access to your new new foster home's contact information and\nmeet others through our pet fostering community."
            : " Reach out to learn more about the FosterFi platform we are building for organizations like yours."}
        </Label>
        {isBlurred ? (
          <>
            <GoogleLoginButton onPress={onPressGoogle} />
            <Label sz="medium" styles={styles.orText}>
              OR
            </Label>
            <LabeledInput
              label="Email"
              labelColor="white"
              value={email}
              onChange={setEmail}
              placeholder="abc@email.com"
              width={Dimensions.get("window").width - 110}
            />
            <LabeledInput
              isSecure
              label="Password"
              labelColor="white"
              value={password}
              onChange={setPassword}
              placeholder="6 or more characters"
              width={Dimensions.get("window").width - 110}
            />
            <Label sz="small" styles={{ color: theme.colors.error }}>
              {error}
            </Label>
          </>
        ) : null}

        <Button
          backgroundColor={"white"}
          textColor={theme.colors.secondary[400]}
          isDisabled={isLoading}
          onPress={() => {
            isBlurred
              ? onSubmitSignup()
              : isAuthed
              ? navigate("/rescue")
              : navigate("/login");
          }}
          width={Dimensions.get("window").width - 110}
        >
          {isBlurred ? "View Foster" : "View Dashboard"}
        </Button>

        <Label
          onPress={() => Linking.openURL("https://tryfosterfi.com")}
          sz="medium"
          styles={{
            color: "white",
            paddingTop: 24,
            paddingBottom: 8,
            textDecorationLine: "underline",
          }}
        >
          Learn more
        </Label>
      </View>

      <Label sz="xlarge" weight="bold" styles={{ paddingBottom: 24 }}>
        Foster Application
      </Label>
      <View style={isBlurred ? styles.blurContainer : { width: "100%" }}>
        <PersonalInformation
          isEditable={false}
          userId={application?.auth_user_id || ""}
          application={application}
          fosterAddress={fosterAddress}
        />

        <View style={styles.divider} />

        <FosterPreferences
          isEditable={false}
          userId={application?.auth_user_id || ""}
          application={application}
        />

        <View style={styles.divider} />

        <HomeInformation
          isEditable={false}
          userId={application?.auth_user_id || ""}
          application={application}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  blurContainer: {
    maxWidth: 1400,
    width: "100%",
    userSelect: "none",
    // Using CSS to apply blur effect
    filter: "blur(3.3px)", // Adjust the blur intensity here
  },
  orText: {
    color: "white",
    padding: 16,
  },
  formContainer: {
    width: "100%",
    alignItems: "center",
    padding: 24,
  },
  divider: {
    height: 4,
    width: "100%",
    borderRadius: 2,
    backgroundColor: theme.colors.secondary[200],
    marginBottom: 16,
    marginTop: 16,
  },
  sectionContainer: {
    maxWidth: "90%",
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: "white",
    padding: 12,
    borderRadius: 8,
  },
});
export default FosterApplication;
