import { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, ScrollView } from "react-native-web";
import Label from "../../components/Label";
import { theme } from "../../config/theme";
import { auth } from "../../services/firebaseService";
import {
  fetchAddress,
  fetchApplicationsForFoster,
  fetchFoster,
  fetchFosterRequests,
} from "../../services/apiService";
import Loading from "../../components/Loading";
import { logAnalyticsEvent } from "../../services/firebaseService";
import PersonalInformation from "../../components/ApplicationForm/PersonalInformation";
import { Address, Application, Foster, FosterRequest } from "../../types/types";
import FosterPreferences from "../../components/ApplicationForm/FosterPreferences";
import HomeInformation from "../../components/ApplicationForm/HomeInformation";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { validateApplicationCompletion } from "../../config/utils";
import FosterApplicationCard from "../../components/FosterApplicationCard";
import { onAuthStateChanged } from "firebase/auth";
import FosterRequestCard from "../../components/FosterRequestCard";

const UpdateAccount = () => {
  const navigate = useNavigate();
  const userId = auth.currentUser?.uid || "";
  const [sentApplications, setSentApplications] = useState<Application[]>([]);
  const [fosterRequests, setFosterRequests] = useState<FosterRequest[]>([]);

  const [application, setApplication] = useState<Foster | undefined>(undefined);
  const [fosterAddress, setFosterAddress] = useState<Address | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const [stepsCompleted, setStepsCompleted] = useState(0);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("IS_RESCUE");
        navigate("/login");
      }
    });
  }, [navigate]);

  const getApplicationData = async () => {
    if (!userId || isLoading) {
      return;
    }
    logAnalyticsEvent({ eventName: "profile_visited" });
    setIsLoading(true);
    const res = await fetchFoster({ userId });
    const addressRes = await fetchAddress({ userId });
    if (res.application) {
      setApplication(res.application);
      setFosterAddress(addressRes.address);
      setStepsCompleted(validateApplicationCompletion(res.application)); // Checks status of completion
    }
    setIsLoading(false);
  };

  const getFosterApplications = async () => {
    try {
      if (!userId) {
        return;
      }
      const res = await fetchApplicationsForFoster({ auth_user_id: userId });
      if (res.applications) {
        setSentApplications(res.applications.reverse());
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getApplicationData();
    getFosterApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const getFosterRequests = async () => {
      try {
        if (!application) {
          return;
        }
        const res = await fetchFosterRequests({ foster_id: application?.id });
        if (res.fosterRequests) {
          setFosterRequests(res.fosterRequests);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getFosterRequests();
  }, [application]);

  return (
    <View style={styles.backgroundContainer}>
      {isLoading ? (
        <Loading height={500} />
      ) : (
        <View style={styles.container}>
          <View style={styles.formContainer}>
            <Label styles={styles.text} weight="bold" sz="xxlarge">
              {stepsCompleted === 3
                ? "Foster Application Completed"
                : stepsCompleted >= 1
                ? "Finish"
                : "Let's Complete"}{" "}
              {stepsCompleted !== 3 ? "Your Foster Application" : ""}
            </Label>

            {stepsCompleted === 3 ? (
              <View
                style={{
                  backgroundColor: theme.colors.secondary[100],
                  padding: 16,
                  marginTop: 16,
                  marginBottom: 16,
                  borderRadius: 8,
                  alignItems: "center",
                }}
              >
                <Label sz="medium" styles={{ textAlign: "center", padding: 8 }}>
                  You're all set! You can now start submitting your Foster
                  Application to rescues in your area.
                </Label>
                <Button
                  width={Dimensions.get("window").width - 75}
                  onPress={() => {
                    navigate("/organizations");
                  }}
                >
                  Connect with Rescues
                </Button>
                <Button
                  backgroundColor="black"
                  width={Dimensions.get("window").width - 75}
                  onPress={() => {
                    navigate("/pets");
                  }}
                >
                  Find Animals In Need
                </Button>

                {fosterRequests?.length > 0 && (
                  <>
                    <Label
                      styles={{
                        width: "100%",
                        marginTop: 22,
                        textAlign: "start",
                      }}
                      sz="medium"
                      weight="medium"
                    >
                      Foster Requests
                    </Label>
                    <ScrollView
                      style={{ width: "100%", minHeight: 120, marginTop: 8 }}
                    >
                      {fosterRequests.map((r) => {
                        return (
                          <FosterRequestCard
                            didSubmit={sentApplications.some(
                              (a) => a.organization_id === r.organization_id
                            )}
                            userId={userId}
                            createdAt={r.createdAt}
                            organization_id={r.organization_id}
                          />
                        );
                      })}
                    </ScrollView>
                  </>
                )}

                {sentApplications?.length > 0 && (
                  <>
                    <Label
                      styles={{
                        width: "100%",
                        marginTop: 22,
                        textAlign: "start",
                      }}
                      sz="medium"
                      weight="medium"
                    >
                      Applications sent
                    </Label>
                    <ScrollView
                      style={{ width: "100%", minHeight: 120, marginTop: 8 }}
                    >
                      {sentApplications.map((app) => {
                        return (
                          <FosterApplicationCard
                            was_viewed={app.was_viewed}
                            createdAt={app.createdAt}
                            updatedAt={app.updatedAt}
                            organization_id={app.organization_id || ""}
                            organization_petfinder_id={
                              app.organization_petfinder_id || ""
                            }
                          />
                        );
                      })}
                    </ScrollView>
                  </>
                )}
              </View>
            ) : null}

            {stepsCompleted === 0 || stepsCompleted === 3 ? (
              <FosterPreferences
                isEditable={true}
                application={application}
                userId={userId}
                onRefresh={getApplicationData}
              />
            ) : null}

            {stepsCompleted === 1 || stepsCompleted === 3 ? (
              <>
                <View style={styles.divider} />
                <PersonalInformation
                  isEditable={true}
                  application={application}
                  fosterAddress={fosterAddress}
                  userId={userId}
                  onRefresh={getApplicationData}
                />
              </>
            ) : undefined}

            {stepsCompleted === 2 || stepsCompleted === 3 ? (
              <>
                <View style={styles.divider} />
                <HomeInformation
                  isEditable={true}
                  application={application}
                  userId={userId}
                  onRefresh={getApplicationData}
                />
              </>
            ) : undefined}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundContainer: {
    backgroundColor: theme.colors.secondary[100],
    alignItems: "center",
  },
  container: {
    marginTop: 16,
    backgroundColor: "white",
    borderRadius: 12,
    padding: 12,
    alignItems: "center",
  },
  formContainer: {
    maxWidth: 800,
    padding: 20,
    alignItems: "center",
  },
  text: {
    textAlign:
      Dimensions.get("window").width > theme.breakpoints.sm ? "center" : "left",
    paddingTop: 16,
    paddingBottom: 16,
  },
  divider: {
    height: 4,
    width: "100%",
    borderRadius: 2,
    backgroundColor: theme.colors.secondary[200],
    marginBottom: 16,
    marginTop: 16,
  },
});

export default UpdateAccount;
