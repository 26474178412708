import { View, TextInput, StyleSheet } from "react-native-web";
import Label from "../Label";
import { theme } from "../../config/theme";

type Props = {
  label: string;
  value: string;
  placeholder: string;
  width?: number;
  height?: number;
  error?: string;
  success?: string;
  isSecure?: boolean;
  multiline?: boolean;
  showPassword?: boolean;
  disabled?: boolean;
  rightElement?: any;
  leftElement?: any;
  labelColor?: string;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  isFocused?: boolean;
};

export const LabeledInput = ({
  label,
  value,
  width,
  height,
  placeholder,
  onChange,
  error,
  success,
  isSecure = false,
  showPassword = false,
  multiline = false,
  disabled,
  leftElement,
  labelColor,
  onFocus,
  isFocused,
  ...rest
}: Props) => {
  return (
    <View style={{ width, maxWidth: 600 }} {...rest}>
      <View>
        <View style={{ display: "flex" }} alignItems="center" space="2">
          <Label
            styles={[
              styles.text,
              { color: labelColor ? labelColor : undefined },
            ]}
            sz="medium"
            weight="medium"
          >
            {label}
          </Label>
        </View>
      </View>
      <TextInput
        multiline={multiline}
        secureTextEntry={isSecure}
        placeholderTextColor={theme.colors.tertiary}
        placeholder={placeholder}
        style={[styles.input, { height }]}
        value={value}
        onChange={(e: any) => onChange && onChange(e.target.value)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    paddingBottom: 6,
    color: "black",
  },
  input: {
    backgroundColor: "white",
    outlineColor: theme.colors.secondary[300],
    height: 42,
    borderRadius: 8,
    borderWidth: 1.5,
    borderColor: theme.colors.tertiary,
    padding: 6,
    marginBottom: 16,
    fontWeight: 600,
    color: theme.colors.text.body,
  },
});
export default LabeledInput;
