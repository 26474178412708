import { useNavigate } from "react-router-dom";
import { theme } from "../../config/theme";
import {
  Dimensions,
  View,
  Pressable,
  StyleSheet,
  Linking,
} from "react-native-web";
import Label from ".././Label";
import { isDesktop, isMobile, isRescueAccount } from "../../config/utils";
import { useEffect, useState } from "react";
import { IconLogoMark } from "../../assets/icons/IconLogoMark";

type Props = {
  isAuthed: boolean;
};
const NavigationFooter = ({ isAuthed }: Props) => {
  const navigate = useNavigate();

  const [isRescue, setIsRescue] = useState(false);

  useEffect(() => {
    const checkForRescue = async () => {
      const rescue = await isRescueAccount();
      setIsRescue(rescue);
    };
    checkForRescue();
  }, []);

  return (
    <>
      <View style={styles.divider}></View>
      <View style={[styles.row]}>
        <View style={styles.container}>
          <IconLogoMark fill="white" />
          <View style={{ height: 6 }} />
          <Label styles={styles.text} sz="medium" weight="bold">
            Quick Links
          </Label>

          <Pressable
            onPress={() => navigate("/pets", { state: { petType: "Dog" } })}
          >
            <Label styles={styles.text} sz="small">
              Foster a dog
            </Label>
          </Pressable>

          <Pressable
            onPress={() => navigate("/pets", { state: { petType: "Cat" } })}
          >
            <Label styles={styles.text} sz="small">
              Foster a cat
            </Label>
          </Pressable>

          <Pressable onPress={() => navigate("/organizations")}>
            <Label styles={styles.text} sz="small">
              Find a rescue
            </Label>
          </Pressable>

          <Pressable
            onPress={() =>
              navigate(
                isRescue && isAuthed
                  ? "/rescue"
                  : isAuthed
                  ? "/update-account"
                  : "/create-account"
              )
            }
          >
            <Label styles={styles.text} sz="small">
              {isRescue && isAuthed
                ? "Dashboard"
                : isAuthed
                ? "My application"
                : "Become a foster"}
            </Label>
          </Pressable>

          <Pressable onPress={() => navigate("/foster-tails")}>
            <Label styles={styles.text} sz="small">
              Resources
            </Label>
          </Pressable>
        </View>
        {}
        <View style={styles.container}>
          <View style={{ opacity: 0 }}>
            <IconLogoMark />
            <View style={{ height: 6 }} />
          </View>
          <Label styles={styles.text} sz="medium" weight="bold">
            Products
          </Label>

          <Pressable onPress={() => Linking.openURL("https://tryfosterfi.com")}>
            <Label styles={styles.text} sz="small">
              FosterFi
            </Label>
          </Pressable>
        </View>
        <View style={styles.container}>
          <View style={{ opacity: 0 }}>
            <IconLogoMark />
            <View style={{ height: 6 }} />
          </View>
          <Label styles={styles.text} sz="medium" weight="bold">
            Contact Info
          </Label>

          <Pressable onPress={() => navigate("/contact")}>
            <Label styles={styles.text} sz="small">
              Contact us
            </Label>
          </Pressable>

          <Label styles={styles.text} sz="small">
            Miami, Florida
          </Label>

          {isDesktop(Dimensions.get("window").width) ? (
            <Pressable
              onPress={() => Linking.openURL("mailto:support@petfoserz.com")}
            >
              <Label
                styles={[styles.text, { textDecorationLine: "underline" }]}
                sz="small"
              >
                support@petfosterz.com
              </Label>
            </Pressable>
          ) : null}
        </View>
      </View>
      <View style={{ width: "100%", height: 16, backgroundColor: "black" }} />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "start",
    paddingTop: 16,
    paddingBottom: 16,
  },
  signUpContainer: {
    height: isMobile(Dimensions.get("window").width) ? 180 : 200,
    width: isMobile(Dimensions.get("window").width) ? 200 : 250,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 12,
    padding: 16,
  },
  row: {
    padding: 12,
    backgroundColor: theme.colors.secondary[400],
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  divider: {
    backgroundColor: theme.colors.primary,
    height: 16,
    width: "100%",
  },
  logo: {
    width: 100,
    height: 75,
  },
  text: {
    padding: 6,
    color: "white",
  },
});

export default NavigationFooter;
