import { useState, useCallback, useRef } from 'react';
import { Address, Animal } from '../types/types';
import { fromAddress } from 'react-geocode';
import { fetchPetFinderAnimals } from '../services/petfinderService';

const useFetchPets = (
  location: string | null,
  search: string,
  petType: string,
  page: string,
  address: Address | null
) => {
  const [petsList, setPetsList] = useState<Animal[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const cache = useRef<{[key: string]: Animal[]}>({}); // Cache object

  const fetchData = useCallback(async (forcePage?: string) => {
    setIsLoading(true);
    setError("");
    const pageToUse = forcePage || page;
    
    const cacheKey = `${location}-${search}-${petType}-${pageToUse}`;
    if (cache.current[cacheKey]) {
      setPetsList(cache.current[cacheKey]);
      setIsLoading(false);
      return;
    }

    let locationStr = location || window.localStorage.getItem('LAT_LNG'); // Must be lat lng or postal code only

    if (address && !locationStr) { // Convert address to lat lng if no browser locations found
       const res = await fromAddress(`${address?.city}, ${address?.state}`, process.env.REACT_APP_MAPS_KEY)
       const { lat, lng } = res.results[0].geometry.location;
      locationStr = `${lat},${lng}`
    };
    
    try { 
      const res = await fetchPetFinderAnimals({
        location: locationStr ? locationStr : "33004", // Default to SoFlo
        search,
        type: petType !== "All pets" ? petType : "",
        page: pageToUse,
      });
      if (res?.animals) {
        const newPetsList = Number(pageToUse) > 1 ? [...petsList, ...res.animals] : res.animals;
        setPetsList(newPetsList);
        cache.current[cacheKey] = newPetsList; // Cache the results
      } else if (pageToUse === '1') {
        setPetsList([]);
        setError("No results were found in your area. Try checking back later.");
      }
    } catch (err) {
      setError("Failed to fetch animals. Please try again.");
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, search, petType, page, address]);

  return { petsList, isLoading, error, fetchData };
};

export default useFetchPets;