import React from "react";
import { Text } from "react-native-web";

const sizes = {
  xxxxlarge: 56,
  xxxlarge: 46,
  xxlarge: 34,
  xlarge: 28,
  large: 22,
  medium: 18,
  small: 14,
  xsmall: 12,
};

const weights = {
  bold: 800,
  medium: 600,
  regular: 500,
  light: 400,
};

type Props = {
  children?: React.ReactNode | string;
  styles?: any;
  sz:
    | "xxxxlarge"
    | "xxxlarge"
    | "xxlarge"
    | "xlarge"
    | "large"
    | "medium"
    | "small"
    | "xsmall";
  weight?: "bold" | "medium" | "regular" | "light";
  numberOfLines?: number;
  onPress?: () => void;
};

const Label = ({
  children,
  styles,
  sz,
  weight,
  numberOfLines,
  onPress,
  ...rest
}: Props) => {
  const fontSize = sizes[sz];
  const fontWeight = weights[weight ? weight : "regular"];
  return (
    <Text
      onPress={onPress}
      numberOfLines={numberOfLines ? numberOfLines : undefined}
      style={[
        styles,
        { fontFamily: "Urbanist", fontWeight, fontSize, ...rest },
      ]}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Label;
