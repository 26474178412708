import {
  View,
  Pressable,
  TextInput,
  Dimensions,
  StyleSheet,
} from "react-native-web";
import ItemPicker from "../ItemPicker";
import IconSearch from "../../assets/icons/IconSearch";
import { theme } from "../../config/theme";

type Props = {
  search: string;
  setSearch: (v: string) => void;
  petType?: string;
  setPetType?: (v: string) => void;
  onPressSearch: () => void;
  placeholder?: string;
};
const FilterSearchInput = ({
  search,
  setSearch,
  petType,
  setPetType,
  onPressSearch,
  placeholder,
}: Props) => {
  return (
    <View style={styles.container}>
      {setPetType ? (
        <ItemPicker
          selectedValue={petType || ""}
          items={["All pets", "Dog", "Cat"]}
          onPressItem={(v, i) => setPetType(v)}
        />
      ) : null}

      {setPetType ? <View style={styles.divider} /> : null}

      <TextInput
        placeholderTextColor={theme.colors.tertiary}
        placeholder={placeholder}
        style={styles.input}
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />
      <Pressable onPress={onPressSearch} style={styles.searchIcon}>
        <IconSearch />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    paddingLeft: 12,
    paddingRight: 2,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 8,
    justifyContent: "space-between",
    width: Dimensions.get("window").width - 40,
    maxWidth: 800,
  },
  input: {
    width: "100%",
    height: 42,
    borderRadius: 8,
    padding: 6,
  },
  searchIcon: {
    backgroundColor: theme.colors.secondary[400],
    borderRadius: 8,
    margin: 4,
    width: 36,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    marginLeft: 12,
    marginRight: 12,
    height: 50,
    width: 1,
    backgroundColor: theme.colors.selector,
  },
});

export default FilterSearchInput;
