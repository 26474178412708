import React, { useEffect, useState } from "react";
import { StyleSheet, View, Dimensions } from "react-native-web";
import { logAnalyticsEvent } from "../../../services/firebaseService";
import Label from "../../../components/Label";
import { Animal } from "../../../types/types";
import { isMobile } from "../../../config/utils";
import { theme } from "../../../config/theme";
import Loading from "../../../components/Loading";
import SubmitPetModal from "../../../components/Rescue/SubmitPetModal";
import Button from "../../../components/Button";
import useOrganization from "../../../hooks/useOrganization";
import { fetchAnimalsForOrganization } from "../../../services/animalService";
import AnimalCard from "../../../components/AnimalCard";
import { fetchPetFinderAnimals } from "../../../services/petfinderService";

const Animals = () => {
  const [petsList, setPetsList] = useState<Animal[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const organization = useOrganization();

  const fetchData = async () => {
    if (!organization) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await fetchAnimalsForOrganization(organization.id);
      if (res.animals) {
        setPetsList(res.animals);
      }
      const pfRes = await fetchPetFinderAnimals({
        location: "",
        organization: organization?.petfinder_id,
      });
      if (pfRes.animals) {
        const newAnimals = [...petsList, ...pfRes.animals];
        setPetsList(newAnimals);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    logAnalyticsEvent({ eventName: "rescue_animals_visited" });

    if (!petsList.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petsList.length, organization]);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Label styles={styles.headerText} sz="xlarge" weight="bold">
          Your rescue animals.
        </Label>
        <Button
          width={120}
          height={42}
          onPress={() => {
            setIsSubmitting(true);
          }}
        >
          Add new
        </Button>
      </View>

      {isLoading ? (
        <Loading height={150} />
      ) : (
        <View style={styles.gridContainer}>
          {petsList.map((pet, i) => {
            const formattedName = pet.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            const petImageUrl =
              pet.photoUrl || pet?.primary_photo_cropped?.medium;
            return (
              <AnimalCard
                key={i}
                animal={pet}
                imageUrl={petImageUrl}
                name={formattedName}
                breed={pet.breed || ""}
                age={pet.age}
              />
            );
          })}
        </View>
      )}
      <SubmitPetModal
        onDismiss={() => {
          setIsSubmitting(false);
          fetchData();
        }}
        isVisible={isSubmitting}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 600,
    padding: 16,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    color: theme.colors.secondary[400],
    width: "100%",
    maxWidth: 800,
    paddingBottom: 16,
  },
  gridContainer: {
    alignSelf: "start",
    width: isMobile(Dimensions.get("window").width) ? "100%" : "70%",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "start",
    justifyContent: "start",
  },
});

export default Animals;
