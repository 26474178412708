import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { StyleSheet, View } from "react-native-web";
import Sidebar from "../../../components/Rescue/Sidebar";
import Header from "../../../components/Rescue/Header";

const RescueApp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <View style={styles.dashboard}>
      <Sidebar />
      <View style={styles.mainContent}>
        <Header />
        <Outlet />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  dashboard: {
    flexDirection: "row",
  },
  mainContent: {
    flex: 1,
    transition: "margin-left 0.3s, width 0.3s",
  },
});

export default RescueApp;
