import {
  Address,
  Application,
  Foster,
  FosterRequest,
  Organization,
} from "../types/types";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type CompleteResultResponse = {
  success: boolean;
  error?: string;
};
type CreateFosterResponse = {
  success: boolean;
  error?: string;
};

type CreateFosterParams = {
  auth_user_id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  address?: string;
  is_adult?: boolean;
  phone?: string;
  breed_preference?: string | null;
  has_experience?: boolean | null;
  animal_age_preference_low?: number | null;
  animal_age_preference_high?: number | null;
  preferred_foster_duration_low?: number | null;
  preferred_foster_duration_high?: number | null;
  residence_description?: string | null;
  residence_owned?: boolean | null;
  roommates_description?: string | null;
  existing_pets_description?: string | null;
  has_foster_experience?: boolean | null;
  foster_services_available?: string[] | null;
};

type UpdateFosterParams = {
  id: string;
  auth_user_id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  address?: string;
  is_adult?: boolean;
  phone?: string;
  breed_preference?: string;
  has_experience?: boolean;
  animal_age_preference_low?: number;
  animal_age_preference_high?: number;
  preferred_foster_duration_low?: number;
  preferred_foster_duration_high?: number;
  residence_description?: string;
  residence_owned?: boolean;
  roommates_description?: string;
  existing_pets_description?: string;
  has_foster_experience?: boolean;
  foster_services_available?: string[];
};

type FetchFosterResponse = {
  application: Foster;
  error?: string;
};

type FetchAllFostersResponse = {
  fosters: Foster[];
  error?: string;
};

type SubmitApplicationResponse = {
  success: boolean;
  error?: string;
};

type SubmitApplicationParams = {
  auth_user_id: string;
  organization_email: string;
  organization_name: string;
  pet_name: string;
  organization_petfinder_id?: string;
  organization_rescuegroup_id?: string;
  organization_id?: string;
};

type CreateApplicationParams = {
  auth_user_id: string;
  organization_petfinder_id?: string;
  organization_rescuegroup_id?: string;
  organization_id?: string;
};

type UpdateApplicationParams = {
  application_id: string;
  was_viewed: boolean;
};

type FetchApplicationParams = {
  organization_id: string;
};

type FetchFosterApplicationParams = {
  auth_user_id: string;
};

type FetchApplicationsResponse = {
  applications?: Application[];
  error?: string;
};

type CreateFosterRequestParams = {
  foster_id: string;
  organization_id: string;
};

type UpdateFosterRequestParams = {
  foster_id: string;
  was_viewed?: boolean;
  was_approved?: boolean;
};

type FetchFosterRequestParams = {
  foster_id: string;
  organization_id?: string;
};

type FetchFosterRequestsResponse = {
  fosterRequests?: FosterRequest[];
  error?: string;
};

type FetchFosterParams = {
  userId: string;
};

type SubmitContactResponse = {
  success: boolean;
  error?: string;
};

type SubmitContactParams = {
  email: string;
  name?: string;
  message: string;
};
type SubmitFosterContactParams = {
  foster_email: string;
  foster_name: string;
  organization_name: string;
};

type CreateAddressParams = {
  auth_user_id: string;
  street_1?: string;
  street_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
};

type FetchAddressResponse = {
  address?: Address;
  error?: string;
};

type FetchOrganizationParams = {
  userId?: string;
  petfinder_id?: string;
  rescuegroup_id?: string;
};

type FetchOrganizationResponse = {
  organization?: Organization;
  error?: string;
};

type CreateOrganizationParams = {
  email: string;
  auth_user_id: string;
  petfinder_id?: string;
  rescuegroup_id?: string;
  name?: string;
};

export const createFoster = async ({
  auth_user_id,
  email,
  first_name,
  last_name,
  is_adult,
  phone,
  breed_preference,
  residence_description,
  roommates_description,
  existing_pets_description,
  foster_services_available,
  has_experience,
  animal_age_preference_low,
  animal_age_preference_high,
  preferred_foster_duration_low,
  preferred_foster_duration_high,
  residence_owned,
  has_foster_experience,
}: CreateFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_user_id,
        email,
        first_name,
        last_name,
        is_adult,
        phone,
        breed_preference,
        residence_description,
        roommates_description,
        existing_pets_description,
        foster_services_available,
        has_experience,
        animal_age_preference_low,
        animal_age_preference_high,
        preferred_foster_duration_low,
        preferred_foster_duration_high,
        residence_owned,
        has_foster_experience,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }

    // // Creates empty address
    await createAddress({ auth_user_id });

    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CreateFosterResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CreateFosterResponse;
  }
};

export const createAddress = async ({
  auth_user_id,
  street_1,
  street_2,
  city,
  state,
  postal_code,
  country,
}: CreateAddressParams) => {
  try {
    const response = await fetch(`${BASE_URL}/addresses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_user_id,
        street_1,
        street_2,
        city,
        state,
        postal_code,
        country,
      }),
    });

    if (!response.ok) {
      console.log(response.status, response);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CreateFosterResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CreateFosterResponse;
  }
};

export const updateAddress = async ({
  auth_user_id,
  street_1,
  street_2,
  city,
  state,
  postal_code,
  country,
}: CreateAddressParams) => {
  try {
    const response = await fetch(`${BASE_URL}/addresses/${auth_user_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_user_id,
        street_1,
        street_2,
        city,
        state,
        postal_code,
        country,
      }),
    });

    if (!response.ok) {
      console.log(response.status, response.json);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CreateFosterResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CreateFosterResponse;
  }
};

export const fetchAddress = async ({ userId }: FetchFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/addresses/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const address: Address = data;
    return { address } as FetchAddressResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchAddressResponse;
  }
};

export const updateFoster = async ({
  auth_user_id,
  email,
  first_name,
  last_name,
  address,
  is_adult,
  phone,
  breed_preference,
  has_experience,
  animal_age_preference_low,
  animal_age_preference_high,
  preferred_foster_duration_low,
  preferred_foster_duration_high,
  residence_description,
  residence_owned,
  roommates_description,
  existing_pets_description,
  has_foster_experience,
  foster_services_available,
}: UpdateFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/${auth_user_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_user_id,
        email,
        first_name,
        last_name,
        address,
        is_adult,
        phone,
        breed_preference,
        has_experience,
        animal_age_preference_low,
        animal_age_preference_high,
        preferred_foster_duration_low,
        preferred_foster_duration_high,
        residence_description,
        residence_owned,
        roommates_description,
        existing_pets_description,
        has_foster_experience,
        foster_services_available,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data.id;
    return { success } as CreateFosterResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CreateFosterResponse;
  }
};

export const fetchFoster = async ({ userId }: FetchFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.log(response.status);
      return { error: response.statusText } as FetchFosterResponse;
    }
    const data = await response.json();
    return { application: data } as FetchFosterResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchFosterResponse;
  }
};

export const fetchAllFosters = async (lat?: number, lon?: number) => {
  try {
    let params = "";
    if (lat && lon) {
      params = `?lat=${lat}&lon=${lon}`;
    }
    const response = await fetch(`${BASE_URL}/fosters${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return { error: response.statusText } as FetchAllFostersResponse;
    }
    const data = await response.json();
    return { fosters: data } as FetchAllFostersResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchAllFostersResponse;
  }
};

export const createApplication = async ({
  auth_user_id,
  organization_id,
  organization_petfinder_id,
  organization_rescuegroup_id,
}: CreateApplicationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/applications/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        foster_auth_id: auth_user_id,
        organization_petfinder_id,
        organization_rescuegroup_id,
        organization_id,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as SubmitApplicationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as SubmitApplicationResponse;
  }
};

export const updateApplication = async ({
  application_id,
  was_viewed,
}: UpdateApplicationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/applications/${application_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        was_viewed,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as SubmitApplicationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as SubmitApplicationResponse;
  }
};

export const fetchApplications = async ({
  organization_id,
}: FetchApplicationParams) => {
  try {
    // Fetch organizations by organization ID
    const response = await fetch(
      `${BASE_URL}/applications/organization/${organization_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    const applications: Application[] = data;
    return { applications } as FetchApplicationsResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error } as FetchApplicationsResponse;
  }
};

export const fetchApplicationsForFoster = async ({
  auth_user_id,
}: FetchFosterApplicationParams) => {
  try {
    // Fetch organizations by organization ID
    const response = await fetch(
      `${BASE_URL}/applications/foster/${auth_user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      console.log(response.status);
      return { error: "No applications found" } as FetchApplicationsResponse;
    }
    const data = await response.json();
    const applications: Application[] = data;
    return { applications } as FetchApplicationsResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error } as FetchApplicationsResponse;
  }
};

export const submitApplication = async ({
  auth_user_id,
  organization_email,
  organization_name,
  pet_name,
  organization_petfinder_id,
  organization_rescuegroup_id,
  organization_id,
}: SubmitApplicationParams) => {
  try {
    const response = await fetch(
      `${BASE_URL}/applications/submit-application`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          auth_user_id,
          organization_email,
          organization_name,
          pet_name,
          organization_petfinder_id,
          organization_rescuegroup_id,
          organization_id,
        }),
      }
    );

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as SubmitApplicationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as SubmitApplicationResponse;
  }
};

export const submitFosterContactRequest = async ({
  foster_email,
  foster_name,
  organization_name,
}: SubmitFosterContactParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/submit-foster-contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        foster_email,
        foster_name,
        organization_name,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as SubmitContactResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as SubmitContactResponse;
  }
};

export const submitContactRequest = async ({
  email,
  name,
  message,
}: SubmitContactParams) => {
  try {
    const response = await fetch(`${BASE_URL}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        message,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = data.success;
    return { success } as SubmitContactResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as SubmitContactResponse;
  }
};

export const createFosterRequest = async ({
  foster_id,
  organization_id,
}: CreateFosterRequestParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/requests`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        foster_id,
        organization_id,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as SubmitApplicationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as SubmitApplicationResponse;
  }
};

export const updateFosterRequest = async ({
  foster_id,
  was_approved,
  was_viewed,
}: UpdateFosterRequestParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/requests/${foster_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        was_viewed,
        was_approved,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const fetchFosterRequests = async ({
  foster_id,
  organization_id,
}: FetchFosterRequestParams) => {
  try {
    // Fetch organizations by organization ID
    let url = `${BASE_URL}/fosters/requests/${foster_id}`;
    if (organization_id) {
      url = url + `?organization=${organization_id}`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    if (organization_id && data) {
      const fosterRequests: FosterRequest[] = [data];
      return { fosterRequests } as FetchFosterRequestsResponse;
    } else if (data) {
      const fosterRequests: FosterRequest[] = data;
      return { fosterRequests } as FetchFosterRequestsResponse;
    } else {
      return { error: "No requests found" } as FetchFosterRequestsResponse;
    }
  } catch (error) {
    console.error("Error:", error);
    return { error: error } as FetchFosterRequestsResponse;
  }
};

export const fetchOrganization = async ({
  userId,
  petfinder_id,
  rescuegroup_id,
}: FetchOrganizationParams) => {
  try {
    // fetch by petfinder_id else auth user id
    let url = `${BASE_URL}/organizations/${
      petfinder_id ? `petfinder/` : rescuegroup_id ? `rescuegroup/` : ""
    }${petfinder_id ? petfinder_id : rescuegroup_id ? rescuegroup_id : userId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const fetchOrganizationById = async (organizationId: string) => {
  try {
    // fetch by org id
    let url = `${BASE_URL}/organizations/id/${organizationId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const createOrganization = async ({
  email,
  name,
  auth_user_id,
  petfinder_id,
  rescuegroup_id,
}: CreateOrganizationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        auth_user_id,
        petfinder_id,
        rescuegroup_id,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const fetchDistanceInMiles = async (
  origin: string,
  destination: string
): Promise<number> => {
  try {
    const response = await axios.get(
      "https://maps.googleapis.com/maps/api/distancematrix/json",
      {
        params: {
          origins: origin,
          destinations: destination,
          key: process.env.REACT_APP_MAPS_KEY,
        },
      }
    );
    const distanceInMeters = response.data.rows[0].elements[0].distance.value;
    const distanceInMiles = distanceInMeters / 1609.34; // Convert meters to miles

    return distanceInMiles;
  } catch (e) {
    return -1;
  }
};
